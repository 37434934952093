import { GeolocationCoords } from '../types/models'

const DEFAULT_COORDINATES: GeolocationCoords = {
  lat: 0,
  lng: 0,
}

export const getCenterCoordinatesFromList = (
  coords: ReadonlyArray<GeolocationCoords>,
) => {
  const coordsLength = coords.length
  if (coordsLength === 0) {
    return
  }
  const { lat, lng } = coords.reduce(
    (total, coord) => ({
      lat: total.lat + (coord.lat || 0),
      lng: total.lng + (coord.lng || 0),
    }),
    { ...DEFAULT_COORDINATES },
  )

  return { lat: lat / coordsLength, lng: lng / coordsLength }
}
