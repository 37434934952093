import { alpha, makeStyles } from '@material-ui/core'
import { Text } from '@velocity/ui'
import clsx from 'clsx'
import { FC } from 'react'

import { useTranslation } from '../../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../../hooks/useMessageFormat'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    maxWidth: '100%',
    padding: 4,
    paddingRight: 12,
    borderRadius: 2,
    backgroundColor: alpha(theme.palette.secondary.dark, 0.1),
  },
  icon: {
    '&$icon': {
      flex: '1 0 auto',
    },
  },
  label: {
    '&$label': {
      marginLeft: 8,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: theme.palette.secondary.dark,
    },
  },
}))

interface Props {
  className?: string
}

export const InstaBookBadge: FC<Props> = ({ className }) => {
  const classes = useStyles()
  const f = useMessageFormat()
  const translations = useTranslation()
  const stepTranslations = translations?.steps?.shared

  const tLabel = f(stepTranslations?.labels['instaBook'], {
    debugId: 'shared.instaBook',
  })

  return (
    <div
      className={clsx(classes.root, className)}
      title={tLabel}
      data-e2e-component="insta-book-badge"
    >
      <Text variant="100" loose={true} className={classes.label}>
        {tLabel}
      </Text>
    </div>
  )
}
