import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { FC, useEffect } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { Supplier } from '../../types/models'
import { formatDistance } from '../../utils/distance'
import { SupplierCard } from '../SupplierCard/SupplierCard'
import { SupplierCardTooltip } from '../SupplierCardTooltip/SupplierCardTooltip'
import { useSupplierCardListStyles } from './SupplierCardList.styled'

export interface SupplierCardListProps {
  /**
   * The list of suppliers to display
   */
  suppliers: ReadonlyArray<Supplier>
  /**
   * Optional function called when a supplier in the list is clicked
   * @param place
   */
  onClick?: (place: Supplier) => void
  /**
   * Optional id of the currently highligthed supplier
   */
  highlightedCard?: string
}

const SUPPLIER_DATA_ATTRIBUTE = 'data-supplier-id'

export const SupplierCardList: FC<SupplierCardListProps> = ({
  onClick,
  suppliers,
  highlightedCard,
}) => {
  const styles = useSupplierCardListStyles()
  const f = useMessageFormat()
  const translations = useTranslation()
  const sharedTranslations = translations?.steps?.shared

  const { countryConfig } = useUxConfig()

  useEffect(() => {
    if (highlightedCard) {
      const cardElement = document.querySelector(
        `[${SUPPLIER_DATA_ATTRIBUTE}="${highlightedCard}"]`,
      )
      cardElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [highlightedCard])

  return (
    <Box className={styles.root} component="ul">
      {suppliers.map((supplier) => {
        const formattedDistance = formatDistance(
          supplier.distance,
          f(sharedTranslations?.otherData['locale'], {
            debugId: 'steps.global.locale',
          }),
        )
        return (
          <Box
            key={supplier.id}
            component="li"
            {...{ [SUPPLIER_DATA_ATTRIBUTE]: supplier.id }}
            my={2}
            className={clsx(
              styles.cardListItem,
              highlightedCard === supplier.id && styles.highlightedCard,
            )}
          >
            <SupplierCard
              title={supplier.name}
              location={{
                city: supplier.city,
                address: [supplier.streetNumber, supplier.streetName]
                  .filter(Boolean)
                  .join(' '),
                postalCode: supplier.postalCode,
              }}
              isInstaBook={
                countryConfig.enableInstaBookIndication &&
                supplier.availabilityStatus === 'available'
              }
              tooltip={<SupplierCardTooltip label={formattedDistance} />}
              onClick={() => onClick?.(supplier)}
            />
          </Box>
        )
      })}
    </Box>
  )
}
