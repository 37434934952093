import { Component, ErrorInfo, ReactElement } from 'react'

interface Props {
  onError?: (error: Error, errorInfo: ErrorInfo) => void
  errorElement: ReactElement | null
}

interface State {
  hasError: boolean
}

export class UxConfigContextErrorBoundary extends Component<Props, State> {
  state = { hasError: false }

  static isUxConfigContextError(error: Error): boolean {
    return error.message.startsWith('No Context value found for `UxConfig`.')
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: UxConfigContextErrorBoundary.isUxConfigContextError(error),
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (UxConfigContextErrorBoundary.isUxConfigContextError(error)) {
      this.props.onError?.(error, errorInfo) // NOSONAR
    }
  }

  render() {
    return this.state.hasError ? this.props.errorElement : this.props.children
  }
}
