import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import { ApolloProvider } from '@apollo/client'
import logBuildInfo from '@cj4/log-build-info'
import { LoggerContextProvider } from '@cj4/react-logger'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  FC,
  lazy,
  Suspense,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { FullScreenLoader } from './components/FullScreenLoader/FullScreenLoader'
import { ResultsView } from './components/ResultsView/ResultsView'
import { appConfig } from './config/env/appConfig'
import { createContext } from './config/env/createContext'
import { AppConfigServiceProvider } from './context/AppConfigContext/AppConfigContext'
import { TranslationProvider } from './context/TranslationContext/TranslationContext'
import { UxConfigProvider } from './context/UxConfigContext/UxConfigContext'
import { FtbVelocityProvider } from './context/VelocityContext/FtbVelocityProvider'
import { useLocale } from './hooks/useLocale'
import { setDefaultLocale } from './i18n/localizedDateFns'
import { defaultLocale } from './i18n/supportedLocales'
import { Routes } from './index.routes'
import { AppLocale } from './types/i18n'
import { BrandTheme } from './types/theme'
import { pushRebrandedUrl } from './utils/history'
import { isJsDom } from './utils/isJsDom'
import { addLocalePolyfills, appLocaleToDateFns } from './utils/locale'

export const Providers: FC = ({ children }) => {
  const ctx = useMemo(() => createContext(appConfig), [])
  const [context, setContext] = useState(!(ctx instanceof Promise) && ctx)
  if (!context) ctx['then'](setContext)

  const ready = useRef(false)

  // Customizable locale from BE, used to i18n displayed content (Dates, etc...).
  // Also used in requests sent to the BE. If missing, fallbacks to 'en-gb'.
  const appLocale = useRef<AppLocale>(defaultLocale)

  // Url-parsed locale for client logic.
  const [urlLocale, changeLocale] = useLocale(appConfig)

  if (context && !ready.current) {
    if (context.logger) context.logger.info('app_start')
    logBuildInfo(appConfig.build)

    const countryConfig = context.countryConfig

    if (context.theme === BrandTheme.Ayvens) {
      require(`@velocity/styling/themes/ayvens/theme.css`)
      pushRebrandedUrl()
    } else {
      require(`@velocity/styling/themes/leaseplan/theme.css`)
    }

    removeLoadingIndicator()

    const countryConfigLocale = countryConfig?.formatLocale as AppLocale
    if (countryConfigLocale) {
      appLocale.current = countryConfigLocale
    }

    setDefaultLocale(appLocaleToDateFns(appLocale.current))

    ready.current = true
  }

  const locale = urlLocale ?? appLocale.current

  const returnToBeginning = useCallback(() => {
    window.location.href = `/${locale}/vehicle`
  }, [locale])

  return ready && context ? (
    <AppConfigServiceProvider value={appConfig}>
      <LoggerContextProvider value={context.logger}>
        <UxConfigProvider
          locale={locale}
          countryConfig={context.countryConfig}
          changeLocale={changeLocale}
          theme={context.theme}
        >
          <ApolloProvider client={context.apolloClient}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={appLocaleToDateFns(locale)}
            >
              <TranslationProvider locale={locale}>
                <FtbVelocityProvider
                  theme={context.theme}
                  // Internally retrieves date-fns Locales with AppLocale keys
                  locale={locale}
                  googleMapsApiKey={appConfig.googleMaps.apiKey}
                >
                  {typeof context.countryConfig === 'undefined' ? (
                    <ResultsView
                      viewType="appError"
                      buttonOnClick={returnToBeginning}
                    />
                  ) : (
                    children
                  )}
                </FtbVelocityProvider>
              </TranslationProvider>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
        </UxConfigProvider>
      </LoggerContextProvider>
    </AppConfigServiceProvider>
  ) : null
}

if (!isJsDom()) {
  // The formatJS polyfills are required to enable formatting plurals in IE11
  // For simplicity, we delay the import of only the <App /> component, which is
  // the only which utilizes this formatting feature
  const AppLazy = lazy(() =>
    addLocalePolyfills().then(() => import('./components/App/App')),
  )

  render(
    <Router>
      <Providers>
        <Routes>
          <Suspense fallback={<FullScreenLoader />}>
            <AppLazy />
          </Suspense>
        </Routes>
      </Providers>
    </Router>,
    document.getElementById('root'),
  )
}

function removeLoadingIndicator() {
  const loader = document.querySelector('.initial-loading-indicator')
  if (loader) {
    document.body.removeChild(loader)
  }
}
