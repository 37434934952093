import { lazy, Suspense } from 'react'

import { StepComponentContainer } from '../../../types/flow'
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'
import { hasVehicleFormValues } from '../VehicleStep/VehicleStep'
import { ActivityStepPrerequisiteData, ActivityStepValues } from './types'

const ActivityStepLazy = lazy(() => import('./ActivityStep'))

export const ActivityStepContainer: StepComponentContainer<
  ActivityStepValues,
  ActivityStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ActivityStepLazy {...props} />
    </Suspense>
  )
}

ActivityStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is ActivityStepPrerequisiteData =>
  hasVehicleFormValues(mergedFlowData)
