/* eslint-disable @typescript-eslint/no-explicit-any */
export function memoizeForever<TResult>(fn: (...args: Array<any>) => TResult) {
  let result: TResult | undefined = undefined
  let haveRun = false
  return (...args: Array<any>): TResult => {
    if (haveRun) {
      return result as TResult
    }
    result = fn(...args)
    haveRun = true
    return result
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
