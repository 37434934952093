import { Typography } from '@material-ui/core'
import { FlexBox } from '@velocity/ui/draft'
import { FC } from 'react'

interface SupplierCardTooltipProps {
  /**
   * Optional label for the tooltip
   */
  label?: string
}

export const SupplierCardTooltip: FC<SupplierCardTooltipProps> = ({
  label,
}) => (
  <FlexBox pr={1} height="100%" alignItems="center">
    <Typography variant="caption">{label}</Typography>
  </FlexBox>
)
