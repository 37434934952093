import { ChevronRightIcon, ChevronLeftIcon } from '@velocity/icons/system'
import { Button, ButtonProps } from '@velocity/ui'

import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'

import { useTranslation } from '../../../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../../../hooks/useMessageFormat'
import { useStyles } from './StepFormButtons.styled'

// ------------------------------------------------------------------------------------------------
// Types

export type NextButtonProps = Partial<
  Omit<ButtonProps, 'disabled' | 'data-e2e-component' | 'type' | 'variant'>
>

export interface StepFormButtonsProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onBlur'> {
  /**
   * Optionally sets whether the Previous button has to be disabled, the default value is false = the button is not disabled
   */
  previousDisabled?: boolean
  /**
   * Optionally sets whether the Next button has to be disabled, the default value is false = the button is not disabled
   */
  nextDisabled?: boolean
  /**
   * Optionally sets a custom label on the Next button, the default value is "Next" translated via key "translations.forms.shared.next"
   */
  nextLabel?: string
  /**
   * Optional object of type NextButtonProps that sets the Next button props
   */
  nextButtonProps?: NextButtonProps
  /**
   * Optional function called when the Previous button is pressed
   */
  onPrevious?: () => void
  /**
   * Optional function called when the Next button is pressed
   */
  onNext?: () => void
}

// ------------------------------------------------------------------------------------------------
// Component

export const StepFormButtons: FC<StepFormButtonsProps> = ({
  previousDisabled = false,
  nextDisabled = false,
  nextLabel,
  nextButtonProps = {},
  className,
  onPrevious,
  onNext,
  ...restProps
}) => {
  const classes = useStyles()
  const translations = useTranslation()
  const formLabels = translations?.forms?.shared?.labels || {}
  const f = useMessageFormat()

  const nextDefaultLabel = f(formLabels['next'], {
    debugId: 'forms.shared.next',
  })

  const { className: nextClassName } = nextButtonProps

  return (
    <div className={clsx(classes.buttonContainer, className)} {...restProps}>
      <Button
        data-e2e-component="previous-button"
        type="button"
        variant="primary-outlined"
        disabled={previousDisabled}
        className={classes.button}
        fullWidth={true}
        StartIcon={() => (
          <ChevronLeftIcon size="s" data-e2e-component="previous-icon" />
        )}
        onClick={onPrevious}
      >
        {f(formLabels['previous'], {
          debugId: 'forms.shared.previous',
        })}
      </Button>

      <Button
        fullWidth={true}
        EndIcon={() => <ChevronRightIcon size="s" />}
        onClick={onNext}
        {...nextButtonProps}
        disabled={nextDisabled}
        data-e2e-component="submission-button"
        type="submit"
        variant="primary-filled"
        className={clsx(classes.button, nextClassName)}
      >
        {nextLabel ?? nextDefaultLabel}
      </Button>
    </div>
  )
}
