import { LocalstorageStore } from '@cj4/store-localstorage'
import { useCallback, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Config } from '../config/env/types'
import { AppLocale } from '../types/i18n'
import { extractLocaleFromUrl } from '../utils/i18n'

export const useLocale = (appConfig: Config) => {
  const history = useHistory()
  const location = useLocation()
  const lsStoreRef = useRef(
    new LocalstorageStore(appConfig.localStorageKeys.locale),
  )
  const locale = extractLocaleFromUrl()
  const isFirstPageLoad = useRef(true)

  const changeLocale = useCallback(
    (locale: AppLocale) => {
      const pathSegments = location.pathname.split('/').filter(Boolean)
      pathSegments.splice(0, 1, locale)
      history.replace(`/${pathSegments.join('/')}${location.search || ''}`)
      lsStoreRef.current.set(locale)
    },
    [history, location],
  )

  // TODO-next: Check how NEXT_LOCALE cookie might already achieve locale persistence.
  // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
  useEffect(() => {
    if (isFirstPageLoad) {
      // on the first page load, do not override the locale in the path
      lsStoreRef.current.set(null)
      isFirstPageLoad.current = false
    }
    const storedLocale = lsStoreRef.current.get()
    const pathSegments = window.location.pathname.split('/').filter(Boolean)
    const pathLocale = pathSegments[0]
    if (storedLocale && storedLocale !== pathLocale) {
      changeLocale(storedLocale as AppLocale)
    }
  }, [location, changeLocale])

  return [locale, changeLocale] as const
}
