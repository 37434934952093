import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme, { overlay: Boolean }>((theme) => ({
  root: ({ overlay }) => ({
    position: overlay ? 'fixed' : 'relative',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 3,
  }),
  centeredCard: {
    width: '100%',
    zIndex: 4,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(40),
    },
  },
}))
