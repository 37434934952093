import { makeStyles, Theme } from '@material-ui/core'
import { ChatIcon, MailIcon, PhoneIcon } from '@velocity/icons/system'
import { FC } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'

import { copyFontStyles } from '../../utils/styles'

const useStyles = makeStyles((theme: Theme) => ({
  needHelpTitle: {
    '&$needHelpTitle': {
      ...copyFontStyles(theme.typography.h6),
    },
  },
  needHelpDetails: {
    '&$needHelpDetails': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        '& > :nth-child(2)': {
          paddingLeft: theme.spacing(1),
          '& a': {
            ...copyFontStyles(theme.typography.h6),
            textDecoration: 'none',
            color: '#006262',
          },
        },
      },
    },
  },
}))

export const NeedHelp: FC<{ className?: string }> = ({ className }) => {
  const classes = useStyles()
  const { countryConfig } = useUxConfig()
  const translations = useTranslation()
  const sharedTranslations = translations?.steps?.shared.otherData

  return (
    <div className={className} data-e2e-component="results-view-need-help">
      <div className={classes.needHelpTitle}>
        {sharedTranslations?.['needHelp']}
      </div>
      <div className={classes.needHelpDetails}>
        <div>
          <PhoneIcon id="1" size="s" color="primary" />
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  String(sharedTranslations?.['needHelpPhone']) ||
                  `Call us on <a href="tel:${countryConfig.contactPhone}">${countryConfig.contactPhoneLabel}</a>`,
              }}
            />
          </div>
        </div>
        <div>
          <MailIcon id="2" size="s" color="primary" />
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  String(sharedTranslations?.['needHelpEmail']) ||
                  `Or <a href="mailto:${countryConfig.contactEmail}">email</a> us`,
              }}
            />
          </div>
        </div>
        <div>
          <ChatIcon id="3" size="s" color="primary" />
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: String(sharedTranslations?.['needHelpFaq']) || '',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
