import { Box, makeStyles } from '@material-ui/core'
import { FC } from 'react'

const skew = 30

const useTooltipStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(3.5),
    position: 'relative',
    color: theme.palette.common.white,
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.secondary.dark,
      transformOrigin: '0 0',
      transform: `skew(${skew}deg)`,
      zIndex: 1,
    },

    '& > *': {
      paddingLeft: skew,
      position: 'relative',
      zIndex: 2,
    },

    '& *': {
      fontWeight: 800,
      color: theme.palette.common.white,
    },
  },
}))

export const Tooltip: FC = ({ children }) => (
  <Box className={useTooltipStyles().root}>{children}</Box>
)
