import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme) => ({
  root: {
    '&$root': {
      width: (props) => (props.isMobile ? '100%' : '50%'),
      padding: theme.spacing(1),
    },
  },
}))
