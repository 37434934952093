import { supportedLocales } from '../i18n/supportedLocales'
import { CountryCode, AppLocale } from '../types/i18n'
import { extractCountryFromLocale } from './i18n'

/**
 * Available locales are supported locales (i.e. the locales we have translations for) for enabled countries
 * (set via `REACT_APP_ENABLED_COUNTRIES`).
 */
export function isAvailableLocale(
  enabledCountries: readonly CountryCode[],
  locale: AppLocale,
) {
  return enabledCountries.find((c) => c === extractCountryFromLocale(locale))
}

/**
 * Available locales are supported locales (i.e. the locales we have translations for) for enabled countries
 * (set via `REACT_APP_ENABLED_COUNTRIES`).
 */
export function listAvailableLocales(enabledCountries: readonly CountryCode[]) {
  return supportedLocales.filter(isAvailableLocale.bind(null, enabledCountries))
}

/**
 * Checks if a LeasePlan country is enabled.
 */
export function isLpCountryEnabled(
  enabledCountries: readonly CountryCode[],
  country?: string,
) {
  if (!country) {
    return false
  }
  return enabledCountries.some((c) => `LP${c.toUpperCase()}` === country)
}
