import { useBreakpoint } from '@velocity/styling/breakpoint/useBreakpoint/useBreakpoint'
import { Checkbox } from '@velocity/ui'
import { ReactElement, useCallback } from 'react'

import { useStyles } from './MultiDropDownSelectItem.styled'

export interface ItemProps<TValue> {
  label: string
  value: TValue
  enabled?: boolean
}

interface MultiDropDownSelectItemProps<T>
  extends Omit<ItemProps<T>, 'enabled'> {
  checked: boolean
  disabled: boolean
  onChange: (value: this['value']) => void
}

export const MultiDropDownSelectItem: <TValue>(
  props: MultiDropDownSelectItemProps<TValue>,
) => ReactElement = ({ checked, label, onChange, value, disabled }) => {
  const isMobile = useBreakpoint()?.toString() === 'XS'

  const classes = useStyles({ isMobile })
  const handleOnChange = useCallback(() => onChange(value), [onChange, value])
  return (
    <Checkbox
      key={label}
      data-e2e-component={`multi-drop-down-checkbox-${value}`}
      className={classes.root}
      label={label}
      onChange={handleOnChange}
      checked={checked}
      disabled={disabled}
    />
  )
}
