import { useLazyQuery } from '@apollo/client'
import { useLogger } from '@cj4/react-logger'
import { loader } from 'graphql.macro'
import { createContext, FC, useContext, useEffect, useState } from 'react'

import { fallbackTranslations } from '../../i18n/fallbackTranslations'
import { flattenTranslations } from '../../i18n/flattenSitecoreTranslations'
import {
  GetTranslations,
  GetTranslationsVariables,
} from '../../types/apollo/GetTranslations'
import { AppLocale, Translations } from '../../types/i18n'
import { deepmerge } from '../../utils/deepmerge'

const GET_TRANSLATIONS = loader('./translations.graphql')

export type TranslationContextValue = Translations & { locale: AppLocale }

const TranslationContext = createContext<TranslationContextValue | undefined>(
  undefined,
)

export function mergeTranslations(
  translations1: Translations,
  translations2?: Translations | null,
): [Translations, string[]] {
  if (!translations2) {
    return [translations1, ['[i18n] translations are not available']]
  }
  try {
    const errors: string[] = []
    return [
      deepmerge(translations1, translations2, (key, base) => {
        errors.push(
          `[i18n] Missing translations key "${key}", using fallback value: ${JSON.stringify(
            base,
          )}`,
        )
      }) as Translations,
      errors,
    ]
  } catch (e) {
    const baseMessage = '[i18n] failed to merge the translations'
    return [
      translations1,
      [e instanceof Error ? `${baseMessage}: ${e.message}` : `${baseMessage}.`],
    ]
  }
}

export const TranslationProvider: FC<{
  locale: AppLocale
  useSitecore?: boolean
}> = ({ children, locale, useSitecore = true }) => {
  const [language, country] = locale.split('-')
  const logger = useLogger()
  const [contextValue, setContextValue] =
    useState<TranslationContextValue | null>(null)

  const [fetchTranslations, { data, error, loading }] = useLazyQuery<
    GetTranslations,
    GetTranslationsVariables
  >(GET_TRANSLATIONS, {
    variables: {
      // This will not work for locales which Sitecore does not support.
      // Since Sitecore has only one version of English per country, it's always called just `en`,
      // unlike all the rest of the languages (`fr-FR`, `nb-NO`, and so on).
      language: language === 'en' ? 'en' : locale,
      country,
    },
  })

  useEffect(() => {
    useSitecore && fetchTranslations()
  }, [fetchTranslations, useSitecore, locale])

  useEffect(() => {
    // BFF logs upstream endpoint errors already
    error && logger.warn(error.message)
  }, [error, logger])

  useEffect(() => {
    if (!useSitecore) {
      // This is the case for unit tests (in `mount` we turn Sitecore off by default)
      setContextValue({ ...fallbackTranslations, locale })
      return
    }
    const hasGraphqlResponse = Boolean(error || data)
    if (loading || !hasGraphqlResponse) {
      return
    }
    const translationsToMergeIn =
      data?.ftbToolV2 && flattenTranslations(data.ftbToolV2)
    const [merged, errors] = mergeTranslations(
      fallbackTranslations,
      translationsToMergeIn,
    )
    errors.forEach((err) => logger.error(err))
    setContextValue({ ...merged, locale })
  }, [useSitecore, locale, data, loading, error, logger])

  return contextValue ? (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  ) : null
}

export function useTranslation(): TranslationContextValue {
  const contextValue = useContext(TranslationContext)

  if (!contextValue) {
    throw new Error(
      'No Context value found for `Translation`. Did you forget to wrap your application in the `TranslationProvider`?',
    )
  }

  return contextValue
}
