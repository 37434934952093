import { makeStyles, Typography } from '@material-ui/core'
import { FC } from 'react'

const useSupplierCardTitleStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    color: theme.palette.secondary.dark,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const SupplierCardTitle: FC = ({ children }) => (
  <Typography
    className={useSupplierCardTitleStyles().root}
    gutterBottom={true}
    variant="subtitle1"
  >
    {children}
  </Typography>
)
