import { Tooltip } from '@velocity/ui'
import clsx from 'clsx'
import { FC, ReactElement } from 'react'

import { FtbSvgIcon } from '../Icons/FtbSvgIcon'
import { useStyles } from './TextWithInfoIcon.styled'

export interface TextWithInfoIconProps {
  className?: string
  text: string | ReactElement
  tooltip: string | ReactElement | null | undefined
}

export const TextWithInfoIcon: FC<TextWithInfoIconProps> = ({
  className,
  text: label,
  tooltip,
}) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <div>{label}</div>
      {tooltip ? (
        <Tooltip text={tooltip}>
          <div className={classes.icon}>
            <FtbSvgIcon name="info" />
          </div>
        </Tooltip>
      ) : null}
    </div>
  )
}

export default TextWithInfoIcon
