import { CountryCodeUppercase } from '../types/i18n'
import { BrandTheme } from '../types/theme'

export const getCountryThemeFromISOCode = (
  countryCode: string,
  ayvensCountries: readonly CountryCodeUppercase[],
): BrandTheme => {
  const codeUC = countryCode.toUpperCase()
  return ayvensCountries.some((ayvensCountry) => ayvensCountry === codeUC)
    ? BrandTheme.Ayvens
    : BrandTheme.LeasePlan
}
