import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  field: {
    '&&': {
      margin: theme.spacing(0, 0, 2.5, 0), // TODO: Round to a better value
    },
    [theme.breakpoints.up('md')]: {
      '&&': {
        margin: theme.spacing(0, 0, 2, 0),
        maxWidth: theme.spacing(42), // TODO: Closest value to Zeplin's max-width: 330px
      },
    },
  },
}))
