import { CountryCode } from '../../types/i18n'
import { getRebrandedCountries } from '../../utils/parseEnv'
import { string, truthy } from '../../utils/string'
import { Env } from './createEnv'
import { Config } from './types'

export const FIXED_ENDPOINTS = {
  vehicleServices: '/vehicle/services',
  vehicleExist: '/vehicle',
  suppliers: '/suppliers',
  createBooking: '/bookings',
  verifyEmail: '/bookings/confirmation',
  countryConfig: (countryCode: string) =>
    `/countries/${countryCode}/configuration`,
  pMRejection: '/maintenance-proposals',
} as const

export const createConfig = (env: Env): Config => ({
  // Reading build info directly to make sure it's defined in the build time
  build: {
    productName: 'Fast Track Booking',
    timestamp: parseInt(process.env.REACT_APP_BUILD_TIME || '', 10),
    commitSha: string(process.env.REACT_APP_BUILD_COMMIT_SHA),
    branch: string(process.env.REACT_APP_BUILD_BRANCH),
  },
  countryConfigOverride: truthy(env.REACT_APP_COUNTRY_CONFIG_OVERRIDE),
  enabledCountries: string(env.REACT_APP_ENABLED_COUNTRIES)
    .split(',')
    .filter(Boolean)
    .map((c) => c.toLowerCase() as CountryCode),
  rebrandedCountries: getRebrandedCountries(env),
  localStorageKeys: {
    flow: 'ftb-data-flow',
    pM: 'ftb-data-proactive-maintenance',
    locale: 'ftb-locale',
    flowLocale: 'ftb-flow-locale',
  },
  dataDog: {
    clientToken: string(env.REACT_APP_DD_CLIENT_TOKEN),
    service: string(env.REACT_APP_DD_SERVICE),
    source: string(env.REACT_APP_DD_SOURCE),
    env: string(env.REACT_APP_DD_ENV),
  },
  googleReCaptcha: {
    siteKey: string(env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY),
    endpoint: string(env.REACT_APP_RECAPTCHA_ENDPOINT),
  },
  graphQl: {
    endpoint: string(env.REACT_APP_GRAPHQL_ENDPOINT),
  },
  googleMaps: {
    apiKey: string(
      env.REACT_APP_GOOGLE_MAPS_API_KEY || env.STORYBOOK_GOOGLE_MAPS_API_KEY,
    ),
  },
  endpoints: {
    apiBase: string(env.REACT_APP_API_BASE),
    ...FIXED_ENDPOINTS,
  },
})
