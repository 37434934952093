import { ChevronRightIcon } from '@velocity/icons/system'
import { Button } from '@velocity/ui'
import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { ResultsViewContent, ResultsViewTypes } from '../../types/i18n'
import { FormatMessageApi } from '../../types/validation'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { FtbSvgIcon, FtbSvgIconName } from '../Icons/FtbSvgIcon'
import { PageBanner } from '../PageBanner/PageBanner'
import { NeedHelp } from './NeedHelp'
import { useResultsViewStyles } from './ResultsView.styled'

export type ResultsViewProps = {
  /**
   * Define the view type - one of the names defined in Sitecore.Results
   */
  viewType: ResultsViewTypes
  /**
   * A callback function called when the button is pressed in the page
   */
  buttonOnClick?: () => void
}

export const ResultsView: FC<ResultsViewProps> = ({
  viewType,
  buttonOnClick,
}) => {
  const classes = useResultsViewStyles()
  const translations = useTranslation()
  const f = useMessageFormat()
  const translatedContentBlock = translations?.results?.[viewType]

  const { title, iconName, details, buttonLabel } = translateResultViewProps(
    f,
    viewType,
    translatedContentBlock,
  )

  const hideNeedHelpAndLocaleSelector = [
    'appError',
    'countryNotSupported',
  ].includes(viewType)

  // When backend returns booking info with the email confirmation response, use this info passed via props
  // to display a `BookingInfo` component together with the deails here.
  // (https://leaseplan-digital.atlassian.net/browse/CJ4VS-1155)

  return (
    <div className={classes.root}>
      <Header hideLocaleSelector={hideNeedHelpAndLocaleSelector} />
      <PageBanner />
      <div className={classes.content}>
        {iconName && (
          <FtbSvgIcon
            className={classes.icon}
            name={iconName as FtbSvgIconName}
          />
        )}
        <div className={classes.title}>{title}</div>
        {details && (
          <div className={classes.description}>
            <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>
              {details && details.split('\n').join('\n\n')}
            </ReactMarkdown>
          </div>
        )}
        {buttonLabel && (
          <Button
            data-e2e-component="select-leaseplan-countries"
            type="submit"
            variant="primary-filled"
            className={classes.button}
            fullWidth={false}
            EndIcon={() => <ChevronRightIcon size="xs" color="currentColor" />}
            onClick={buttonOnClick}
          >
            {buttonLabel}
          </Button>
        )}
        {!hideNeedHelpAndLocaleSelector && (
          <NeedHelp className={classes.needHelp} />
        )}
      </div>
      <Footer className={classes.footer} />
    </div>
  )
}

// Currently, there are variations on the content structure of the ResultsView
// generic component so we need to suppress logging until we agree on a common way
// of indicating in Sitecore that a field has been left empty on purpose
// (suggestion: using a null value)
function translateResultViewProps(
  f: FormatMessageApi<string | number | JSX.Element>,
  contentBlockParentContext: string,
  contentBlock?: ResultsViewContent,
  values?: Record<string, string>,
): ResultsViewContent {
  if (!contentBlock) return {} as ResultsViewContent

  const debugIdParent = contentBlockParentContext
    ? `${contentBlockParentContext}.`
    : ''

  return {
    title: f(contentBlock?.title, {
      debugId: `${debugIdParent}.contentBlock.header`,
      ...(values ? { values } : {}),
      suppressWarnings: true,
    }),
    iconName: f(contentBlock?.iconName, {
      debugId: `${debugIdParent}.contentBlock.image`,
      suppressWarnings: true,
    }) as FtbSvgIconName,
    details: f(contentBlock?.details, {
      debugId: `${debugIdParent}.contentBlock.body`,
      suppressWarnings: true,
      ...(values ? { values } : {}),
    }),
    buttonLabel: f(contentBlock?.buttonLabel, {
      debugId: `${debugIdParent}.contentBlock.resetButtonLabel`,
      suppressWarnings: true,
    }),
  }
}
