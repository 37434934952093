import { CountryConfiguration } from '../../types/api'
import { createConfig } from './createConfig'
import { createEnv } from './createEnv'

type CountryConfigOrError =
  | CountryConfiguration
  | null
  | undefined
  | { error: string | Record<string, string> }

declare global {
  interface Window {
    __ENV__: Record<string, string>
    __COUNTRY_CONFIG__: CountryConfigOrError
  }
}

const env = createEnv(() => window.__ENV__ || process.env || {})

export const appConfig = createConfig(env)
