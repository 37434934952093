import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&$button': {
      textTransform: 'uppercase',
      width: '100%',
      '&:first-of-type': {
        margin: theme.spacing(2, 0, 0, 0),
      },
      justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        '&:first-of-type': {
          margin: theme.spacing(0, 4, 0, 0),
        },
        height: 50,
        borderRadius: 25,
        width: theme.spacing(25),
        fontSize: theme.typography.subtitle2.fontSize,
      },
    },
  },
  buttonContainer: {
    '&$buttonContainer': {
      display: 'flex',
      flexDirection: 'column-reverse',
      margin: theme.spacing(5, 0, 0, 0),

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        margin: theme.spacing(5, 0, 0, 0),
      },
    },
  },
}))
