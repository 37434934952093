import { FC, lazy, Suspense } from 'react'

import { FullScreenLoader } from '../FullScreenLoader/FullScreenLoader'

const PMRejectionLazy = lazy(() => import('./PMRejectionContainer'))

export const PMRejectionContainer: FC = () => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <PMRejectionLazy />
    </Suspense>
  )
}
