import { FC } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { ErrorCode } from '../../types/errors'
import { getTranslatedTitleAndMessageForError } from '../../utils/error'

import { MessageBox } from '../MessageBox/MessageBox'

interface ErrorBoxHttpCodesProps {
  error?: string | number
}

export const ErrorBoxHttpCodes: FC<ErrorBoxHttpCodesProps> = ({ error }) => {
  const f = useMessageFormat()
  const translations = useTranslation()

  const {
    countryConfig: { contactPhone },
  } = useUxConfig()

  if (!error) {
    const { title, message } = getTranslatedTitleAndMessageForError(
      ErrorCode.SystemError,
      translations,
      f,
    )
    return <MessageBox type="error" title={title} message={message} />
  }

  if (typeof error === 'string') {
    const { title, message } = getTranslatedTitleAndMessageForError(
      error,
      translations,
      f,
    )
    return <MessageBox type="error" title={title} message={message} />
  } else {
    let errorCode = ErrorCode.SystemError
    switch (error) {
      case 403:
      case 404:
        errorCode = ErrorCode.PlateError
        break
      case 503:
        errorCode = ErrorCode.NetworkError
        break
    }
    const { title, message } = getTranslatedTitleAndMessageForError(
      errorCode,
      translations,
      f,
      { contactPhone },
    )

    return <MessageBox type="error" title={title} message={message} />
  }
}
