import { FC, useCallback } from 'react'

import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'
import { ResultsView } from '../ResultsView/ResultsView'

export const NotFoundPage: FC = () => {
  const { locale } = useUxConfig()
  const onClick = useCallback(
    () => window.location.replace(`${window.location.origin}/${locale}`),
    [locale],
  )
  return <ResultsView viewType="notFoundPage" buttonOnClick={onClick} />
}
