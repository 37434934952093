import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& [role="dialog"]': {
      zIndex: 5,
    },
    '& [role="application"]': {
      boxShadow: '0 2px 6px 0px rgb(0 0 0 / 20%)',
      [theme.breakpoints.up('md')]: {
        boxShadow:
          '4px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      },
      borderRadius: theme.spacing(0.5),
      '& .react-datepicker': {
        '& .react-datepicker__month-container': {
          '& .react-datepicker__header ': {
            '& .react-datepicker__day-names': {
              border: 'none',
            },
            '& .react-datepicker__navigation': {
              backgroundColor: theme.palette.common.white,
              '& .react-datepicker__navigation--current-month': {
                color: theme.palette.text.primary,
                fontWeight: 'normal',
              },
              '& .react-datepicker__navigation--next, .react-datepicker__navigation--previous':
                {
                  color: theme.palette.text.primary,
                  '&:not(:disabled)': {
                    '&:active, &:hover, &:focus': {
                      backgroundColor: theme.palette.grey['200'],
                    },
                  },
                  '&:disabled': {
                    color: theme.palette.grey['400'],
                  },
                },
            },
          },
          '& .react-datepicker__month': {
            border: 'none',
            '& .react-datepicker__week': {
              '& .react-datepicker__day--today': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
              },
              '& .react-datepicker__day--selected:not([aria-disabled="true"])':
                {
                  backgroundColor: theme.palette.primary.main,
                  '&:active, &:hover, &:focus': {
                    border: `2px solid ${theme.palette.primary.main}`,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  },
                },
              '& .react-datepicker__day--keyboard-selected': {
                border: 'none',
              },
              '& .react-datepicker__day': {
                '&:not([aria-disabled="true"])': {
                  '&:active, &:hover, &:focus': {
                    border: `2px solid ${theme.palette.grey['200']}`,
                    backgroundColor: theme.palette.grey['200'],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}))
