import { NativeSelect, NativeSelectProps } from '@velocity/ui'
import { ReactElement, useEffect, useState } from 'react'

import {
  DEFAULT_PERIODIC_CONFIG,
  getTimeline,
  PeriodicConfig,
} from '../../utils/time'

interface TimeDropdownProps {
  /**
   * Optional initial value
   */
  value?: string
  /**
   * Optional function to be called when value changes
   * @param selection
   */
  onChange?: NativeSelectProps['onChange']
  /**
   * Optional value for the title
   */
  title?: string
  /**
   * Optional value for the placeholder
   */
  placeholder?: string
  /**
   * Optional set of props to be passed to the underlying input field
   */
  inputProps?: NativeSelectProps['inputProps']
  /**
   * Optional config for creating a periodic range of time
   */
  periodicConfig?: PeriodicConfig
  /**
   * Optional options for rendering the field
   */
  renderer?: (option: string | number, key: number) => ReactElement
  /**
   * Optional set of options to be passed to the underlying select field
   */
  name?: string
}

export const TimeDropdown: (
  props: TimeDropdownProps,
) => ReactElement<TimeDropdownProps> | null = (props) => {
  const { periodicConfig, placeholder, value, name, ...other } = props

  const [choices, setChoices] = useState<string[]>()

  useEffect(() => {
    const config: PeriodicConfig = periodicConfig ?? DEFAULT_PERIODIC_CONFIG

    setChoices(getTimeline(config))
  }, [periodicConfig])

  return choices ? (
    <NativeSelect
      {...other}
      value={value}
      onChange={props.onChange}
      inputProps={{
        'data-e2e-component': 'select-dropdown',
      }}
      name={name}
    >
      <option value="">{placeholder}</option>
      {choices.map((choice) => (
        <option value={choice} key={choice}>
          {choice}
        </option>
      ))}
    </NativeSelect>
  ) : null
}
