// Filters a collection of translationKeys based on a list of Types
export const filterByTranslation = <TData, TReturnType>(
  dictionary: Record<string, TReturnType> | null,
  entities: TData[],
  translationKeyProp?: string,
  enabledProp?: string,
) =>
  dictionary
    ? entities.reduce<TReturnType[]>((acc, entity) => {
        const dictionaryEntry =
          dictionary[translationKeyProp ? entity[translationKeyProp] : entity]

        if (dictionaryEntry && enabledProp) {
          dictionaryEntry['enabled'] =
            entity[enabledProp] !== undefined
              ? Boolean(entity[enabledProp])
              : true
        }

        return dictionaryEntry ? [...acc, dictionaryEntry] : acc
      }, [])
    : []
