import {
  ValidationFunction,
  Validator,
  ValidationError,
} from '../types/validation'
import * as d from '../utils/date'
import { sanitizeNumber } from './number'

export const validationDefaults = {
  maxMileageDigits: 6,
}

export const compose =
  <T>(...validators: ReadonlyArray<Validator<T>>) =>
  (value: T) =>
    validators.reduce<ValidationError | undefined>(
      (error, validator) => error || validator(value),
      undefined,
    )

export const apply =
  <T>(
    validator: ValidationFunction<T>,
    validationError: ValidationError,
  ): Validator<T> =>
  (value: T) =>
    validator(value) ? undefined : validationError

export const required = <T>(value: T) =>
  typeof value === 'string' ? !!value.trim() : !!value

export const dateRequired = <T>(value: T) => value !== null

export const minLength =
  <T>(minimumLength: number) =>
  (value: string | ReadonlyArray<T>) => {
    const length =
      typeof value === 'string' ? value.trim().length : value.length

    return length >= minimumLength
  }

export const maxLength =
  <T>(maximumLength: number) =>
  (value: string | ReadonlyArray<T> | number) => {
    if (value === undefined) {
      return true
    }
    const length =
      typeof value === 'string'
        ? value.trim().length
        : typeof value === 'number'
        ? value.toString().length
        : value.length

    return length <= maximumLength
  }

export const minMaxLength =
  <T>(minimumLength: number, maximumLength: number) =>
  (value: string | ReadonlyArray<T>) => {
    return minLength(minimumLength)(value) && maxLength(maximumLength)(value)
  }

export const isNumber = (value: number | string) => {
  return !isNaN(+sanitizeNumber(value))
}

export const isUnsigned = (value: number | string) => {
  return /^\d+(?:\.\d+)?$/.test(sanitizeNumber(value))
}

export const isNonNegative = (value: number | string) => {
  return +sanitizeNumber(value) >= 0
}

export const isNonZero = (value: number | string) => {
  return sanitizeNumber(value) !== '0'
}

export const isNatural = (value: number | string) => {
  return /^[1-9]\d*$/.test(sanitizeNumber(value))
}

export const isEmail = (value: string) => {
  const sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]'
  const sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]'
  const sAtom =
    '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+'
  const sQuotedPair = '\\x5c[\\x00-\\x7f]'
  const sDomainLiteral = '\\x5b(' + sDtext + '|' + sQuotedPair + ')*\\x5d'
  const sQuotedString = '\\x22(' + sQtext + '|' + sQuotedPair + ')*\\x22'
  const sSubDomain = '(' + sAtom + '|' + sDomainLiteral + ')'
  const sWord = '(' + sAtom + '|' + sQuotedString + ')'
  const sDomain = sSubDomain + '(\\x2e' + sSubDomain + ')*'
  const sLocalPart = sWord + '(\\x2e' + sWord + ')*'
  const sAddrSpec = sLocalPart + '\\x40' + sDomain // complete RFC822 email address spec
  const sValidEmail = '^' + sAddrSpec + '$' // as whole string

  const reValidEmail = new RegExp(sValidEmail)
  return reValidEmail.test(value)
}

export const isPhoneNumber = (value: string) => {
  const regex = /^[0-9 ()+-./]*$/
  return regex.test(value)
}

export const matchRegex = (regex: string | RegExp) => (value: string) => {
  const rgx = typeof regex === 'string' ? new RegExp(regex) : regex
  return rgx.test(value)
}

export const isLettersOnly = (value: string) => {
  const regex = /^[a-zA-Z]+( +[a-zA-Z]+)*$/
  return regex.test(value)
}

export const isDateValid = (value: Date) => {
  // we want to supress thrown errors for invalid dates passed through validation
  return d.isValidDate(value, false)
}

export const isDateNotBefore = (beforeDate: Date) => (value: Date) => {
  if (!d.isValidDate(beforeDate)) {
    throw new Error('The "beforeDate" parameter is not a valid date string')
  }

  return d.isBeforeDate(beforeDate, value)
}

export const isDateAfter = (afterDate: Date) => (value: Date) => {
  if (!d.isValidDate(afterDate)) {
    throw new Error('The "afterDate" parameter is not a valid date string')
  }

  return d.isAfterDate(afterDate, value)
}

export const isDateBetweenIncluding =
  (minDate: Date, maxDate: Date) => (value: Date) => {
    if (!d.isValidDate(minDate)) {
      throw new Error('The "minDate" parameter is not a valid date string')
    }
    if (!d.isValidDate(maxDate)) {
      throw new Error('The "maxDate" parameter is not a valid date string')
    }

    return d.isBetweenDates(minDate, maxDate, value)
  }
