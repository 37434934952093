export const string = (value: string | undefined) =>
  value ? `${value}`.trim() : ''

export const isString = (value: unknown): value is string =>
  typeof value === 'string'

export const truthy = (value: string | undefined) =>
  ['y', 'yes', 'true'].includes(string(value).toLowerCase())

export const shortenString = (
  value: string,
  maxLength: number,
  replaceWith = '...',
) => {
  const replacementLength = replaceWith.length
  if (replacementLength >= maxLength || value.length < maxLength) return value

  return value.substring(0, maxLength - replacementLength).concat(replaceWith)
}

export const int = (value: string | undefined) => {
  const result = parseInt(value || '', 10)
  return isNaN(result) ? 0 : result
}
