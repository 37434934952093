import { supportedLocales } from '../i18n/supportedLocales'
import { AppLocale, CountryCode, LeaseplanCode } from '../types/i18n'

export const extractLocaleFromUrl = () => {
  const localeFromUrl = (window.location.pathname || '')
    .split('/')
    .filter(Boolean)[0]
  const matchingLocale = supportedLocales.find((supportedLocale) => {
    return supportedLocale === localeFromUrl
  })

  return matchingLocale || null
}

export const extractCountryFromLocale = (locale: AppLocale) =>
  locale.split('-')[1] as CountryCode

export const extractLanguageFromLocale = (locale: AppLocale) =>
  locale.split('-')[0]

export const countryToLPCode = (c: CountryCode): LeaseplanCode =>
  `LP${c.toUpperCase() as Uppercase<CountryCode>}`
