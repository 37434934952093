import { useLogger } from '@cj4/react-logger'
import { makeStyles, Theme, Typography, Box } from '@material-ui/core'
import {
  Place,
  PlacesAutocomplete,
  PlacesAutocompleteProps,
  VelFocusEventHandler,
} from '@velocity/ui'

import React, { FC, useCallback, useEffect, useRef } from 'react'

export interface Props {
  country: string
  name?: string
  className?: string
  title?: string
  placeholder?: string
  error?: string
  value?: string
  initialInputValue?: string
  onPlaceSelect(place?: Place): void
  onInputChange?: (nextValue: string) => void
  onSuggestionsChange?: (
    nextSuggestions: google.maps.places.AutocompletePrediction[],
  ) => void
  onBlur?: VelFocusEventHandler
  onFocus?: VelFocusEventHandler
  readOnly?: boolean
  id?: string
  inputProps?: PlacesAutocompleteProps['inputProps']
}

const useStyles = makeStyles((theme: Theme) => ({
  suggestionList: {
    '& > div[role=listbox]': {
      zIndex: theme.zIndex.modal,
    },
  },
}))

export const GooglePlacesAutocomplete: FC<Props> = (props) => {
  const {
    onPlaceSelect,
    country,
    title,
    onInputChange,
    initialInputValue,
    readOnly,
    className,
    id,
    value,
    inputProps,
    name,
  } = props
  const logger = useLogger()
  const classes = useStyles()

  // we do this to avoid adding value as a handleStateChange dependency
  const persistedInputValue = useRef(value)
  useEffect(() => {
    persistedInputValue.current = value
  }, [value])

  const handleInputChange: PlacesAutocompleteProps['onInputChange'] = (e) => {
    onInputChange && e.target.value != null && onInputChange(e.target.value)
  }

  const handleSuggestionsChange = useCallback(
    (event) => {
      !event.target.suggestions.length &&
        logger.warn(`Zero results for search ${persistedInputValue.current}`)
    },
    [logger],
  )

  const initialInputProps = initialInputValue
    ? {
        initialInputValue,
        initialSelectedItem: {
          value: initialInputValue,
          label: initialInputValue,
        },
      }
    : {}

  return (
    <Box className={className}>
      {title && (
        <Typography variant="subtitle2" component="label">
          {title}
        </Typography>
      )}
      <PlacesAutocomplete
        {...initialInputProps}
        className={classes.suggestionList}
        id={id}
        country={country}
        inputProps={
          {
            autoComplete: 'off',
            'data-e2e-component': 'autocomplete-text-field',
            ...inputProps,
          } as React.ComponentPropsWithoutRef<'input'>
        }
        name={name}
        onBlur={props.onBlur}
        inputValue={props.value}
        onFocus={props.onFocus}
        onSelect={(event) => onPlaceSelect(event.target.place)}
        onInputChange={handleInputChange}
        onSuggestionsChange={handleSuggestionsChange}
        placeholder={props.placeholder}
        placeTypes={['geocode']}
        readOnly={readOnly}
      />
    </Box>
  )
}
