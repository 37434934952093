import { Logger } from '@cj4/logger'
import { useLogger } from '@cj4/react-logger'
import axios, {
  Method,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios'
import {
  Dispatch,
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react'

import { ErrorPayload } from '../types/models'
import { delay } from '../utils/delay'

const requestAllowedFields = { data: ['PUT', 'POST', 'PATCH'] }

export interface RequestState<TResponse, TErrorPayload = ErrorPayload> {
  loading: boolean
  response?: AxiosResponse<TResponse>
  error?: AxiosError<TErrorPayload>
}
export interface RequestResponse<TResponse, TErrorPayload = ErrorPayload> {
  loading: boolean
  data?: TResponse
  error?: AxiosError<TErrorPayload>
}

export const REQUEST_START = 'REQUEST_START'
export const REQUEST_END = 'REQUEST_END'

interface RequestStartAction {
  type: typeof REQUEST_START
}

interface RequestEndAction<T> {
  type: typeof REQUEST_END
  payload: AxiosResponse<T>
  error?: boolean
}

type Action<T> = RequestStartAction | RequestEndAction<T>

export interface RetryPolicyConfig<TErrorPayload = ErrorPayload> {
  retries: number
  retryInterval: number
  retryFilter?: (error: AxiosError<TErrorPayload>) => boolean
}

export interface RequestConfig<T> {
  method?: Method
  baseURL?: string
  url?: string
  data?: T
  params?: T
  retryPolicy?: RetryPolicyConfig
}

const initialState = { loading: false }
const cancelTokenFactory = axios.CancelToken

/**
 * A generic retry filter which enables retries of requests when the network or
 * SF is down.
 **/
const DEFAULT_RETRY_FILTER = (error: AxiosError) => {
  const { response } = error
  return !response || error.response?.status === 503
}

export const DEFAULT_RETRY_POLICY = {
  retries: 3,
  retryInterval: 1000,
  retryFilter: DEFAULT_RETRY_FILTER,
}

export const reducer = <TResponse, TErrorPayload = ErrorPayload>(
  state: RequestState<TResponse, TErrorPayload>,
  action: Action<TResponse>,
): RequestState<TResponse, TErrorPayload> => {
  switch (action.type) {
    case REQUEST_START:
      return { loading: true }
    case REQUEST_END:
      return {
        ...state,
        loading: false,
        [action.error ? 'error' : 'response']: action.payload,
      }
    default:
      return state
  }
}

const requestFactory =
  <TResponse, TMappedResponse = TResponse>(
    dispatch: Dispatch<Action<TMappedResponse>>,
    mapOrFilterData?: (data: TResponse) => TMappedResponse,
  ) =>
  (logger: Logger) =>
  async (
    config: AxiosRequestConfig,
    retryPolicy: RetryPolicyConfig = { retries: 0, retryInterval: 0 },
  ) => {
    dispatch({ type: REQUEST_START })

    const { retries, retryInterval, retryFilter } = retryPolicy

    for (let tryNumber = 0; tryNumber < retries + 1; ++tryNumber) {
      if (config.cancelToken?.reason) {
        // the request has been cancelled
        return
      }
      const requestStartTimeMs = window.performance.now()

      const success = await axios
        .request(config)
        .then((result) => {
          logger.info(`[xhr] Request to '${config.url}' succeeded`, {
            tryNumber: tryNumber + 1,
            timeMs: window.performance.now() - requestStartTimeMs,
            statusCode: result.status,
            statusText: result.statusText,
            headers: result.headers,
          })
          dispatch({
            type: REQUEST_END,
            payload: mapOrFilterData
              ? { ...result, data: mapOrFilterData(result?.data) }
              : result,
          })
          return true
        })
        .catch(async (error) => {
          if (axios.isCancel(error)) {
            // if it's cancelled then we know a newer request is in progress
            return
          }
          logger.info(
            `[xhr] Request to '${config.url}' failed`,
            Object.assign({
              message: error.message,
              tryNumber: tryNumber + 1,
              timeMs: window.performance.now() - requestStartTimeMs,
              stackTrace: error.stackTrace,
              isNetworkError: !error.response,
              statusCode: error.response?.status,
              statusText: error.response?.statusText,
              headers: error.response?.headers,
            }),
          )

          const isNetworkError = !error.response
          const shouldRetry = retryFilter ? retryFilter(error) : isNetworkError
          if (shouldRetry && tryNumber < retries) {
            await delay(retryInterval)
            return false
          }

          dispatch({ type: REQUEST_END, error: true, payload: error })
          throw error
        })

      if (success) break
      continue
    }
  }

/**
 * The deferred argument of the hook is used in cases where an axios request needs
 * to be setup but not run immediately. This could be used with container components
 * which create request triggers that will be consumed by presentational components.
 *
 * The passed config should be immutable.
 */
export const useAxios = <
  TResponse,
  TErrorPayload = ErrorPayload,
  TRequest = {},
  TFilteredOrMappedResponse = TResponse,
>(
  config: RequestConfig<TRequest> = {},
  deferred = false,
  mapOrFilterData?: (data: TResponse) => TFilteredOrMappedResponse,
): [
  RequestResponse<TFilteredOrMappedResponse, TErrorPayload>,
  (config?: AxiosRequestConfig) => Promise<void>,
] => {
  const [state, dispatch] = useReducer<
    Reducer<
      RequestState<TFilteredOrMappedResponse, TErrorPayload>,
      Action<TFilteredOrMappedResponse>
    >
  >(reducer, initialState)
  const axiosConfig = useRef<AxiosRequestConfig>()
  const cancelTokenSource = useRef(cancelTokenFactory.source())
  const logger = useLogger()
  const request = requestFactory<TResponse, TFilteredOrMappedResponse>(
    dispatch,
    mapOrFilterData,
  )(logger)

  useEffect(() => {
    if (config.url) {
      const method = (config.method || 'GET').toUpperCase() as Method
      const requestAllowsData = requestAllowedFields.data.includes(method)
      const baseURL = config.baseURL

      axiosConfig.current = {
        method,
        ...(baseURL ? { baseURL } : {}),
        url: config.url,
        ...(requestAllowsData && config.data ? { data: config.data } : {}),
        ...(config.params ? { params: config.params } : {}),
        maxRedirects: 0,
        cancelToken: cancelTokenSource.current.token,
      }

      if (!deferred) {
        request(axiosConfig.current, config.retryPolicy).catch(logger.log)
      }
    }
    // We check the `config` instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  const cancelRequest = useCallback((reason?: string) => {
    cancelTokenSource.current.cancel(reason)
  }, [])

  const refetch = useCallback(
    (conf: AxiosRequestConfig = {}) => {
      // ensure that past requests are terminated
      cancelRequest()
      // generate a cancel token because if the old one is used all requests will fail
      cancelTokenSource.current = cancelTokenFactory.source()
      // update request config
      if (axiosConfig.current) {
        axiosConfig.current.cancelToken = cancelTokenSource.current.token
      }

      return request(
        {
          ...axiosConfig.current,
          ...conf,
        },
        config.retryPolicy,
      )
    },
    // We check the `config` instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config],
  )

  return [
    { loading: state.loading, data: state.response?.data, error: state.error },
    refetch,
  ]
}
