import { useCallback, useState } from 'react'

import { GeolocationCoords } from '../types/models'

export interface GeolocationState {
  coords?: GeolocationCoords
  error?: GeolocationPositionError
  loading: boolean
}

export const useGeolocation = (
  onGeolocationUpdate?: (state: GeolocationState) => void,
): [GeolocationState, () => void] => {
  const [locationState, setLocationState] = useState<GeolocationState>({
    loading: false,
  })
  const handleSuccess = useCallback(
    (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords
      const state = {
        coords: { lat: latitude, lng: longitude },
        loading: false,
      }
      setLocationState(state)
      onGeolocationUpdate && onGeolocationUpdate(state)
    },
    [onGeolocationUpdate],
  )

  const handleError = useCallback(
    (error: GeolocationPositionError) => {
      setLocationState((prevState) => {
        const state = { ...prevState, error, loading: false }
        onGeolocationUpdate && onGeolocationUpdate(state)
        return state
      })
    },
    [onGeolocationUpdate],
  )

  const getLocation = useCallback(() => {
    if (!locationState.coords) {
      setLocationState({ loading: true })
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
    } else {
      onGeolocationUpdate && onGeolocationUpdate(locationState)
    }
  }, [
    handleError,
    handleSuccess,
    locationState,
    setLocationState,
    onGeolocationUpdate,
  ])

  return [locationState, getLocation]
}
