import { useLogger } from '@cj4/react-logger'
import { useCallback, useMemo } from 'react'

import { useAppConfig } from '../../context/AppConfigContext/AppConfigContext'
import { VehicleServicesRequest, VehicleService } from '../../types/api'
import { ErrorPayload } from '../../types/models'
import {
  RequestConfig,
  useAxios,
  DEFAULT_RETRY_POLICY as retryPolicy,
  RequestResponse,
} from '../useAxios'

type VehicleServicesRequestFunc = (
  data: VehicleServicesRequest,
) => Promise<void>

export type VehicleServicesResponseType = RequestResponse<
  VehicleService[],
  ErrorPayload<string>
>

export const useVehicleServices = (): [
  VehicleServicesResponseType,
  VehicleServicesRequestFunc,
] => {
  const logger = useLogger()
  const {
    endpoints: { apiBase: baseURL, vehicleServices: vehicleServicesEndpoint },
  } = useAppConfig()

  const requestConfig: RequestConfig<VehicleServicesRequest> = useMemo(
    () => ({
      baseURL,
      retryPolicy,
      method: 'POST',
      url: vehicleServicesEndpoint,
    }),
    [baseURL, vehicleServicesEndpoint],
  )

  const [vehicleServicesResponse, axiosPost] = useAxios<
    VehicleService[],
    ErrorPayload<string>,
    VehicleServicesRequest
  >(requestConfig, true)

  const fetchVehicleServices: VehicleServicesRequestFunc = useCallback(
    (data: VehicleServicesRequest) =>
      axiosPost({ data }).catch((error) => {
        logger.error(error)
        throw error
      }),
    [axiosPost, logger],
  )

  return [vehicleServicesResponse, fetchVehicleServices]
}
