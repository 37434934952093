import * as R from 'ramda'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useUxConfig } from '../context/UxConfigContext/UxConfigContext'

export interface TrackingEventDescriptor {
  event: 'pageView' | 'genericEvent' | 'formEvent'
  category?: 'temp' | 'search' | 'results'
  action?: 'click' | 'submit' | 'results' | 'refinement'
  label?: string
  resultsCount?: number
  nonInteraction?: boolean
  supplierIdName?: string
  supplierDistance?: number
  formName?: 'garage booking' | 'garage search'
  formStep?: string
  formContext?: 'ftb'
  formErrorField?: string
  formErrorType?: string
  meta?: Record<string, string>
  bookingResult?: 'success' | 'fail'
}

interface PageViewEventDescriptor {
  /** Name of the item, always in English. MUST be unique. */
  itemName: string
  /** Item name in local language */
  displayName: string
  /** Referrer */
  referrer?: string
  /**
   * Indicates page views generated by the automated re-routing
   * (e.g. Flow missing data for the selected step, so going one step back)
   */
  automatedRouting?: boolean
}

declare global {
  interface Window {
    dataLayer?: Array<
      Record<
        string,
        string | number | boolean | Record<string, string | boolean>
      >
    >
  }
}

export interface Tracker {
  trackEvent: (event: TrackingEventDescriptor) => void
  trackPageView: (pageViewEvent: PageViewEventDescriptor) => void
}

export const useTracking = (): Tracker => {
  const { country } = useUxConfig()
  const { locale } = useParams<{ locale?: string }>()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  const trackEvent = useCallback(
    ({
      event,
      category,
      action,
      label,
      resultsCount,
      nonInteraction,
      supplierIdName,
      supplierDistance,
      formName,
      formStep,
      formContext,
      formErrorField,
      formErrorType,
      bookingResult,
    }: TrackingEventDescriptor) => {
      if (!window.dataLayer) {
        return
      }

      const eventToPush = {
        event: event || 'genericEvent',
        eventCategory: category,
        eventAction: action,
        formName,
        formStep,
        formContext,
        eventLabel: label,
        totalSearchResults: resultsCount,
        nonInteraction,
        supplierIdName,
        supplierDistance,
        formErrorField,
        formErrorType,
        bookingResult,
      }

      window.dataLayer.push(
        // the cast is to remove undefined from R.Filter return type
        R.filter(
          (val) => val !== undefined,
          eventToPush,
        ) as Required<Window>['dataLayer'][0],
      )
    },
    [],
  )

  const trackPageView = ({
    referrer,
    itemName,
    displayName,
    automatedRouting,
  }: PageViewEventDescriptor) => {
    if (!window.dataLayer) {
      return
    }

    window.dataLayer.push({
      event: 'pageView',
      meta: {
        userType: 'driver' /* always driver unless there are other roles */,
        pageURL: window.location.href /* current URL */,
        country: country.code /* country that is served */,
        language:
          (locale || '').toLowerCase().split('-')[0] ||
          '' /* language in what the website is served */,
        valueStream: 'FTB' /* fixed - product name*/,
        itemName,
        displayName,
        pageId: `${(locale || '').toLowerCase()} | Home | ${itemName}`,
        pageTitle: 'Fast Track Booking | LeasePlan',
        hostname: window.location.origin,
        redirect: '0',
        referrer: referrer || window.document.referrer || '',
        automatedRouting: automatedRouting || false,
      },
    })
  }

  return { trackEvent, trackPageView }
}
