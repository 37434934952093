import { useEffect } from 'react'

interface Config {
  title: string
  description: string
}

export const useMetaTags = ({ title, description }: Config) => {
  useEffect(() => {
    applyMetaTags(title, description)
  }, [title, description])
}

function applyMetaTags(title: string, description: string) {
  const titleElement = document.head.querySelector('title')
  if (titleElement) {
    titleElement.innerText = title
  }
  const descriptionElement = document.head.querySelector(
    'meta[name="description"]',
  )
  if (descriptionElement) {
    descriptionElement.setAttribute('content', description)
  }
}
