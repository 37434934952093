import { FormDictionary } from '../types/i18n'
import { FormatMessageApi } from '../types/validation'
import * as v from './validation'

export const getVehicleValidators = (
  licensePlateFormat: string,
  format: FormatMessageApi<string>,
  errorMessages?: FormDictionary['errorMessages'],
) => ({
  licensePlate: v.compose(
    v.apply<string>(v.required, {
      type: 'plateRequired',
      message: format(errorMessages?.['plateRequired'], {
        debugId: 'forms.pMRejection.errors.plateRequired',
      }),
    }),
    (licensePlate) => {
      return v.apply<string>(v.matchRegex(licensePlateFormat), {
        type: 'plateFormat',
        message: format(errorMessages?.['plateFormat'], {
          debugId: 'forms.pMRejection.errors.plateFormat',
        }),
      })(licensePlate?.replace(/[^\w]|_/g, ''))
    },
  ),
  mileage: v.compose(
    v.apply<number>(v.required, {
      type: 'mileageRequired',
      message: format(errorMessages?.['mileageRequired'], {
        debugId: 'forms.pMRejection.errors.mileageRequired',
      }),
    }),
    v.apply<number>(v.isNumber, {
      type: 'invalidNumber',
      message: format(errorMessages?.['invalidNumber'], {
        debugId: 'forms.pMRejection.errors.invalidNumber',
      }),
    }),
    v.apply<number>(v.isNonNegative, {
      type: 'negativeNumber',
      message: format(errorMessages?.['negativeNumber'], {
        debugId: 'forms.pMRejection.errors.negativeNumber',
      }),
    }),
    v.apply<number>(v.isUnsigned, {
      type: 'invalidNumber',
      message: format(errorMessages?.['invalidNumber'], {
        debugId: 'forms.pMRejection.errors.invalidNumber',
      }),
    }),
    v.apply<number>(v.isNonZero, {
      type: 'nonZeroNumber',
      message: format(errorMessages?.['nonZeroNumber'], {
        debugId: 'forms.pMRejection.errors.nonZeroNumber',
      }),
    }),
    v.apply<number>(v.isNatural, {
      type: 'naturalNumber',
      message: format(errorMessages?.['naturalNumber'], {
        debugId: 'forms.pMRejection.errors.naturalNumber',
      }),
    }),
    v.apply<number>(v.maxLength(v.validationDefaults.maxMileageDigits), {
      type: 'maximumDigits',
      message: format(errorMessages?.['maximumDigits'], {
        debugId: 'forms.pMRejection.errors.maximumDigits',
      }),
    }),
  ),
})
