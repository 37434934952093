import { makeStyles } from '@material-ui/core'
import { FlexColumn } from '@velocity/ui/draft'
import { FC } from 'react'

const useSupplierResultsActionsStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    justifyContent: 'space-between',
    top: 0,
    left: 0,
    height: '100vh',
    padding: theme.spacing(1, 2, 2, 2),
    zIndex: 4,
    pointerEvents: 'none',

    '& > *': {
      pointerEvents: 'auto',
    },

    '& button:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))

export const SupplierResultsActions: FC = ({ children }) => {
  const styles = useSupplierResultsActionsStyles()
  return <FlexColumn className={styles.root}>{children}</FlexColumn>
}
