import * as R from 'ramda'
import { useRef, useCallback } from 'react'

import { StepComponent } from '../../../types/flow'
import { DriverForm, DriverFormValues } from '../../forms/DriverForm/DriverForm'

export type DriverStepValues = DriverFormValues

const DriverStep: StepComponent<DriverStepValues> = (props) => {
  const { onPreviousStep, onNextStep, onUpdateStep, stepData } = props

  const initialValuesRef = useRef<DriverStepValues | undefined>(
    hasDriverFormValues(stepData)
      ? {
          firstName: stepData.firstName,
          lastName: stepData.lastName,
          email: stepData.email,
          phoneNumber: stepData.phoneNumber,
        }
      : undefined,
  )

  const handleSubmit = useCallback(
    (formData: DriverFormValues) => {
      onUpdateStep(formData)

      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  return (
    <DriverForm
      initialValues={initialValuesRef.current}
      onGoBack={onPreviousStep}
      onSubmit={handleSubmit}
    />
  )
}

export const hasDriverFormValues = (x: unknown): x is DriverFormValues =>
  R.allPass([
    R.pipe(R.prop('firstName'), R.is(String)),
    R.pipe(R.prop('lastName'), R.is(String)),
    R.pipe(R.prop('email'), R.is(String)),
    R.pipe(R.prop('phoneNumber'), R.is(String)),
  ])(x)

// lazy currently supports only default exports (https://reactjs.org/docs/code-splitting.html#named-exports)
export default DriverStep
