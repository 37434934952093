import { makeStyles, Theme } from '@material-ui/core'

import { copyFontStyles } from '../../../utils/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: theme.spacing(0),
    flexFlow: 'row wrap',
    alignItems: 'baseline',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  googleAutocomplete: {
    '& [role="tooltip"]': {
      zIndex: 1,
    },
    '& div[role="listbox"]': {
      zIndex: 4,
      '& div[aria-selected="true"]': {
        borderLeft: `3px ${theme.palette.primary.main} solid`,
        '& div:first-child': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  field: {
    [theme.breakpoints.up('md')]: {
      '&&': {
        maxWidth: '23%',
        marginRight: theme.spacing(3),
      },
    },
  },
  de: {
    [theme.breakpoints.up('md')]: {
      '& label': {
        height: 40,
        display: 'inline-block',
      },
    },
  },
  timeField: {
    [theme.breakpoints.up('md')]: {
      '&&': {
        marginRight: 0,
      },
    },
  },
  datePicker: {
    '&& > div[role=dialog]': {
      zIndex: theme.zIndex.modal,
    },
  },
  locationField: {
    '&&': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '48%',
      },
    },
  },
  searchLabel: {
    ...copyFontStyles(theme.typography.h6),
  },
  card: {
    '&&': {
      paddingBottom: '10px',
    },
  },
  errorField: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      '&&': {
        marginTop: theme.spacing(2),
      },
    },
  },
  searchButton: {
    '&&': {
      justifyContent: 'center',
    },
  },
  geolocationHelperText: {
    color: theme.palette.grey['A400'],
  },
}))

export const useIconButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
}))
