import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  checkboxCardWrapper: {
    '&&': {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      paddingBottom: theme.spacing(2),
      '&:nth-child(odd)': {
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        '&&': {
          width: '100%',
          paddingRight: theme.spacing(0),
        },
      },
    },
  },
  checkboxCard: {
    '&&': {
      width: '100%',
      height: '100%',
    },
  },
  checkBoxTip: {
    '&$checkBoxTip': {
      marginTop: theme.spacing(1),
    },
  },
}))
