import { CountryCodeUppercase } from '../types/i18n'
import { string } from './string'

export const getRebrandedCountries = (
  env: Record<string, string | undefined>,
) => {
  return string(env.REACT_APP_REBRANDED_COUNTRIES)
    .split(',')
    .filter(Boolean)
    .map((c) => c.toUpperCase() as CountryCodeUppercase)
}
