import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey['500']}`,
    background: theme.palette.grey['50'],
    fontSize: '0.75rem',
    margin: theme.spacing(0.5),
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    minWidth: 100,
    '& span': {
      flexGrow: 1,
    },
  },
  removeIcon: {
    color: theme.palette.grey['500'],
    marginLeft: theme.spacing(1),
    flexShrink: 0,
  },
}))
