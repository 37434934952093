import { FC, lazy, Suspense } from 'react'

import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'

const DoneStepLazy = lazy(() => import('./DoneStep'))

export const DoneStepContainer: FC = () => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <DoneStepLazy />
    </Suspense>
  )
}
