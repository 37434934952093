import { Place, useGoogleMapsService } from '@velocity/ui'
import { useState } from 'react'

import { useGeolocation, GeolocationState } from './useGeolocation'

export interface ReverseGeolocationState extends GeolocationState {
  place: Place | null
}

/**
 * Detects the current user's location (`useGeolocation` under the hood) and extends it with
 * reverse geocoding data (a Google Maps Place).
 * @param onGeolocationUpdate
 */
export const useReverseGeolocation = (
  onGeolocationUpdate?: (state: ReverseGeolocationState) => void,
): [ReverseGeolocationState, () => void] => {
  const [isPlaceLoading, setPlaceLoading] = useState(false)
  const [place, setPlace] = useState<Place | null>(null)
  const { findPlaceByLocation } = useGoogleMapsService()
  const handleGeolocationUpdate = async (
    geolocationState: GeolocationState,
  ) => {
    if (!geolocationState?.coords) {
      setPlaceLoading(false)
      onGeolocationUpdate &&
        onGeolocationUpdate({ ...geolocationState, place: null })
      return
    }
    try {
      const decodedPlace = await findPlaceByLocation(geolocationState.coords)
      if (decodedPlace) {
        setPlace(decodedPlace)
      }
      setPlaceLoading(false)
      onGeolocationUpdate &&
        onGeolocationUpdate({ ...geolocationState, place: decodedPlace })
    } catch (e) {
      setPlaceLoading(false)
      onGeolocationUpdate &&
        onGeolocationUpdate({ ...geolocationState, place: null })
    }
  }
  const [state, getLocation] = useGeolocation(handleGeolocationUpdate)
  const getPlace = () => {
    setPlaceLoading(true)
    getLocation()
  }

  return [
    { ...state, loading: state.loading || isPlaceLoading, place },
    getPlace,
  ]
}
