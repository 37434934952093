import { makeStyles } from '@material-ui/core'
import { FlexColumn } from '@velocity/ui/draft'
import { FC } from 'react'

export const useSupplierResultsPanelStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2, 2, 2),
    maxWidth: theme.spacing(40),
    overflow: 'auto', // otherwise it's not scrollable on landscape mobile
    msOverflowStyle: '-ms-autohiding-scrollbar',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2, 10, 2),
    },
  },
}))

export const SupplierResultsPanel: FC = ({ children }) => {
  const styles = useSupplierResultsPanelStyles()
  return <FlexColumn className={styles.root}>{children}</FlexColumn>
}
