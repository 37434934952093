import { makeStyles, Typography } from '@material-ui/core'
import { FC } from 'react'

const useSupplierCardInfoStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    lineHeight: '20px',
  },
}))

export const SupplierCardInfo: FC = ({ children }) => (
  <Typography
    variant="caption"
    className={useSupplierCardInfoStyles().root}
    component="span"
  >
    {children}
  </Typography>
)
