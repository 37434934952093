import { makeStyles, Theme } from '@material-ui/core'

export const useFooterStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: theme.spacing(2),
    alignSelf: 'normal',
  },
  delimiter: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  footerContainer: {
    marginTop: 'auto',
    width: '100%',
  },
}))
