import { Container, makeStyles } from '@material-ui/core'
import { useBreakpoint } from '@velocity/styling/breakpoint/useBreakpoint/useBreakpoint'
import { Headline } from '@velocity/ui'
import { FlexBox } from '@velocity/ui/draft'
import { FC } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useUxConfigUnsafe } from '../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { BrandTheme } from '../../types/theme'
import { JourneyLine } from '../Icons/JourneyLine'

const usePageBannerStyles = makeStyles((theme) => ({
  wrapper: {
    '&$wrapper': {
      position: 'relative',
      height: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(15),
      },
    },
  },
  panel: {
    '&$panel': {
      backgroundImage: 'linear-gradient(#336e7d, #369399)',
      overflow: 'hidden',
      position: 'absolute',
      zIndex: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
  },
  svg: {
    '&$svg': {
      left: '50%',
      position: 'absolute',
      top: 0,
      transform: 'translateX(-50%)',
    },
  },
  title: {
    '&$title': {
      zIndex: 0,
      color: theme.palette.common.white,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const PageBanner: FC = () => {
  const { wrapper, panel, title, container, svg } = usePageBannerStyles()
  const f = useMessageFormat()
  const translations = useTranslation()
  const stepTranslations = translations?.steps?.shared
  const isMobile = useBreakpoint()?.toString() === 'XS'
  const theme = useUxConfigUnsafe()?.theme

  return (
    <FlexBox className={wrapper}>
      <div className={panel} data-e2e-component="page-banner">
        {theme !== BrandTheme.Ayvens && <JourneyLine className={svg} />}
        <Container className={container}>
          <Headline variant={isMobile ? '200' : '500'} className={title}>
            {f(stepTranslations?.otherData['bannerTitle'], {
              debugId: 'steps.shared.otherData.bannerTitle',
            })}
          </Headline>
        </Container>
      </div>
    </FlexBox>
  )
}
