import { makeStyles, Theme } from '@material-ui/core'

interface StylesProps {
  showMap?: boolean
}

export const useGarageStepStyles = makeStyles<Theme, StylesProps>(() => ({
  root: ({ showMap }: StylesProps) => ({
    width: '100%',
    maxHeight: showMap ? '100vh' : '100%',
  }),
}))
