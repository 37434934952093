import { makeStyles, Typography, Box } from '@material-ui/core'
import { LocationIcon } from '@velocity/icons/system'
import { FormField, LinkStandalone } from '@velocity/ui'
import { useCallback } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import {
  useGeolocation as useGeolocationHook,
  GeolocationState,
} from '../../hooks/useGeolocation'
import { useMessageFormat } from '../../hooks/useMessageFormat'

interface GeolocationButtonProps<TGeolocationState extends GeolocationState> {
  className?: string
  /**
   * Event fired when geolocation fetching is about to be started.
   */
  onStartFetchingLocation?: () => void
  /**
   * Optional callback to get the result. Alternatively, you can put an effect on the returned state.
   * @param location
   */
  onLocationResult?: (state: TGeolocationState) => void
  /**
   * Optional parameter to inject your implementation of a `useGeolocation` hook.
   * @param onGeolocationUpdate
   */
  useGeolocation: (
    onGeolocationUpdate?: (state: TGeolocationState) => void,
  ) => [TGeolocationState, () => void]
}

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'flex-start',
    color: theme.palette.secondary.dark,
    paddingLeft: 0,
    textAlign: 'left',
  },
  label: {
    '&&': {
      textTransform: 'none',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      display: 'inline',
    },
  },
  locationIcon: {
    color: theme.palette.primary.main,
  },
}))

export const GeolocationButton = <TGeolocationState extends GeolocationState>({
  className,
  onStartFetchingLocation,
  onLocationResult,
  useGeolocation,
}: GeolocationButtonProps<TGeolocationState>) => {
  const classes = useStyles()
  const f = useMessageFormat()
  const translations = useTranslation()
  const formTranslations = translations?.forms?.shared

  const handleGeolocationResult = useCallback(
    (state: TGeolocationState) => {
      onLocationResult?.(state)
    },
    [onLocationResult],
  )
  const [{ loading, error }, requestLocation] = useGeolocation(
    handleGeolocationResult,
  )
  const handleClick = useCallback(() => {
    typeof onStartFetchingLocation === 'function' && onStartFetchingLocation()
    requestLocation()
  }, [onStartFetchingLocation, requestLocation])

  return (
    <FormField
      label={<></>}
      errorMessage={
        error &&
        f(formTranslations?.errorMessages['useGeolocation'], {
          debugId: 'forms.shared.useGeolocationError',
        })
      }
      className={className}
      renderAsGroup={true}
      hideLabel={true}
    >
      <LinkStandalone
        data-e2e-component="geolocation-button"
        component="button"
        onClick={handleClick}
        className={classes.root}
        disabled={loading}
      >
        <LocationIcon
          size="m"
          className={classes.locationIcon}
          color="primary"
        />
        <Box pl={0.5}>
          <Typography
            component="label"
            variant="body2"
            className={classes.label}
          >
            {loading
              ? f(formTranslations?.labels['useGeolocationLoading'], {
                  debugId: 'forms.shared.useGeolocationLoading',
                })
              : f(formTranslations?.labels['useGeolocation'], {
                  debugId: 'forms.shared.useGeolocation',
                })}
          </Typography>
        </Box>
      </LinkStandalone>
    </FormField>
  )
}

GeolocationButton.defaultProps = {
  useGeolocation: useGeolocationHook,
}
