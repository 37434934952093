import { makeStyles } from '@material-ui/core'
import { FormField as VelocityFormField } from '@velocity/ui'
import clsx from 'clsx'
import { FieldValidator } from 'final-form'
import { ReactElement, ReactNode } from 'react'
import { Field, FieldMetaState, FieldInputProps } from 'react-final-form'

interface FormFieldStyles {
  formControl?: string
}

export interface FormFieldProps<T> {
  /**
   * Value for the name attribute of the underlying HTML input.
   */
  name: string
  /**
   * Optional label for the form field.
   */
  label?: ReactNode
  /**
   * Optional HTML for value for the form field.
   */
  htmlFor?: string
  /**
   * Optional FieldValidator for the form field.
   */
  validator?: FieldValidator<T>
  /**
   * Sets whether the form field should be displayed inline.
   * Horizontal paddings are applied in between such fields, making it possible to still control their
   * widths with pixels or percents.
   */
  inline?: boolean
  /**
   * Optional Form Field Styles to set the styling of the form control and form label of the field.
   */
  classes?: FormFieldStyles
  /**
   * Children as function to render your inputs.
   * @param fieldProps
   */
  children: (fieldProps: {
    input: FieldInputProps<T, HTMLElement>
    meta: FieldMetaState<T>
  }) => ReactElement | ReactElement[]
  /**
   * A hint to display below the field label.
   */
  hint?: string
  /**
   * In case of a Checkbox/RadioGroup, or multiple inputs, group should be set to true.
   */
  group?: true
  /**
   * Optional Required for the form field.
   */
  required?: boolean
  /**
   * Optional Type for the form field used by react final form to determine field type.
   */
  type?: string
  /**
   * A custom show error function
   */
  showErrorFn?: <TFieldValue>(
    meta: FieldMetaState<TFieldValue>,
  ) => string | null | undefined
}

function showErrorFnDefaultFactory<TFieldValue>() {
  return (meta: FieldMetaState<TFieldValue>) =>
    meta.error && meta.touched ? meta.error.message : null
}

export const FormField: <T>(props: FormFieldProps<T>) => ReactElement = ({
  name,
  label,
  htmlFor,
  inline,
  validator,
  children,
  classes,
  hint,
  showErrorFn,
  group,
  required = true,
  type,
}) => {
  const ownClasses = useStyles()
  const showError = showErrorFn || showErrorFnDefaultFactory()

  return (
    <Field name={name} validate={validator} type={type}>
      {({ input, meta }) => (
        <>
          <VelocityFormField
            errorMessage={showError(meta)}
            label={label}
            className={clsx(
              classes?.formControl,
              ownClasses.root,
              inline && ownClasses.inline,
            )}
            {...(group ? { renderAsGroup: true } : { id: htmlFor })}
            required={required}
            hint={hint}
          >
            {children({ input, meta })}
          </VelocityFormField>
        </>
      )}
    </Field>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    flexGrow: 1,
    '& + &': {
      marginTop: spacing(3),
      [breakpoints.up('md')]: {
        marginTop: 0,
      },
    },
  },
  inline: {
    '&&': {
      paddingRight: spacing(1.5),
      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
}))
