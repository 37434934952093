import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    outline: 0,
  },
  select: {
    position: 'relative',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.spacing(0.25),
    color: theme.palette.grey[500],
    display: 'inline-flex',
    width: '100%',
    padding: theme.spacing(1, 7, 1, 1.5),
    '&:hover': {
      border: `1px solid ${theme.palette.grey[900]}`,
    },
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: theme.spacing(5.25),
  },
  error: {
    borderColor: theme.palette.error.dark,
    background: theme.palette.error.light,
  },
  compact: {
    padding: theme.spacing(0.25, 7, 0.25, 0.25),
  },
  content: {
    flexGrow: 1,
  },
  iconWrapper: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1.25),
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  chevronIcon: {
    '&$chevronIcon': {
      color: theme.palette.grey[900],
    },
  },
  dropDown: {
    marginTop: theme.spacing(1),
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(2),
    width: '100%',
  },
  input: {
    height: 0,
    left: '-9999px',
    opacity: 0,
    position: 'absolute',
    width: 0,
  },
  itemsGroup: {
    width: '100%',
  },
}))
