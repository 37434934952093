import { makeStyles, Theme, Typography } from '@material-ui/core'
import { CarMissingLargePictogram } from '@velocity/icons/pictogram'
import { FC } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'

const useStylesNoSupplierResults = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  heading: {
    padding: 0,
    margin: theme.spacing(0, 0, 1, 0),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(21),
    height: theme.spacing(21),
  },
}))

export const NoSupplierResults: FC = () => {
  const classes = useStylesNoSupplierResults()
  const f = useMessageFormat()
  const translations = useTranslation()
  const stepTranslations = translations?.steps?.garage

  return (
    <div className={classes.root} data-e2e-component="no-supplier-results">
      <div
        className={classes.icon}
        data-e2e-component="no-supplier-result-icon"
      >
        <CarMissingLargePictogram name="carMissing" />
      </div>
      <Typography variant="h6">
        {f(stepTranslations?.otherData['noGarageHint'], {
          debugId: 'steps.garage.noGarageHint',
        })}
      </Typography>
    </div>
  )
}
