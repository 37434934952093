import { useLogger } from '@cj4/react-logger'
import { IntlMessageFormat } from 'intl-messageformat'
import { Fragment, useCallback, useRef } from 'react'

import { useTranslation } from '../context/TranslationContext/TranslationContext'
import { AppLocale } from '../types/i18n'
import { FormatConfig, FormatMessageApi, Message } from '../types/validation'

// TODO: https://leaseplan-digital.atlassian.net/browse/CJ4VS-1578
export const useMessageFormat = <
  T extends string | number | JSX.Element,
>(): FormatMessageApi<T> => {
  const logger = useLogger()
  const { locale } = useTranslation()
  const hasLoggedMissingKeys = useRef<boolean>()
  const unknownSitecoreKey = 'unknown key'

  const createFormattedMessage = useCallback(
    (messageTemplate?: Message, config?: FormatConfig<T>) => {
      const missingTranslationMessage = `Missing Sitecore translation for ${
        config?.debugId || unknownSitecoreKey
      }`

      if (typeof messageTemplate === 'object') {
        if (!config?.suppressWarnings) {
          logger.error(missingTranslationMessage)
        }
        return null
      }
      if (!messageTemplate) {
        hasLoggedMissingKeys.current = true

        // log missing Sitecore translation
        if (!hasLoggedMissingKeys.current && !config?.suppressWarnings) {
          logger.error(missingTranslationMessage)
        }
      }

      return typeof messageTemplate !== 'number'
        ? formatMessage(locale, messageTemplate, config)
        : messageTemplate
    },
    [locale, logger],
  )

  const t = useCallback(
    (
      messageTemplate?: string | number | null | undefined,
      config?: FormatConfig<T>,
    ) => {
      const message = createFormattedMessage(messageTemplate as string, config)

      return Array.isArray(message) ? message.join('') : message
    },
    [createFormattedMessage],
  )

  t['JSX'] = useCallback(
    (
      messageTemplate?: string | number | null | undefined,
      config?: FormatConfig<T>,
    ) => {
      const message = createFormattedMessage(messageTemplate as string, config)

      return Array.isArray(message) ? (
        message.map((messageComponent, i) => (
          <Fragment key={i}>{messageComponent}</Fragment>
        ))
      ) : (
        <>{message}</>
      )
    },
    [createFormattedMessage],
  )

  return t as FormatMessageApi<T>
}

export function formatMessage<T extends string | number | JSX.Element>(
  locale: AppLocale,
  messageTemplate: string | null | undefined,
  config?: FormatConfig<T>,
) {
  const message = new IntlMessageFormat(messageTemplate || '', locale)

  try {
    return message.format(config?.values || {})
  } catch (e) {
    // the translation is present, but is incorrect
    // e.g. in the intl formatting rule the `other` keyword is translated to another language by accident
    // (see also comments to https://leaseplan-digital.atlassian.net/browse/CJ4VS-1586)
    return ''
  }
}
