/**
 * The API error codes that can be returned to frontend
 * Taken from https://gitlab.leaseplan.io/digital/cj4/ftb/fast-track-booking/-/blob/feature/eligibility-check/src/main/java/com/leaseplandigital/cj4/ftb/exception/ErrorMessage.java
 */
export enum ErrorCode {
  // API error code
  FailedToGetSuppliers = 'ER_100',
  FailedToProcessRequestBody = 'ER_101',
  EmptyLicensePlate = 'ER_107',
  InvalidLicensePlateCharacter = 'ER_108',
  InvalidLicensePlateFormat = 'ER_109',
  InvalidCountryCode = 'ER_110',
  InvalidSupplierId = 'ER_111',
  FailedToGetCountryConfiguration = 'ER_112',
  InternalServerError = 'ER_113',
  InvalidRequestParameter = 'ER_114',
  FailedToSendVerificationEmail = 'ER_115',
  InvalidConfirmationCode = 'ER_116',
  VehicleNotFound = 'ER_117',
  ContactNotFound = 'ER_118',
  NoEmptySlots = 'ER_119',
  FailedToCreateContact = 'ER_120',
  FailedToCreateBooking = 'ER_121',
  MultipleVehiclesForPlate = 'ER_122',
  BookingAlreadyProcessed = 'ER_123',
  FailedToFindProposal = 'ER_128',
  FailedToRejectProposal = 'ER_129',
  FailedToUpdateProposal = 'ER_130',
  DifferentLicensePlateInProposal = 'ER_131',
  FailedToSearchCase = 'ER_132',
  FailedToUpdateCase = 'ER_133',
  UnavailabledMalfunctios = 'ER_134',
  FailedToSendBookingEmail = 'ER_135',
  VehicleNotEligible = 'ER_136',
  UnavailableServices = 'ER_137',
  UnavailableBookingDate = 'ER_138',
  ContractInThresholdPeriod = 'ER_142',

  // internal (FTB frontend) error code
  NetworkError = 'networkError',
  PlateError = 'plateError',
  SystemError = 'systemError',
}

/**
 * When a translated message for a BE error code should be displayed in UI then
 * - if needed introduce a new error code in {@link ErrorCode} enum
 * - add the error code enum name in camel case to SiteCore: Steps > Shared > Error Messages > [errorCodeName]
 * - use {@link ErrorBoxHttpCodes} component to display it
 */
export const ERROR_CODE_TO_SITECORE_KEY_MAP = Object.entries(ErrorCode).reduce(
  (acc, curr) => {
    const sitecoreKey = curr[0][0].toLowerCase() + curr[0].slice(1)
    const errorCode = curr[1]
    return { ...acc, [errorCode]: sitecoreKey }
  },
  {},
)
