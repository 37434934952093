import { MalfunctionCode } from '../types/api'
import { FormDictionary } from '../types/i18n'
import { FormatMessageApi } from '../types/validation'
import * as v from './validation'

export const getActivityValidators = (
  format: FormatMessageApi<string>,
  errorMessages?: FormDictionary['errorMessages'],
) => ({
  malfunctions: v.compose(
    v.apply<MalfunctionCode[]>(v.minLength(0), {
      type: 'malfunctionRequired',
      message: format(errorMessages?.['malfunctionRequired'], {
        debugId: 'forms.activity.errors.malfunctionRequired',
      }),
    }),
  ),
})
