import { lazy, Suspense } from 'react'

import { StepComponentContainer } from '../../../types/flow'
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'
import { hasActivityFormValues } from '../ActivityStep/ActivityStep'
import { hasDriverFormValues } from '../DriverStep/DriverStep'
import { hasGarageStepValues } from '../GarageStep/GarageStep'
import { hasVehicleFormValues } from '../VehicleStep/VehicleStep'
import { SummaryStepViewProps } from './components/SummaryStepView/SummaryStepView'
import { SummaryStepPrerequisiteData } from './types'

const SummaryStepLazy = lazy(() => import('./SummaryStep'))

export const SummaryStepContainer: StepComponentContainer<
  SummaryStepViewProps,
  SummaryStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <SummaryStepLazy {...props} />
    </Suspense>
  )
}

SummaryStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is SummaryStepPrerequisiteData =>
  hasVehicleFormValues(mergedFlowData) &&
  hasActivityFormValues(mergedFlowData) &&
  hasGarageStepValues(mergedFlowData) &&
  hasDriverFormValues(mergedFlowData)
