import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { CarSedanSmallPictogram } from '@velocity/icons/pictogram'
import { useBreakpoint } from '@velocity/styling/breakpoint/useBreakpoint/useBreakpoint'
import { FC, useEffect, useState } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$root': {
      width: '80%',
      height: theme.spacing(17),
      marginTop: theme.spacing(2),
      padding: theme.spacing(3, 4, 3, 4),
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(2),
        height: theme.spacing(24),
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
  },
  icon: {
    '&$icon': {
      margin: theme.spacing(0),
      width: theme.spacing(11),
      height: theme.spacing(11),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 0, 2),
        width: theme.spacing(9),
        height: theme.spacing(9),
      },
    },
  },
  tip: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0, 3, 0),
    },
  },
  tipHeading: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0, 0, 2),
      fontSize: theme.typography.body1.fontSize,
    },
  },
  tipBody: {
    margin: theme.spacing(0.5, 0, 0, 0),
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 0, 0, 2),
    },
  },
}))

const TIPS_CYCLE_TIME_MS = 4000

export const LoadingTips: FC = () => {
  const classes = useStyles()
  const f = useMessageFormat()
  const translations = useTranslation()

  const tSharedStep = translations.steps?.shared
  const tLoadingScreenDidYouKnow =
    tSharedStep?.otherData['loadingTipsDidYouKnow'] || ''
  const tTips = Object.values(
    tSharedStep?.otherData['loadingTips'] || {},
  ) as string[]

  const [activeTipIndex, setActiveTipIndex] = useState(0)
  const isMobile = useBreakpoint()?.toString() === 'XS'

  useEffect(() => {
    return () => clearTimeout(timer)
  })

  if (tTips.length === 0) {
    return null
  }

  const moveTipIndex = () => {
    setActiveTipIndex(
      tTips.length - 1 === activeTipIndex ? 0 : activeTipIndex + 1,
    )
  }

  const timer = setTimeout(moveTipIndex, TIPS_CYCLE_TIME_MS)

  const renderDesktop = () => (
    <div className={classes.root}>
      <CarSedanSmallPictogram className={classes.icon} />
      <div className={classes.tip}>
        <Typography variant="h6" className={classes.tipHeading}>
          {f(tLoadingScreenDidYouKnow)}
        </Typography>
        <Typography variant="body1" className={classes.tipBody}>
          {tTips[activeTipIndex]}
        </Typography>
      </div>
    </div>
  )

  const renderMobile = () => (
    <div className={classes.root}>
      <Box display="flex">
        <CarSedanSmallPictogram className={classes.icon} />
        <Typography variant="h6" className={classes.tipHeading}>
          {f(tLoadingScreenDidYouKnow, {
            debugId: 'steps.global.otherData.loadingTipsDidYouKnow',
          })}
        </Typography>
      </Box>
      <Box justifyContent="center">
        <div className={classes.tip}>
          <Typography variant="body1" className={classes.tipBody}>
            {tTips[activeTipIndex]}
          </Typography>
        </div>
      </Box>
    </div>
  )

  return isMobile ? renderMobile() : renderDesktop()
}
