import { useMemo } from 'react'

import { useUxConfig } from '../../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../../hooks/useMessageFormat'
import { CountryConfiguration } from '../../../types/api'
import { FlattenedErrorMessages, FormDictionary } from '../../../types/i18n'
import { FormatMessageApi } from '../../../types/validation'
import * as v from '../../../utils/validation'

const invalidPhoneDebugId = 'forms.driver.errors.phoneInvalid'
const invalidNameDebugId = 'forms.driver.errors.nameInvalid'
const NAME_MIN_LENGTH = 2
const NAME_MAX_LENGTH = 40

const getValidators = (
  phoneMinLength: CountryConfiguration['phoneMinLength'],
  phoneMaxLength: CountryConfiguration['phoneMaxLength'],
  nameMinLength: number,
  nameMaxLength: number,
  format: FormatMessageApi<string>,
  errorMessages?: FormDictionary['errorMessages'],
) => ({
  firstName: v.compose(
    v.apply<string>(v.required, {
      type: 'firstNameRequired',
      message: format(errorMessages?.['firstNameRequired'], {
        debugId: 'forms.driver.errors.firstNameRequired',
      }),
    }),
    v.apply<string>(v.isLettersOnly, {
      type: 'firstNameMustBeLettersOnly',
      message: format(errorMessages?.['firstNameMustBeLettersOnly'], {
        debugId: invalidNameDebugId,
      }),
    }),
    v.apply<string>(v.minMaxLength(nameMinLength, nameMaxLength), {
      type: 'firstNameLengthBetween',
      message: format(errorMessages?.['firstNameLengthBetween'], {
        debugId: invalidNameDebugId,
        values: {
          minLength: nameMinLength.toString(),
          maxLength: nameMaxLength.toString(),
        },
      }),
    }),
  ),
  lastName: v.compose(
    v.apply<string>(v.required, {
      type: 'lastNameRequired',
      message: format(errorMessages?.['lastNameRequired'], {
        debugId: 'forms.driver.errors.lastNameRequired',
      }),
    }),
    v.apply<string>(v.isLettersOnly, {
      type: 'lastNameMustBeLettersOnly',
      message: format(errorMessages?.['lastNameMustBeLettersOnly'], {
        debugId: invalidNameDebugId,
      }),
    }),
    v.apply<string>(v.minMaxLength(nameMinLength, nameMaxLength), {
      type: 'lastNameLengthBetween',
      message: format(errorMessages?.['lastNameLengthBetween'], {
        debugId: invalidNameDebugId,
        values: {
          minLength: nameMinLength.toString(),
          maxLength: nameMaxLength.toString(),
        },
      }),
    }),
  ),
  email: v.compose(
    v.apply<string>(v.required, {
      type: 'emailRequired',
      message: format(errorMessages?.['emailRequired'], {
        debugId: 'forms.driver.errors.emailRequired',
      }),
    }),
    v.apply<string>(v.isEmail, {
      type: 'invalidEmail',
      message: format(errorMessages?.['emailInvalid'], {
        debugId: 'forms.driver.errors.emailInvalid',
      }),
    }),
  ),
  phone: v.compose(
    v.apply<string>(v.required, {
      type: 'phoneRequired',
      message: format(errorMessages?.['phoneRequired'], {
        debugId: 'forms.driver.errors.phoneRequired',
      }),
    }),
    v.apply<string>(v.isUnsigned, {
      type: 'phoneMustBeUnsigned',
      message: format(errorMessages?.['phoneMustBeUnsigned'], {
        debugId: invalidPhoneDebugId,
      }),
    }),
    v.apply<string>(v.isNumber, {
      type: 'phoneMustBeNumber',
      message: format(errorMessages?.['phoneMustBeNumber'], {
        debugId: invalidPhoneDebugId,
      }),
    }),
    v.apply<string>(v.minMaxLength(phoneMinLength, phoneMaxLength), {
      type: 'phoneLengthBetween',
      message: format(errorMessages?.['phoneLengthBetween'], {
        debugId: invalidPhoneDebugId,
        values: {
          minLength: phoneMinLength.toString(),
          maxLength: phoneMaxLength.toString(),
        },
      }),
    }),
    v.apply<string>(v.isPhoneNumber, {
      type: 'phoneInvalid',
      message: format(errorMessages?.['phoneInvalid'], {
        debugId: invalidPhoneDebugId,
      }),
    }),
  ),
})

export const useDriverFormValidators = (
  formErrors: FlattenedErrorMessages,
  f: ReturnType<typeof useMessageFormat>,
) => {
  const { countryConfig } = useUxConfig()

  const validators = useMemo(
    () =>
      getValidators(
        countryConfig.phoneMinLength,
        countryConfig.phoneMaxLength,
        NAME_MIN_LENGTH,
        NAME_MAX_LENGTH,
        f,
        formErrors,
      ),
    [countryConfig.phoneMinLength, countryConfig.phoneMaxLength, f, formErrors],
  )

  return validators
}
