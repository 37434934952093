import {
  ChevronUpIcon,
  ChevronDownIcon,
  CloseIcon,
} from '@velocity/icons/system'
import { useBreakpoint } from '@velocity/styling/breakpoint/useBreakpoint/useBreakpoint'
import { FlexBox } from '@velocity/ui/draft'
import clsx from 'clsx'
import {
  FocusEvent,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useStyles } from './MultiDropDownSelect.styled'
import { MultiDropDownSelectedLabel } from './MultiDropDownSelectedLabel'
import { MultiDropDownSelectItem, ItemProps } from './MultiDropDownSelectItem'

export interface MultiDropDownSelectProps<T>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onBlur'> {
  /**
   * Sets a list of selectable items/options to be displayed in the dropdown
   * containing a label and value prop
   */
  items: ItemProps<T>[]
  /**
   * Optional list of selected item values. When using complex data as value, it
   * has to maintain it's reference to the item value passed in items
   */
  value?: ItemProps<T>['value'][]
  /**
   * Sets a placeholder to be displayed when nothing is selected
   */
  placeholder?: string
  /**
   * Sets a message to be displayed when no items are provided
   */
  noItemsMessage?: string
  /**
   * Optional function called when (de)selecting items
   * @param items
   * @param item
   */
  onChange?: (
    items: ItemProps<T>['value'][],
    item?: ItemProps<T>['value'],
  ) => void
  /**
   * Optional function called when closing the dropdown
   */
  onClose?: () => void
  /**
   * Optional function called when opening the dropdown
   */
  onOpen?: () => void
  /**
   * Optional function called when onBlur is called on the root element
   * @param event
   */
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void
  /**
   * Sets the maximum number of selectable items
   */
  max?: number
  /**
   * Sets whether to render the dropdown open or closed
   */
  isOpen?: boolean
  /**
   * Sets whether to apply error styling
   */
  error?: boolean
}

export const MultiDropDownSelect: <T>(
  props: MultiDropDownSelectProps<T>,
) => ReactElement = ({
  items,
  onChange,
  onClose,
  onOpen,
  onBlur,
  placeholder = '',
  noItemsMessage = 'Nothing to select',
  max,
  isOpen: providedIsOpen = false,
  value: providedSelectedItems,
  error,
  className,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(providedIsOpen)
  const [selectedItems, setSelectedItems] = useState(
    providedSelectedItems || [],
  )
  const classes = useStyles()
  const rootEl = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (providedSelectedItems) {
      setSelectedItems(
        items.reduce<typeof providedSelectedItems>((acc, curr) => {
          if (providedSelectedItems.includes(curr.value)) {
            acc.push(curr.value)
          }
          return acc
        }, []),
      )
    }
  }, [providedSelectedItems, items])

  useEffect(() => {
    if (!isOpen) {
      return onClose?.()
    }
    onOpen?.()
  }, [isOpen, onClose, onOpen])

  useEffect(() => {
    if (providedIsOpen) {
      rootEl.current?.focus()
    }
    setIsOpen(providedIsOpen)
  }, [providedIsOpen])

  const clearSelection = useCallback(
    (event) => {
      event.stopPropagation()
      setSelectedItems([])
      onChange?.([])
    },
    [onChange],
  )

  const handleSelectItem = useCallback(
    (item) => {
      let items

      if (selectedItems.includes(item)) {
        items = selectedItems.filter((i) => i !== item)
        setSelectedItems(items)
      } else {
        if (selectedItems.length === max) {
          return
        }
        items = [...selectedItems, item]
        setSelectedItems(items)
      }
      onChange?.(items, item)
    },
    [selectedItems, max, onChange],
  )

  const handleBlur = useCallback(
    (event) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        setIsOpen(false)
        onBlur?.(event)
      }
    },
    [onBlur],
  )

  const handleEnterPress = useCallback(
    ({ key }) => key === 'Enter' && setIsOpen(false),
    [],
  )

  const renderDropDownItem = useCallback(
    (item) => (
      <MultiDropDownSelectItem
        key={`item-${item.label}-${item.value}`}
        checked={selectedItems.includes(item.value)}
        onChange={handleSelectItem}
        disabled={
          (selectedItems.length === max &&
            !selectedItems.includes(item.value)) ||
          item.enabled === false
        }
        {...item}
      />
    ),
    [handleSelectItem, max, selectedItems],
  )

  const renderDesktopDropDown = useCallback(
    (items) => {
      const leftCount = Math.ceil(items.length / 2)

      return Array.from({ length: leftCount }).map((_, i) => (
        <>
          {items[i] && renderDropDownItem(items[i])}
          {items[i + leftCount] && renderDropDownItem(items[i + leftCount])}
        </>
      ))
    },
    [renderDropDownItem],
  )

  const isDesktop = useBreakpoint()?.toString() !== 'XS' // NOSONAR

  return (
    <div
      ref={rootEl}
      onBlur={handleBlur}
      tabIndex={0}
      className={clsx(classes.wrapper, className)}
      {...rest}
      onFocus={(e) => {
        inputRef.current?.focus()
        rest.onFocus?.(e)
      }}
    >
      <div
        className={clsx(
          classes.select,
          selectedItems.length ? classes.compact : '',
          error ? classes.error : '',
        )}
        onClick={() => setIsOpen(!isOpen)}
        data-e2e-component="multi-drop-down-select"
      >
        <FlexBox flexGrow={1} flexWrap="wrap">
          {selectedItems.length
            ? selectedItems.map((selectedItem) => {
                const item = items.find((i) => i.value === selectedItem)
                return (
                  <MultiDropDownSelectedLabel
                    key={`label-${item?.label}`}
                    label={`${item?.label}`}
                    onRemove={() => handleSelectItem(item?.value)}
                  />
                )
              })
            : placeholder}
        </FlexBox>
        <div className={classes.iconWrapper}>
          {!!selectedItems.length && (
            <CloseIcon
              data-e2e-component="multi-drop-down-remove-all"
              className={classes.closeIcon}
              size="xs"
              onClick={clearSelection}
            />
          )}

          {isOpen ? (
            <ChevronUpIcon size="s" className={classes.chevronIcon} />
          ) : (
            <ChevronDownIcon size="s" className={classes.chevronIcon} />
          )}
        </div>
      </div>

      {isOpen && (
        <FlexBox
          flexWrap="wrap"
          className={classes.dropDown}
          data-e2e-component="multi-drop-down-items"
        >
          {isDesktop
            ? renderDesktopDropDown(items)
            : items.map((item) => renderDropDownItem(item))}
          {!items.length && noItemsMessage}
        </FlexBox>
      )}

      <input
        type="text"
        ref={inputRef}
        className={classes.input}
        data-e2e-component="multi-drop-down-hidden-input"
        placeholder="hidden"
        onKeyPress={handleEnterPress}
      />
    </div>
  )
}
