import { LinkInline } from '@velocity/ui'
import { useMemo } from 'react'

import { useTranslation } from '../../../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../../../hooks/useMessageFormat'
import { ErrorCode } from '../../../../types/errors'
import { getTranslatedTitleAndMessageForError } from '../../../../utils/error'
import { MessageBox } from '../../../MessageBox/MessageBox'

interface EligibilityErrorProps {
  errorCode?: ErrorCode
}

export const EligibilityError: React.FC<EligibilityErrorProps> = ({
  errorCode,
}) => {
  const translations = useTranslation()
  const f = useMessageFormat()
  const sharedLabels = translations.forms?.shared?.labels
  const {
    countryConfig: { contactPhone },
  } = useUxConfig()

  const ContactUsLink = useMemo(
    () => (
      <span>
        <LinkInline
          href={f(sharedLabels?.['contactUsLink'], {
            debugId: `forms.shared.labels.${sharedLabels?.['contactUsLink']}`,
          })}
          emphasis="low"
        >
          {f(sharedLabels?.['contactUs'], {
            debugId: `forms.shared.labels.${sharedLabels?.['contactUs']}`,
          })}
        </LinkInline>
      </span>
    ),
    [f, sharedLabels],
  )

  const PhoneLink = useMemo(
    () => (
      <span>
        <LinkInline href={`tel:${contactPhone}`} emphasis="low">
          {contactPhone}
        </LinkInline>
      </span>
    ),
    [contactPhone],
  )

  const { title, message } = getTranslatedTitleAndMessageForError(
    errorCode || ErrorCode.VehicleNotEligible,
    translations,
    f,
    {
      contactUs: ContactUsLink,
      phoneUs: PhoneLink,
    },
  )

  return <MessageBox type="error" title={title} message={message} />
}
