import { Box } from '@material-ui/core'
import { useBreakpoint } from '@velocity/styling/breakpoint/useBreakpoint/useBreakpoint'
import { FlexBox } from '@velocity/ui/draft'
import * as R from 'ramda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isPlace, isSupplier } from '../../../domain/shapes'
import { useSuppliers } from '../../../hooks/api/useSuppliers'
import { useTracking } from '../../../hooks/useTracking'
import { VehicleServiceCode } from '../../../types/api'
import { StepComponent } from '../../../types/flow'
import { Supplier } from '../../../types/models'
import { dateToIsoString, isIsoString } from '../../../utils/date'
import { getErrorFromPayload } from '../../../utils/error'
import { ErrorBoxHttpCodes } from '../../ErrorBoxHttpCodes/ErrorBoxHttpCodes'
import { GarageFormValues } from '../../forms/GarageForm/GarageForm'
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'
import { useGarageStepStyles } from './GarageStep.styled'
import {
  GarageStepValues,
  GarageStepPrerequisiteData,
} from './GarageStep.types'
import { GarageView } from './GarageStepView'

const GarageStep: StepComponent<GarageStepValues, GarageStepPrerequisiteData> =
  (props) => {
    const { onUpdateStep, onNextStep, stepData, onPreviousStep, mergedData } =
      props
    const { supplier, suppliers, supplierQuery } = stepData
    const { trackEvent } = useTracking()
    const [showMap, setShowMap] = useState(false)
    const [showGarageCount, setShowGarageCount] = useState(!!suppliers?.length)
    const classes = useGarageStepStyles({ showMap })
    const isMobile = useBreakpoint()?.toString() === 'XS'
    const {
      licensePlate,
      mileage,
      selectedMalfunctions,
      selectedRegularServices,
      selectedExtraServices,
    } = props.mergedData
    const [{ suppliers: newSuppliers, error, loading }, fetchSuppliers] =
      useSuppliers()

    useEffect(() => {
      newSuppliers && onUpdateStep({ suppliers: newSuppliers })
    }, [newSuppliers, onUpdateStep])

    const withPickupAndReturn = mergedData.selectedExtraServices.includes(
      VehicleServiceCode.CollectReturn,
    )

    useEffect(() => {
      if (!showGarageCount && newSuppliers) {
        setShowGarageCount(true)
      }
      if ((loading || error) && showGarageCount) {
        setShowGarageCount(false)
      }
    }, [loading, error, newSuppliers, showGarageCount])

    useEffect(() => {
      if (newSuppliers) {
        trackEvent({
          event: 'genericEvent',
          category: 'search',
          action: 'results',
          label: newSuppliers?.length ? 'has-results' : 'no-result',
          resultsCount: newSuppliers?.length || 0,
          nonInteraction: true,
        })
      } else if (error) {
        trackEvent({
          event: 'genericEvent',
          category: 'search',
          action: 'results',
          label: 'error',
          resultsCount: 0,
          nonInteraction: true,
        })
      }
    }, [newSuppliers, trackEvent, error])

    const initialValuesRef = useRef<
      GarageStepValues['supplierQuery'] | undefined
    >(hasGarageFormValues(supplierQuery) ? supplierQuery : undefined)

    // Sanitize all non GarageForm stored data owned by this step
    useEffect(() => {
      !hasGarageStepValues(stepData) &&
        onUpdateStep({
          supplier: undefined,
          suppliers: [],
          supplierQuery: {
            ...initialValuesRef.current,
          },
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = useCallback(
      (formData: GarageFormValues) => {
        const { date, place, dropOffTime } = formData
        const formattedDate = dateToIsoString(date, true)
        // persist all currently available data
        onUpdateStep({
          supplier: undefined,
          suppliers: [],
          supplierQuery: { place, date: formattedDate, dropOffTime },
        })

        // then fetch suppliers
        fetchSuppliers({
          place,
          licensePlate,
          mileage,
          malfunctionCodes: selectedMalfunctions,
          serviceCodes: [...selectedRegularServices, ...selectedExtraServices],
          // BE always expects a short ISO date (`YYYY-MM-DD`)
          // Conveniently enough, we store it in the flow data in this very format
          bookingDate: formattedDate,
        })
      },
      [
        fetchSuppliers,
        licensePlate,
        mileage,
        onUpdateStep,
        selectedMalfunctions,
        selectedRegularServices,
        selectedExtraServices,
      ],
    )

    const handleSupplierSelect = useCallback(
      (supplier: Supplier) => {
        onUpdateStep({
          ...stepData,
          supplier,
        })

        trackEvent({
          event: 'genericEvent',
          category: 'results',
          action: 'click',
          label: 'list-item',
          resultsCount: suppliers?.length || 0,
          supplierIdName: supplier.name,
          supplierDistance: supplier.distance,
        })
        onNextStep?.() // NOSONAR
      },
      [onNextStep, onUpdateStep, stepData, trackEvent, suppliers?.length],
    )

    const errorMemo = useMemo(
      () => error && <ErrorBoxHttpCodes error={getErrorFromPayload(error)} />,
      [error],
    )

    return (
      <Box className={classes.root}>
        <GarageView
          initialValues={initialValuesRef.current}
          searchedPlace={supplierQuery?.place}
          supplier={supplier}
          suppliers={suppliers}
          showGarageCount={showGarageCount}
          desktopView={!isMobile}
          onSupplierSelect={handleSupplierSelect}
          onSubmit={handleSubmit}
          showMap={showMap}
          setMapVisibility={setShowMap}
          onGoBack={onPreviousStep}
          error={errorMemo}
          withPickupAndReturn={withPickupAndReturn}
        />
        {loading && (
          <FlexBox height="100%" position="absolute">
            <FullScreenLoader variant="fixed" />
          </FlexBox>
        )}
      </Box>
    )
  }

const hasGarageFormValues = (
  x: unknown,
): x is GarageStepValues['supplierQuery'] =>
  R.allPass([
    R.pipe(R.prop('place'), isPlace),
    R.pipe(R.prop('date'), isIsoString),
    R.pipe(R.prop('dropOffTime'), R.is(String)),
  ])(x)

export const hasGarageStepValues = (x: unknown): x is GarageStepValues =>
  R.allPass([
    R.pipe(R.prop('supplierQuery'), hasGarageFormValues),
    R.pipe(R.prop('supplier'), isPlace), // google result
    R.pipe(R.prop('suppliers'), R.all(isSupplier)),
  ])(x)

// lazy currently supports only default exports (https://reactjs.org/docs/code-splitting.html#named-exports)
export default GarageStep
