import { MalfunctionCode, VehicleServiceCode, SupplierData } from './api'

/**
 * Define the flow steps
 * !!! the order of the enum keys must be preserved
 * values are used also for accessing sitecore keys, see StepNames and FormNames in i18n.ts file
 */
export enum FlowStepName {
  /**
   * Step 1
   */
  Vehicle = 'vehicle',
  /**
   * Step 2
   */
  Activity = 'activity',
  /**
   * Step 3
   */
  Garage = 'garage',
  /**
   * Step 4
   */
  Driver = 'driver',
  /**
   * Step 5
   */
  Summary = 'summary',
  /**
   * Step 6
   */
  Done = 'done',
}

export interface SupplierLocation
  extends Pick<Supplier, 'city' | 'postalCode'> {
  address: string
}

// TODO: alias to postpone 60 file changes
export type Supplier = SupplierData
export type Suppliers = readonly Supplier[]

export interface ErrorPayload<TCode = string> {
  code?: TCode
  message?: string
}

export interface GeolocationCoords {
  lat: number
  lng: number
}

export interface Service<T extends VehicleServiceCode | MalfunctionCode> {
  label: string
  value: T
  description?: string
  enabled?: boolean
}

export type MalfunctionDictionary = Record<string, Service<MalfunctionCode>>
export type ServiceDictionary = Record<string, Service<VehicleServiceCode>>
