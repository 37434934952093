export const formatFloat = (value: number, decimalPlaces = 1) => {
  const decimalFactor = Math.pow(10, decimalPlaces)

  return (Math.round(value * decimalFactor) / decimalFactor).toFixed(
    decimalPlaces,
  )
}

// removes leading zeros when the number is non-zero
export const sanitizeNumber = (value: number | string) => {
  const trimmedValue = `${value}`.replace(/^0+(?=\d)/, '')

  return trimmedValue.replace(',', '.')
}
