import { alpha, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Text } from '@velocity/ui'
import { FC, Fragment } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'

import { LoadingTips } from '../LoadingTips/LoadingTips'

interface FullScreenLoaderProps {
  /**
   * Optionally set the full screen loader to either be contained within its parent component, fixed
   * and cover the entire page or display a series of tips.
   */
  variant?: 'contained' | 'fixed' | 'tips'
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ variant }: Pick<FullScreenLoaderProps, 'variant'>) =>
    variant === 'fixed' || variant === 'tips'
      ? {
          position: 'fixed',
          height: '100vh',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10000,
          backgroundColor: alpha(theme.palette.common.black, 0.4),
        }
      : {
          minHeight: variant === 'contained' ? '100%' : '100vh',
        },
  circularProgress: ({ variant }: Pick<FullScreenLoaderProps, 'variant'>) => ({
    marginTop: variant === 'tips' ? theme.spacing(-20) : 0,
  }),
  noticeContainer: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  notice: {
    color: theme.palette.common.white,
    margin: theme.spacing(2, 0, 2, 0),
    textAlign: 'center',
  },
}))

export const FullScreenLoader: FC<FullScreenLoaderProps> = ({ variant }) => {
  const classes = useStyles({ variant })
  const f = useMessageFormat()
  const translations = useTranslation()

  const tSharedStep = translations.steps?.shared
  const tLoadingScreenHeader = tSharedStep?.otherData['loadingTipsHeader'] || ''
  const tLoadingScreenDescription =
    tSharedStep?.otherData['loadingTipsDescription'] || ''

  return (
    <Grid
      container={true}
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      data-e2e-component="fullscreen-loader"
      className={classes.root}
    >
      <CircularProgress
        aria-label="loader"
        className={classes.circularProgress}
      />
      {variant === 'tips' && (
        <Fragment>
          <div className={classes.noticeContainer}>
            <Text variant="300" className={classes.notice}>
              {f(tLoadingScreenHeader, {
                debugId: 'steps.global.otherData.loadingTipsHeader',
              })}
            </Text>
            <Text variant="300" className={classes.notice}>
              {f(tLoadingScreenDescription, {
                debugId: 'steps.global.otherData.loadingTipsDescription',
              })}
            </Text>
          </div>
          <LoadingTips />
        </Fragment>
      )}
    </Grid>
  )
}
