import { useLogger } from '@cj4/react-logger'
import { Place } from '@velocity/ui'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { useAppConfig } from '../../context/AppConfigContext/AppConfigContext'
import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'
import { SearchSuppliersData, SearchSuppliersRequest } from '../../types/api'
import { ErrorPayload } from '../../types/models'
import { useAxios } from '../useAxios'

export interface RequestData
  extends Omit<
    SearchSuppliersRequest,
    'latitude' | 'longitude' | 'countryCode'
  > {
  place: Place
}

export interface RequestState {
  suppliers?: SearchSuppliersData
  error?: AxiosError<ErrorPayload>
  loading: boolean
}

// A wrapper of the /suppliers request
export const useSuppliers = (): [RequestState, (data: RequestData) => void] => {
  const logger = useLogger()
  const [request, setRequestState] = useState<RequestState>({
    loading: false,
  })

  const {
    countryConfig: { countryCode },
  } = useUxConfig()

  const {
    endpoints: { apiBase: baseURL, suppliers: suppliersEndpoint },
  } = useAppConfig()

  const [{ data, error, loading }, deferredCall] =
    useAxios<SearchSuppliersData>(
      { method: 'POST', baseURL, url: suppliersEndpoint },
      true,
    )

  useEffect(() => {
    setRequestState({ suppliers: data, error, loading })
  }, [data, error, loading])

  const fetchSuppliers = useCallback(
    (data: RequestData) => {
      const { place, malfunctionCodes, serviceCodes, ...restRequestData } = data

      const requestData: SearchSuppliersRequest = {
        ...restRequestData,
        countryCode,
        latitude: place.latitude,
        longitude: place.longitude,
        malfunctionCodes,
        serviceCodes,
      }

      deferredCall({ data: requestData }).catch(logger.error)
    },
    [deferredCall, countryCode, logger],
  )

  return [request, fetchSuppliers]
}
