import { DatePicker as VelocityDatePicker, DatePickerProps } from '@velocity/ui'
import clsx from 'clsx'
import React from 'react'

import { useStyles } from './DatePicker.styled'

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <VelocityDatePicker className={clsx(classes.root, className)} {...rest} />
  )
}
