import * as R from 'ramda'
import { useRef, useCallback, useMemo } from 'react'

import { useTranslation } from '../../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../../context/UxConfigContext/UxConfigContext'
import { isMalfunction, isVehicleService } from '../../../domain/shapes'
import { StepComponent } from '../../../types/flow'
import { filterByTranslation } from '../../../utils/filterByTranslation'
import {
  ActivityForm,
  ActivityFormValues,
} from '../../forms/ActivityForm/ActivityForm'
import { VehicleStepValues } from '../VehicleStep/VehicleStep'
import { ActivityStepValues } from './types'

const ActivityStep: StepComponent<ActivityStepValues, VehicleStepValues> = ({
  onPreviousStep,
  onNextStep,
  onUpdateStep,
  stepData,
  mergedData,
}) => {
  const {
    countryConfig: { malfunctions: countryMalfunctions },
  } = useUxConfig()

  const translations = useTranslation()

  const handleSubmit = useCallback(
    (formData: ActivityFormValues) => {
      onUpdateStep(formData)

      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  const malfunctions = useMemo(
    () =>
      filterByTranslation(
        translations.malfunctions,
        countryMalfunctions,
        'code',
        'isAvailable',
      ),
    [countryMalfunctions, translations.malfunctions],
  )

  const regularServices = useMemo(
    () =>
      filterByTranslation(
        translations.services,
        mergedData.services.filter((service) => !service.isAdditional),
        'code',
        'isAvailable',
      ),
    [mergedData.services, translations.services],
  )

  const extraServices = useMemo(
    () =>
      filterByTranslation(
        translations.services,
        mergedData.services.filter((service) => service.isAdditional),
        'code',
        'isAvailable',
      ),
    [mergedData.services, translations.services],
  )

  const initialValuesRef = useRef<ActivityStepValues | undefined>(
    hasActivityFormValues(stepData)
      ? {
          selectedRegularServices: stepData.selectedRegularServices,
          /**
           * <MultiDropDownSelect /> requires us to maintain referential
           * integrity among its passed items and selected values
           * TODO: figure out if this can be simplified by not having to
           * maintain referential integrity
           */
          selectedMalfunctions: stepData.selectedMalfunctions,
          selectedExtraServices: stepData.selectedExtraServices,
          additionalRequests: stepData.additionalRequests,
        }
      : undefined,
  )

  return (
    <ActivityForm
      regularServices={regularServices}
      extraServices={extraServices}
      malfunctions={malfunctions}
      initialValues={initialValuesRef.current}
      onSubmit={handleSubmit}
      onGoBack={onPreviousStep}
    />
  )
}

export const hasActivityFormValues = (x: unknown): x is ActivityFormValues =>
  R.allPass([
    R.pipe(R.prop('selectedRegularServices'), Array.isArray),
    R.pipe(R.prop('selectedRegularServices'), R.all(isVehicleService)),
    R.pipe(R.prop('selectedMalfunctions'), Array.isArray),
    R.pipe(R.prop('selectedMalfunctions'), R.all(isMalfunction)),
    R.pipe(R.prop('selectedExtraServices'), Array.isArray),
    R.pipe(R.prop('selectedExtraServices'), R.all(isVehicleService)),
    R.pipe(R.prop('additionalRequests'), R.is(String)),
  ])(x)

// lazy currently supports only default exports (https://reactjs.org/docs/code-splitting.html#named-exports)
export default ActivityStep
