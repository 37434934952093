import { makeStyles } from '@material-ui/core'

export const useCardStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: theme.spacing(15),
    boxSizing: 'border-box',
    isolation: 'isolate', // to create a new stacking context

    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  rootIE: {
    zIndex: 0, // because IE11 does not support `isolation`
  },
  instaBookBadge: {
    marginBottom: 4,
  },
}))
