import { ApolloClient, InMemoryCache } from '@apollo/client'
import { Logger } from '@cj4/logger'
import { createLogger as createConsoleLogger } from '@cj4/logger-console'
import { createLogger as createDatadogLogger } from '@cj4/logger-datadog'
import axios from 'axios'

import { defaultLocale } from '../../i18n/supportedLocales'

import {
  MaybeCountryConfiguration,
  CountryConfiguration,
} from '../../types/api'
import { readCountryConfigFromWindow } from '../../utils/countryConfig'
import { getCountryThemeFromISOCode } from '../../utils/getCountryThemeFromISOCode'
import {
  extractCountryFromLocale,
  extractLocaleFromUrl,
} from '../../utils/i18n'
import { memoizeForever } from '../../utils/memoizeForever'
import { AppContext, Config } from './types'

const HTTP_BAD_REQUEST = 400

export const createProductionContext = (
  config: Config,
  countryConfig: MaybeCountryConfiguration,
): AppContext => {
  const apolloClient = new ApolloClient({
    uri: config.graphQl.endpoint,
    cache: new InMemoryCache(),
  })

  const isoCountryCode = countryConfig?.countryCode.replace('LP', '')

  return {
    logger: initializeLogger(config),
    localStorageKeys: config.localStorageKeys,
    apolloClient,
    countryConfig,
    theme: getCountryThemeFromISOCode(
      isoCountryCode || '',
      config.rebrandedCountries,
    ),
  }
}

export const createContext = (
  config: Config,
): AppContext | Promise<AppContext> => {
  if (!config.countryConfigOverride) {
    return createProductionContext(config, readCountryConfigFromWindow())
  }

  return fetchCountryConfig(config).then((countryConfig) =>
    createProductionContext(config, countryConfig),
  )
}

export const initializeLogger = memoizeForever<Logger | null>(function (
  config: Config,
) {
  if (config.dataDog.env === 'local') {
    return createConsoleLogger()
  }

  // Never fail due to a problem with the logger.
  try {
    /**
     * 'source', and 'env' are Core facets, and can only be filtered on in the log viewer if they are set as tags,
     * rather than meta tags, so instead we use 'dd_source' and 'dd_env'. Also, source gets overridden by the Datadog log
     * collector with a tag source:browser.
     *
     * The log collector does not set a service tag (which is also Core facet), but strangely enough, we have no problems
     * when setting it here. It behaves in the log viewer just as if it were set as a tag.
     */
    return createDatadogLogger({
      clientToken: config.dataDog.clientToken,
      defaultContext: {
        dd_env: config.dataDog.env,
        service: config.dataDog.service,
        dd_source: config.dataDog.source,
        buildInfo: config.build,
      },
    })
  } catch (e) {
    // Log the failure, unless we're on the production site.
    if (config.dataDog.env !== 'prod') {
      // eslint-disable-next-line no-console
      console.error('DataDog Logger initialization failure:', e)
    }
    return null
  }
})

async function fetchCountryConfig(
  config: Config,
): Promise<MaybeCountryConfiguration> {
  const locale = extractLocaleFromUrl() || defaultLocale
  const country = extractCountryFromLocale(locale)
  const countryCode = `LP${country.toUpperCase()}`

  return await axios
    .get<CountryConfiguration>(
      `${config.endpoints.apiBase}${config.endpoints.countryConfig(
        countryCode,
      )}`,
      { timeout: 5000 },
    )
    .then(({ data }) => data)
    .catch((e) => (e.response?.status === HTTP_BAD_REQUEST ? null : undefined))
}
