import { Place } from '@velocity/ui'
import * as R from 'ramda'

import { MalfunctionCode, VehicleServiceCode } from '../types/api'
import { Supplier } from '../types/models'

export const isPlace = (x: unknown): x is Place =>
  R.allPass([
    R.pipe(R.prop('id'), R.anyPass([R.is(String), R.isNil])),
    R.pipe(R.prop('name'), R.is(String)),
    R.pipe(R.prop('streetName'), R.is(String)),
    R.pipe(R.prop('streetNumber'), R.anyPass([R.is(String), R.isNil])),
    R.pipe(R.prop('postalCode'), R.is(String)),
    R.pipe(R.prop('city'), R.is(String)),
    R.pipe(R.prop('country'), R.is(String)),
    R.pipe(R.prop('latitude'), R.is(Number)),
    R.pipe(R.prop('longitude'), R.is(Number)),
  ])(x)

export const isSupplier = (x: unknown): x is Supplier =>
  R.allPass([
    R.pipe(R.prop('id'), R.is(String)),
    R.pipe(R.prop('distance'), R.is(Number)),
    isPlace,
  ])(x)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isVehicleService = (x: any): x is VehicleServiceCode =>
  Object.values(VehicleServiceCode).includes(x)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMalfunction = (x: any): x is MalfunctionCode =>
  Object.values(MalfunctionCode).includes(x)
