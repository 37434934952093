import { NotificationBanner } from '@velocity/ui'

import { FC } from 'react'

import { MessageBoxType } from './types'

export interface MessageBoxProps {
  /**
   * Optional class name to override component's root element style class
   */
  className?: string
  /**
   * Optional message to be displayed with normal text
   */
  message?: string | JSX.Element
  /**
   * Optional title to be displayed with bold, bigger ann more weight text then the `message`
   */
  title?: string
  /**
   * Required, defines the type of the messsage box: info / success / error
   * Based on the `type` the message box is styled
   */
  type: MessageBoxType
}

export const MessageBox: FC<MessageBoxProps> = ({
  className,
  message,
  title,
  type,
}) => {
  return (
    <NotificationBanner
      kind={type}
      title={title}
      data-e2e-component={`message-box-${type}`}
      className={className}
    >
      {message}
    </NotificationBanner>
  )
}
