import isMergeableObject from 'is-mergeable-object'

type MergeableObject = Array<unknown> | Partial<unknown>

function isMergeable(a: unknown): a is {} | Array<unknown> {
  return isMergeableObject(a)
}

function clone(val: MergeableObject) {
  return isMergeable(val)
    ? deepmerge(
        Array.isArray(val) ? ([] as Array<unknown>) : ({} as Partial<unknown>),
        val,
      )
    : val
}

/**
 * A custom deepmerge implementation which:
 * - expects the objects to be of the same shape
 * - replaces arrays
 * - allows to visit the nodes (e.g. for logging purposes)
 */
export function deepmerge<T>(
  base: MergeableObject,
  source: MergeableObject,
  visitMissing?: (key: string, aVal: unknown, bVal: unknown) => void,
  baseKey = '',
) {
  if (base === source) {
    return base
  }
  if (Array.isArray(base)) {
    if (!source) {
      visitMissing?.(baseKey, base, source)
      return base
    } else {
      return source
    }
  }
  if (!isMergeable(base)) {
    if (typeof source === 'undefined' || source === null || source === '') {
      visitMissing?.(baseKey, base, source)
      return base
    } else {
      return source
    }
  }
  const destination: Partial<T> = {}
  // to be able to log missing keys, we put the source values first
  Object.keys(source || {}).forEach((key) => {
    destination[key] = clone(source[key])
  })
  Object.keys(base).forEach((key) => {
    if (source && key in source) {
      destination[key] = deepmerge(base[key], source[key], visitMissing, key)
    } else {
      visitMissing?.(`${baseKey}.${key}`, base[key], source?.[key]) // NOSONAR
      destination[key] = clone(base[key])
    }
  })
  return destination
}
