import { Place } from '@velocity/ui'

import { FormDictionary } from '../types/i18n'
import { FormatMessageApi } from '../types/validation'
import { dateToLocaleString } from './date'
import * as v from './validation'

export const getGarageValidators = (
  minDate: Date,
  maxDate: Date,
  format: FormatMessageApi<string>,
  errorMessages?: FormDictionary['errorMessages'],
) => ({
  place: v.apply<Place>(v.required, {
    type: 'addressRequired',
    message: format(errorMessages?.['addressRequired'], {
      debugId: 'forms.garage.errors.addressRequired',
    }),
  }),
  time: v.apply<string>(v.required, {
    type: 'timeRequired',
    message: format(errorMessages?.['timeRequired'], {
      debugId: 'forms.garage.errors.timeRequired',
    }),
  }),
  date: v.compose(
    v.apply<Date>(v.dateRequired, {
      type: 'dateRequired',
      message: format(errorMessages?.['dateRequired'], {
        debugId: 'forms.garage.errors.dateRequired',
      }),
    }),
    v.apply<Date>(v.isDateValid, {
      type: 'dateInvalid',
      message: format(errorMessages?.['dateInvalid'], {
        debugId: 'forms.garage.errors.dateInvalid',
      }),
    }),
    v.apply<Date>(v.isDateNotBefore(minDate), {
      type: 'dateNotBefore',
      message: format(errorMessages?.['dateNotBefore'], {
        debugId: 'forms.garage.errors.dateNotBefore',
        values: { minDate: dateToLocaleString(minDate) },
      }),
    }),
    v.apply<Date>(v.isDateBetweenIncluding(minDate, maxDate), {
      type: 'dateBetween',
      message: format(errorMessages?.['dateBetween'], {
        debugId: 'forms.garage.errors.dateBetween',
        values: {
          minDate: dateToLocaleString(minDate),
          maxDate: dateToLocaleString(maxDate),
        },
      }),
    }),
  ),
})
