import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  field: {
    [theme.breakpoints.up('md')]: {
      '&&': {
        margin: theme.spacing(0, 0, 5, 0),
        width: '80%',
      },
    },
  },
  activityError: {
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  },
  checkboxCardWrapper: {
    '&&': {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      paddingBottom: theme.spacing(2),
      '&:nth-child(odd)': {
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        '&&': {
          width: '100%',
          paddingRight: theme.spacing(0),
        },
      },
    },
  },
  checkboxCard: {
    '&&': {
      width: '100%',
      height: '100%',
    },
  },
  call: {
    textDecoration: 'none',
    color: 'inherit',
  },
  warningBanner: {
    marginBottom: theme.spacing(3),
  },
  tooltipBox: {
    marginBottom: theme.spacing(3),
    '& a': {
      display: 'inline',
      marginLeft: theme.spacing(1),
    },
  },
  infoText: {
    fontSize: '0.85rem',
    margin: 0,
    padding: 0,
  },
  contactButton: {
    '&&': {
      display: 'inline-block',
      marginLeft: theme.spacing(1),
    },
  },
}))
