import { useBreakpoint } from '@velocity/styling/breakpoint/useBreakpoint/useBreakpoint'
import { TopNavigation } from '@velocity/ui/draft'
import { FC } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { useMetaTags } from '../../hooks/useMetaTags'
import { LocaleSelector } from './LocaleSelector'

export interface HeaderProps {
  hideLocaleSelector?: boolean
}

export const Header: FC<HeaderProps> = ({ hideLocaleSelector }) => {
  const stepTranslations = useTranslation()?.steps?.shared
  const isMobile = useBreakpoint()?.toString() === 'XS'

  const f = useMessageFormat()

  const tLogoUrl = f(stepTranslations?.otherData['goToLeasePlanUrl'], {
    debugId: 'steps.global.otherData.goToLeasePlanUrl',
  })

  useMetaTags({
    title: f(stepTranslations?.otherData['appTitle'], {
      debugId: 'steps.shared.otherData.appTitle',
    }),
    description: f(stepTranslations?.otherData['appDescription'], {
      debugId: 'steps.shared.otherData.appDescription',
    }),
  })

  return (
    <TopNavigation layoutContainerSize="fluid">
      <TopNavigation.Logo
        data-e2e-component="app-header"
        clipBehaviour={isMobile ? 'clip' : 'none'}
        component="a"
        href={tLogoUrl}
        aria-label="logo"
      />
      <TopNavigation.Widgets aria-label="widgets">
        {!hideLocaleSelector && <LocaleSelector />}
      </TopNavigation.Widgets>
    </TopNavigation>
  )
}
