import { useLogger } from '@cj4/react-logger'
import moment from 'moment-timezone'
import { FC, ErrorInfo, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { AvailableCountriesView } from './components/AvailableCountriesView/AvailableCountriesView'
import { CountryNotSupported } from './components/CountryNotSupported/CountryNotSupported'
import { DisabledAppPage } from './components/DisabledAppPage/DisabledAppPage'
import { FullScreenLoader } from './components/FullScreenLoader/FullScreenLoader'
import { NotFoundPage } from './components/NotFoundPage/NotFoundPage'
import { PMRejectionContainer } from './components/PMRejection'
import { ResultsView } from './components/ResultsView/ResultsView'
import { DoneStepContainer } from './components/steps/DoneStep'
import { appConfig } from './config/env/appConfig'
import { useUxConfigUnsafe } from './context/UxConfigContext/UxConfigContext'
import { UxConfigContextErrorBoundary } from './context/UxConfigContext/UxConfigContextErrorBoundary'
import { defaultLocale } from './i18n/supportedLocales'
import { LeaseplanCode } from './types/i18n'
import {
  listAvailableLocales,
  isLpCountryEnabled,
} from './utils/enabledCountries'

function isWithinDisableRange() {
  const cetTime = moment().tz('Europe/Paris')
  const start = moment.tz(
    '2024-02-18 03:00',
    'YYYY-MM-DD HH:mm',
    'Europe/Paris',
  )
  const end = moment.tz('2024-02-18 07:00', 'YYYY-MM-DD HH:mm', 'Europe/Paris')

  return cetTime.isBetween(start, end, null, '[)')
}

export const Routes: FC = ({ children }) => {
  const localeParam = `:locale(${[
    ...listAvailableLocales(appConfig.enabledCountries),
    defaultLocale,
  ].join('|')})`

  const uxConfig = useUxConfigUnsafe()
  const logger = useLogger()
  const countryDefaultLocale =
    (isLpCountryEnabled(
      appConfig.enabledCountries,
      uxConfig?.countryConfig?.countryCode as LeaseplanCode,
    ) &&
      uxConfig?.countryConfig?.locale[0]) ||
    null

  const countryNotSupportedRoute = `/${defaultLocale}/country-not-supported`
  const availableCountriesRoute = `/${defaultLocale}/available-countries`

  const logUxConfigContextError = (error: Error, errorInfo: ErrorInfo) => {
    logger.error(error.message, errorInfo)
  }

  const appIsOutOfService = isWithinDisableRange()

  if (appIsOutOfService) {
    return (
      <Switch>
        <Route path="/out-of-service">
          <UxConfigContextErrorBoundary
            errorElement={<ResultsView viewType="appError" />}
            onError={logUxConfigContextError}
          >
            <DisabledAppPage />
          </UxConfigContextErrorBoundary>
        </Route>
        <Route path="*">
          <Redirect to="/out-of-service" />
        </Route>
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/:locale(\w\w-\w\w)?/find-garage(/.*)?">
        {({ match }: { match: { params: { locale: string } } }) => (
          <Redirect
            to={
              match?.params.locale
                ? `/${match?.params.locale}/vehicle`
                : '/vehicle'
            }
          />
        )}
      </Route>
      <Route
        path="/:url*(/+)"
        exact={true}
        strict={true}
        render={({ location }) => (
          <Redirect to={location.pathname.replace(/\/+$/, '')} />
        )}
      />

      <Route path={countryNotSupportedRoute}>
        <UxConfigContextErrorBoundary
          onError={logUxConfigContextError}
          errorElement={<ResultsView viewType="appError" />}
        >
          <CountryNotSupported redirectUrl={availableCountriesRoute} />
        </UxConfigContextErrorBoundary>
      </Route>
      <Route path={availableCountriesRoute}>
        <UxConfigContextErrorBoundary
          onError={logUxConfigContextError}
          errorElement={<ResultsView viewType="appError" />}
        >
          <AvailableCountriesView />
        </UxConfigContextErrorBoundary>
      </Route>

      {uxConfig?.countryConfig.enableNegativeFlow && (
        <Route path={`/${localeParam}/rejection*`}>
          <UxConfigContextErrorBoundary
            onError={logUxConfigContextError}
            errorElement={<Redirect to={countryNotSupportedRoute} />}
          >
            <Suspense fallback={<FullScreenLoader />}>
              <PMRejectionContainer />
            </Suspense>
          </UxConfigContextErrorBoundary>
        </Route>
      )}
      <Route path={`/${localeParam}/confirm*`}>
        <UxConfigContextErrorBoundary
          onError={logUxConfigContextError}
          errorElement={<Redirect to={countryNotSupportedRoute} />}
        >
          <DoneStepContainer />
        </UxConfigContextErrorBoundary>
      </Route>
      <Route path={`/${localeParam}`}>
        <UxConfigContextErrorBoundary
          onError={logUxConfigContextError}
          errorElement={<Redirect to={countryNotSupportedRoute} />}
        >
          {children}
        </UxConfigContextErrorBoundary>
      </Route>
      <Route
        path="/:locale(\w\w-\w\w)?/:url?"
        render={({ match: { params } }) => (
          <Redirect
            to={
              countryDefaultLocale
                ? `/${countryDefaultLocale}/${params.url || ''}`
                : countryNotSupportedRoute
            }
          />
        )}
      />
      <Route path="*">
        <UxConfigContextErrorBoundary
          onError={logUxConfigContextError}
          errorElement={<Redirect to={countryNotSupportedRoute} />}
        >
          <NotFoundPage />
        </UxConfigContextErrorBoundary>
      </Route>
    </Switch>
  )
}
