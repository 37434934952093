import { createContext, useContext } from 'react'

import { Config } from '../../config/env/types'

/**
 * A context with the application configuration (regardless the current user's experience).
 */
const AppConfigContext = createContext<Config | undefined>(undefined)

export const AppConfigServiceProvider = AppConfigContext.Provider

export const useAppConfig = (): Config => {
  const appConfig = useContext(AppConfigContext)
  if (!appConfig) {
    throw new Error(
      'No Context value found for `AppConfig`. Did you forget to wrap your application in the `AppConfigServiceProvider`?',
    )
  }

  return appConfig
}
