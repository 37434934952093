import { Theme, makeStyles } from '@material-ui/core'

export const useSupplierCardListStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0),
    overflowY: 'auto',
    msOverflowStyle: 'none',
  },
  highlightedCard: {
    '&&': {
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  cardListItem: {
    borderRadius: theme.spacing(0.5),
    border: `1px solid transparent`,
    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
}))
