import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  XIcon,
  YoutubeIcon,
} from '@velocity/icons/system'
import { Footer as LeasePlanFooter } from '@velocity/ui'
import { FC, useMemo } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { now } from '../../utils/date'
import { useFooterStyles } from './Footer.styled'

interface FooterProps {
  className?: string
}

export const Footer: FC<FooterProps> = ({ className }) => {
  const classes = useFooterStyles()
  const f = useMessageFormat()
  const translations = useTranslation()
  const links = translations.footer?.links ?? []
  const socialMediaLinks = useMemo(
    () => ({
      facebook: translations.footer?.labels?.['facebookUrl'],
      instagram: translations.footer?.labels?.['instagramUrl'],
      linkedin: translations.footer?.labels?.['linkedinUrl'],
      twitter: translations.footer?.labels?.['twitterUrl'],
      youtube: translations.footer?.labels?.['youtubeUrl'],
    }),
    [translations?.footer?.labels],
  )

  return (
    <div className={classes.footerContainer} data-e2e-component="app-footer">
      <LeasePlanFooter
        className={classes.footer}
        copyright={
          <p>
            &copy;&nbsp;{now().getFullYear()}&nbsp;
            {f(translations?.footer?.labels?.['leasePlan'], {
              debugId: `footer.leasePlan`,
            })}
          </p>
        }
        layoutContainerSize="fixed-wide"
        variant="brand"
      >
        <LeasePlanFooter.Legal>
          {links.map((linkObject, index) => {
            const link = f(linkObject?.link, {
              debugId: 'footer.link?', // generic identifier, hence the comma
            })
            const linkText = f(linkObject?.linkText, {
              debugId: 'footer.linkText?', // generic identifier, hence the comma
            })

            return (
              <LeasePlanFooter.Legal.Link
                href={link}
                key={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkText}
              </LeasePlanFooter.Legal.Link>
            )
          })}
        </LeasePlanFooter.Legal>
        <LeasePlanFooter.Social>
          {socialMediaLinks.facebook && (
            <LeasePlanFooter.Social.Link
              Icon={FacebookIcon}
              href={socialMediaLinks.facebook}
              aria-label="Facebook"
            />
          )}
          {socialMediaLinks.instagram && (
            <LeasePlanFooter.Social.Link
              Icon={InstagramIcon}
              href={socialMediaLinks.instagram}
              aria-label="Instagram"
            />
          )}
          {socialMediaLinks.linkedin && (
            <LeasePlanFooter.Social.Link
              Icon={LinkedinIcon}
              href={socialMediaLinks.linkedin}
              aria-label="LinkedIn"
            />
          )}
          {socialMediaLinks.twitter && (
            <LeasePlanFooter.Social.Link
              Icon={XIcon}
              href={socialMediaLinks.twitter}
              aria-label="Twitter"
            />
          )}
          {socialMediaLinks.youtube && (
            <LeasePlanFooter.Social.Link
              Icon={YoutubeIcon}
              href={socialMediaLinks.youtube}
              aria-label="Youtube"
            />
          )}
        </LeasePlanFooter.Social>
      </LeasePlanFooter>
    </div>
  )
}
