import { makeStyles, Theme } from '@material-ui/core'

import { copyFontStyles } from '../../utils/styles'

export const useResultsViewStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      minHeight: '100vh',
    },
  },
  content: {
    '&$content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      maxWidth: 600,
      [theme.breakpoints.up('md')]: {
        width: 600,
        backgroundColor: '#fff',
        marginTop: theme.spacing(5),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
      },
    },
  },
  icon: {
    '&$icon': {
      alignSelf: 'center',
      width: 136,
      height: 140,
      [theme.breakpoints.up('md')]: {
        width: 251,
        height: 251,
      },
    },
  },
  title: {
    '&$title': {
      ...copyFontStyles(theme.typography.h5),
      paddingTop: theme.spacing(2),
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        ...copyFontStyles(theme.typography.h2),
      },
    },
  },
  description: {
    '&$description': {
      ...copyFontStyles(theme.typography.body1),
      paddingTop: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
      },
    },
  },
  button: {
    '&$button': {
      textTransform: 'uppercase',
      marginTop: theme.spacing(5),
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6),
        minWidth: 280,
        maxWidth: 340,
        height: 50,
        borderRadius: 25,
        justifyContent: 'center',
        fontSize: theme.typography.subtitle2.fontSize,
      },
    },
  },
  needHelp: {
    '&$needHelp': {
      paddingTop: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(6),
      },
    },
  },
  footer: {
    '&$footer': {
      marginTop: 'auto',
    },
  },
}))
