import { Logger } from '@cj4/logger'
import { isSameDay } from 'date-fns'

import {
  parse,
  addBusinessDays as addWorkingDays,
  addDays as addAnyDays,
  subDays,
  format,
  endOfDay,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parseISO,
  startOfDay,
  toDate as cloneDateOrParseNum,
} from '../i18n/localizedDateFns'

export type DateInput = Date | number | string
/**
 * SDate is an ES Date wrapper which supports all existing Date inputs through
 * a stricter parsing policy for timely bug detection and improved DX.
 */
export const SDate = (
  input: DateInput = now(),
  format?: string, // for string inputs
  logger?: Logger,
): Date => {
  // Since this is used by the utils as well, let's aim for earliest return.
  if (input instanceof Date) {
    if (isValid(input)) {
      return cloneDateOrParseNum(input)
    }
    throw new Error(`Date parsing error. Input: ${JSON.stringify(input)}.`)
  }

  // 0/negative/decimal are ok. Very big values not but they are an edge case.
  if (typeof input === 'number') return cloneDateOrParseNum(input)

  let date = format ? parse(input, format, now()) : now('INVALID DATE')
  if (!isValid(date)) date = parseISO(input)

  if (isValid(date)) return date

  // If everything failed, throw and let consumers decide.
  const baseError = `Date parsing error. Input: ${input}.`
  const formatError = format ? ` Format: ${format}.` : ''
  logger?.error(`${baseError}${formatError}`)
  throw new Error('Invalid date')
}

const pad = (num: number) => `${Math.floor(Math.abs(num))}`.padStart(2, '0')
// Converts a date to an ISO string with offset by default
export const dateToIsoString = (
  date = new Date(),
  noTime = false,
  zeroOffset = false,
) => {
  if (zeroOffset) {
    return date.toISOString()
  }
  const d = date
  const tzo = -d.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'

  return (
    `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}` +
    ((!noTime &&
      `T${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}` +
        `${dif + pad(tzo / 60)}:${pad(tzo % 60)}`) ||
      '')
  )
}

export const isIsoString = (date: string) => isValid(parseISO(date))

/**
 * Adds number of business days.
 */
export const addBusinessDays = (
  days: number,
  dateInput?: DateInput,
  formatString?: string,
) => addWorkingDays(SDate(dateInput, formatString), days)

/**
 * Adds given days to the input date.
 */
export const addDays = (
  days = 0,
  dateInput?: DateInput,
  formatString?: string,
) => addAnyDays(SDate(dateInput, formatString), days)

/**
 * Subtract days from the input date.
 */
export const subtractDays = (
  days = 0,
  dateInput?: DateInput,
  formatString?: string,
) => subDays(SDate(dateInput, formatString), days)

/**
 * Gets day start of the given date.
 */
export const getStartOfDay = (dateInput?: DateInput, formatString?: string) =>
  startOfDay(SDate(dateInput, formatString))

/**
 * Gets day end of the given date.
 */
export const getEndOfDay = (dateInput?: DateInput, formatString?: string) =>
  endOfDay(SDate(dateInput, formatString))

export const isValidDate = (dateInput: DateInput, shouldThrow = true) => {
  if (shouldThrow) {
    SDate(dateInput)
    return true
  }

  try {
    SDate(dateInput)
    return true
  } catch {
    return false
  }
}

export const isBeforeDate = (
  d: DateInput,
  dToComp?: DateInput,
  strict = false,
) => {
  const date = SDate(d)
  const dateToCompare = SDate(dToComp)
  if (!strict && isSameDay(date, dateToCompare)) return true

  return isBefore(date, dateToCompare)
}

export const isAfterDate = (
  d: DateInput,
  dToComp?: DateInput,
  strict = false,
) => {
  const date = SDate(d)
  const dateToCompare = SDate(dToComp)

  if (!strict && isSameDay(date, dateToCompare)) return true

  return isAfter(date, dateToCompare)
}

export const isBetweenDates = (
  minDate: DateInput,
  maxDate: DateInput,
  dateInput?: DateInput,
  formatString?: string, // Requires similar custom format strings (if used)
) => {
  return isWithinInterval(SDate(dateInput, formatString), {
    start: SDate(minDate, formatString),
    end: SDate(maxDate, formatString),
  })
}

/** Helpers */
export const now = (date?: DateInput): Date =>
  date ? new Date(date) : new Date()

export const resetTime = (date: Date, nextDay = false) => {
  date.setHours(nextDay ? 24 : 0, 0, 0, 0)
  return date
}

/** Deprecate */
export const dateToLocaleString = (
  dateInput?: DateInput,
  formatString?: string,
) => format(SDate(dateInput, formatString), 'P')
export const dateToLongLocaleString = (
  dateInput?: DateInput,
  formatString?: string,
) => format(SDate(dateInput, formatString), 'PP')
