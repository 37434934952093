import { lazy, Suspense } from 'react'

import { StepComponentContainer } from '../../../types/flow'
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'
import { hasActivityFormValues } from '../ActivityStep/ActivityStep'
import { hasVehicleFormValues } from '../VehicleStep/VehicleStep'
import {
  GarageStepValues,
  GarageStepPrerequisiteData,
} from './GarageStep.types'

const GarageStepLazy = lazy(() => import('./GarageStep'))

export const GarageStepContainer: StepComponentContainer<
  GarageStepValues,
  GarageStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <GarageStepLazy {...props} />
    </Suspense>
  )
}

GarageStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is GarageStepPrerequisiteData =>
  hasVehicleFormValues(mergedFlowData) && hasActivityFormValues(mergedFlowData)
