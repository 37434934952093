import { makeStyles, Typography } from '@material-ui/core'
import { LocationIcon } from '@velocity/icons/system'
import { Button } from '@velocity/ui'

import { FC } from 'react'

const useAdjustQueryButtonStyles = makeStyles((theme) => ({
  root: {
    '&$root': {
      color: theme.palette.common.black,
      borderColor: theme.palette.grey['300'],
      textTransform: 'unset',
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: theme.spacing(3),
      justifyContent: 'flex-start',
      margin: theme.spacing(3, 'auto', 0, 'auto'),
    },
  },
  locationButton: {
    '&$locationButton': {
      color: theme.palette.primary.main,
    },
  },
}))

export const AdjustQueryButton: FC<{ onClick?: () => void }> = ({
  children,
  onClick,
}) => {
  const classNames = useAdjustQueryButtonStyles()

  return (
    <Button
      onClick={onClick}
      variant="primary-outlined"
      className={classNames.root}
      data-e2e-component="adjust-query-button"
      StartIcon={() => (
        <LocationIcon
          className={classNames.locationButton}
          size="s"
          display="flex"
        />
      )}
    >
      <Typography color="inherit" variant="body1">
        {children}
      </Typography>
    </Button>
  )
}
