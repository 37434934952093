import { Box } from '@material-ui/core'
import { ChevronRightIcon } from '@velocity/icons/system'
import { Card } from '@velocity/ui'
import { FlexBox, FlexColumn } from '@velocity/ui/draft'

import clsx from 'clsx'
import { detect } from 'detect-browser'
import { FC, ReactNode } from 'react'

import { SupplierLocation } from '../../types/models'
import { InstaBookBadge } from './components/InstaBookBadge'
import { SupplierCardInfo } from './components/SupplierCardInfo'
import { SupplierCardTitle } from './components/SupplierCardTitle'
import { Tooltip } from './components/Tooltip'
import { useCardStyles } from './SupplierCard.styled'

export interface SupplierCardProps {
  /**
   * Title of the card
   */
  title: string
  /**
   * Sets whether to display the instabook label for the supplier
   */
  isInstaBook?: boolean
  /**
   * Optional location details of the supplier
   */
  location?: SupplierLocation
  /**
   * Optional tooltip object
   */
  tooltip?: ReactNode
  /**
   * Optional function called when the card is clicked
   */
  onClick?: () => void
}

export const SupplierCard: FC<SupplierCardProps> = (props) => {
  const { title, isInstaBook, location, onClick, tooltip } = props
  const styles = useCardStyles()
  return (
    <Card onClick={onClick}>
      <Card.Body
        className={clsx(styles.root, detect()?.name === 'ie' && styles.rootIE)}
        title={title}
      >
        <FlexColumn data-e2e-component="supplier-card" alignItems="flex-start">
          <FlexBox justifyContent="space-between">
            <SupplierCardTitle>{title}</SupplierCardTitle>
            {tooltip && (
              <Box>
                <Tooltip>{tooltip}</Tooltip>
              </Box>
            )}
          </FlexBox>
          {location && (
            <FlexBox alignItems="center" pr={0.5}>
              <FlexBox>
                <FlexColumn>
                  <SupplierCardInfo>{location.address}</SupplierCardInfo>
                  <SupplierCardInfo>{`${location.city}, ${location.postalCode}`}</SupplierCardInfo>
                </FlexColumn>
              </FlexBox>
              <ChevronRightIcon name="chevronRight" size="s" />
            </FlexBox>
          )}
          {isInstaBook && <InstaBookBadge className={styles.instaBookBadge} />}
        </FlexColumn>
      </Card.Body>
    </Card>
  )
}
