import { FC, HTMLAttributes } from 'react'

import accidentTree from './svg/accidentTree.svg'
import appointmentNotFound from './svg/appointmentNotFound.svg'
import appointmentScheduled from './svg/appointmentScheduled.svg'
import calendar from './svg/calendar.svg'
import carDamage from './svg/carDamage.svg'
import carMissing from './svg/carMissing.svg'
import checkCar from './svg/checkCar.svg'
import checkWhiteSimple from './svg/checkSimple.svg'
import contact from './svg/contact.svg'
import email from './svg/email.svg'
import info from './svg/info.svg'
import travelingAbroad from './svg/travelingAbroad.svg'

export const FTB_SVG_ICONS = {
  accidentTree,
  appointmentNotFound,
  appointmentScheduled,
  calendar,
  carDamage,
  carMissing,
  checkCar,
  checkWhiteSimple,
  contact,
  email,
  info,
  travelingAbroad,
}

export type FtbSvgIconName = keyof typeof FTB_SVG_ICONS

export interface FtbSvgIconProps extends HTMLAttributes<HTMLImageElement> {
  /**
   * Required name of the icon to be displayed
   */
  name: FtbSvgIconName
}

export const FtbSvgIcon: FC<FtbSvgIconProps> = ({ className, name, style }) => (
  <img
    alt={`svg-icon-${name}`}
    className={className}
    data-e2e-component={`svg-icon-${name}`}
    src={FTB_SVG_ICONS?.[name] || FTB_SVG_ICONS.accidentTree}
    style={style}
  />
)
