import { AppLocale } from './i18n'

/**
 * API does not define standalone DriveData, here defined for better readability
 */
export type DriverData = {
  /**
   * The email address of the driver, example: driver@leaseplan.com
   */
  email: string
  /**
   * The driver’s first name
   */
  firstName: string
  /**
   * The driver’s last name
   */
  lastName: string
  /**
   * The license plate of the vehicle
   */
  licensePlate: string
  /**
   * The driver’s locale, example: en-nl
   */
  locale: string
  /**
   * The mileage of the vehicle for which the driver is creating a booking.
   * The unit of this field depends on the configuration of the country CRM that is connected,
   * for example miles or kilometers.
   */
  mileage: number
  /**
   * The driver’s country phone code
   */
  phoneCode: string
  /**
   * The driver’s phone number
   */
  phoneNumber: string
}

export enum SupplierAvailabilityStatus {
  Available = 'available',
  ConfirmationRequired = 'confirmation-required',
}

export enum MalfunctionCode {
  AdblueRequired = 'ADBLUE_REQUIRED',
  AirConNotWorking = 'AIR_CON_NOT_WORKING',
  Battery = 'BATTERY',
  BluetoothRadioNotWorking = 'BLUETOOTH_RADIO_NOT_WORKING',
  BootBonnetDoorProblem = 'BOOT_BONNET_DOOR_PROBLEM',
  BrakeFailureOrWarning = 'BRAKE_FAILURE_OR_WARNING',
  CarHeadlightsBurntoutBulbLight = 'CAR_HEADLIGHTS_BURNTOUT_BULB_LIGHT',
  CarIsOverheating = 'CAR_IS_OVERHEATING',
  CarIsSmoking = 'CAR_IS_SMOKING',
  CarKeyBatteryCarRemoteControlBatteryOutOfOrder = 'CAR_KEY_BATTERY_CAR_REMOTE_CONTROL_BATTERY_OUT_OF_ORDER',
  CheckWipers = 'CHECK_WIPERS',
  ClutchGearSelectionOrGearboxNoise = 'CLUTCH_GEAR_SELECTION_OR_GEARBOX_NOISE',
  ClutchIssues = 'CLUTCH_ISSUES',
  CoolantLeak = 'COOLANT_LEAK',
  ElectricalIssueComponentNotWorking = 'ELECTRICAL_ISSUE_COMPONENT_NOT_WORKING',
  EngineAuxheater = 'ENGINE_AUXHEATER',
  FluidLeaks = 'FLUID_LEAKS',
  FuelLeak = 'FUEL_LEAK',
  GearboxIssues = 'GEARBOX_ISSUES',
  GpsRadioMultimediaNotWorking = 'GPS_RADIO_MULTIMEDIA_NOT_WORKING',
  LightNotWorking = 'LIGHT_NOT_WORKING',
  NoiseFromEngine = 'NOISE_FROM_ENGINE',
  NoiseFromVehicle = 'NOISE_FROM_VEHICLE',
  OilCoolantBrakeFluidNeedToBeFilled = 'OIL_COOLANT_BRAKE_FLUID_NEED_TO_BE_FILLED',
  OilLeak = 'OIL_LEAK',
  OilLightOrOilPressure = 'OIL_LIGHT_OR_OIL_PRESSURE',
  OrangeActivationLightTurnOn = 'ORANGE_ACTIVATION_LIGHT_TURN_ON',
  Other = 'OTHER',
  RecallOem = 'RECALL_OEM',
  RemoteKeyBattery = 'REMOTE_KEY_BATTERY',
  RunningLights = 'RUNNING_LIGHTS',
  SatnavSoftware = 'SATNAV_SOFTWARE',
  Security = 'SECURITY',
  SteeringProblem = 'STEERING_PROBLEM',
  Suspension = 'SUSPENSION',
  Tpms = 'TPMS',
  VehicleHeater = 'VEHICLE_HEATER',
  VehicleInLimpModeOrLossOfPower = 'VEHICLE_IN_LIMP_MODE_OR_LOSS_OF_POWER',
  WarningLightOnDashboard = 'WARNING_LIGHT_ON_DASHBOARD',
  WarningMessageOnDashboard = 'WARNING_MESSAGE_ON_DASHBOARD',
}

export type Location = {
  /**
   * The city of the location.
   */
  city: string
  /**
   * The country of the location.
   */
  country: string
  /**
   * The latitude of the location.
   */
  latitude: number
  /**
   * The longitude of the location.
   */
  longitude: number
  /**
   * The postal code of the location.
   */
  postalCode: string
  /**
   * The street name of the location.
   */
  streetName: string
  /**
   * The street number of the location.
   */
  streetNumber: string
}

/**
 * Malfunction defined by the respective country
 */
export type Malfunction = {
  /**
   * A malfunction code
   */
  code: MalfunctionCode
  /**
   * Defines if the malfunction is available for a booking.
   */
  isAvailable: boolean
}

/**
 *
 * The request body for the "createBooking" endpoint
 * --
 * For better readability created based on DriverData that does not exist as a standalone type in API's swagger
 */
export type BookingData = DriverData & {
  /**
   * The date of the booking
   */
  bookingDate: string
  /**
   * The comments about what needs to be done in the car
   */
  comments: string
  /**
   * The country code of the organization that manages the vehicle
   */
  countryCode: string
  /**
   * The time of the booking, example: 09:15
   */
  dropOffTime: string
  /**
   * A list of malfunctions.
   */
  malfunctionCodes: MalfunctionCode[]
  /**
   * Optional. The pickup and return location.
   */
  pickupLocation?: Location
  /**
   * Optional. The preferred pickup time at the pickup location.
   */
  pickupTime?: string
  /**
   * Proactive maintenance identification, example: 5001G00000jJEvDQAW
   */
  predictiveMaintenanceIdentification?: string
  /**
   * A list of services.
   */
  serviceCodes: VehicleServiceCode[]
  /**
   * Enum: [ 'available', 'confirmation-required' ]
   */
  supplierAvailabilityStatus: SupplierAvailabilityStatus
  /**
   * The id of the supplier, type of UUID
   */
  supplierId: string
  /**
   * bookingType: "Service;CVRT;Repair"
   * - for LPIE the bookingType could be "Service", "CVRT", "Repair". One of them or all of them separated ";".
   * - for another countries "Service" only
   * */
  bookingType: string
}

export type SupplierData = Location & {
  /**
   * The garage/supplier availability check status
   */
  availabilityStatus: SupplierAvailabilityStatus
  /**
   * The distance between the location the user searched on and the supplier.
   */
  distance: number
  /**
   * The supplier place id, type of UUID
   */
  id: string
  /**
   * The supplier place name
   */
  name: string
}

/**
 * The successful response to createBooking request
 */
export type CreateBookingResponse = {
  /**
   * Date time when the booking was created
   */
  createdDateTime: string
}

/**
 * The request body for the "searchSuppliers" endpoint
 * Describes the location where the supplier should be searched and for which vehicle.
 */
export type SearchSuppliersRequest = Pick<
  BookingData,
  /**
   * Booking date ISO string (`YYYY-MM-DD`)
   */
  | 'bookingDate'
  | 'countryCode'
  | 'licensePlate'
  | 'malfunctionCodes'
  | 'mileage'
  | 'serviceCodes'
> &
  Pick<SupplierData, 'latitude' | 'longitude'>

/**
 * The response body for the "searchSuppliers" endpoint
 */
export type SearchSuppliersData = SupplierData[]

/**
 * The response body for the "confirmBooking" endpoint
 */
export type BookingConfirmationData = Pick<
  BookingData,
  | 'bookingDate'
  | 'dropOffTime'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'licensePlate'
  | 'locale'
  | 'pickupLocation'
  | 'pickupTime'
  | 'predictiveMaintenanceIdentification'
> & {
  supplier: SupplierData
}

export type BookingConfirmationRequest = {
  /**
   * Confirmation code that is unique to the booking
   */
  code: string
}

export type ErrorResponse = {
  /**
   * A code which uniquely identifies the type of error that occurred
   */
  code: string
  /**
   * A human readable description of the error that occurred
   */
  message: string
}

export type DropOffInterval = {
  /**
   * End time to drop off.
   */
  endTime: string
  /**
   * Interval between start and end time.
   */
  minutesOfInterval: number
  /**
   * Start time to drop off.
   */
  startTime: string
}

export enum MaintenanceProposalStatus {
  New = 'NEW',
  Rejected = 'REJECTED',
  Other = 'OTHER',
}

export enum MaintenanceProposalRejectionReason {
  EarlyBooking = 'EARLY_BOOKING',
  BookedAlready = 'BOOKED_ALREADY',
  HadRecentAppointment = 'HAD_RECENT_APPOINTMENT',
}

export type MaintenanceProposalAttributes = {
  /**
   * The license plate of the vehicle
   */
  licensePlate: string
  /**
   * Actual mileage of the vehicle
   */
  mileage: number
  /**
   * Enum: [ 'EARLY_BOOKING', 'BOOKED_ALREADY', 'HAD_RECENT_APPOINTMENT' ]
   */
  rejectionReason: MaintenanceProposalRejectionReason
  /**
   * Enum: [ 'NEW', 'REJECTED', 'OTHER' ]
   */
  status: MaintenanceProposalStatus
}

export type MaintenanceProposalInfo = {
  /**
   * LeasePlan country code, example: LPGB
   */
  countryCode: string
  /**
   * Enum: [ 'NEW', 'REJECTED', 'OTHER' ]
   */
  status: MaintenanceProposalStatus
}

export type CountryConfiguration<
  TSupportedLocales extends readonly AppLocale[] = AppLocale[],
> = {
  /**
   * Additional requests max. length 500
   */
  additionalRequestsMaxLength: number
  /**
   * example: contact@example.com
   */
  contactEmail: string
  /**
   * example: 0201234567 (Option 1)
   */
  contactPhone: string
  /**
   * example: 33896823
   */
  contactPhoneLabel: string
  /**
   * LeasePlan country code, example: LPIE
   */
  countryCode: string
  /**
   *
   */
  dropOffInterval: DropOffInterval
  /**
   * Enables vehicle eligibility check via the API contract service based on license plate and milege
   */
  enableEligibilityCheck: boolean
  /**
   * Enable insta book indication, example: true
   */
  enableInstaBookIndication: boolean
  /**
   * Enable map planning, example: true
   */
  enableMapPanning: boolean
  /**
   * Enable negative flow for the country, example: true
   */
  enableNegativeFlow: boolean
  /**
   * Enable tips while loading, example: true
   */
  enableTipsWhileLoading: boolean
  /**
   * Format locale, example: en-ie
   */
  formatLocale: string
  /**
   * The international calling code number, example: 33
   */
  internationalCallingCode: string
  /**
   * Regex that will be used to validate the license plate, example: ^[A-Z0-9]{6}?$
   */
  licensePlateFormat: string
  /**
   * Supported locales
   */
  locale: TSupportedLocales
  /**
   * Supported malfunctions
   */
  malfunctions: Malfunction[]
  /**
   * The maximum number of working days that should be between the booking creation and the actual booking, example: 14
   */
  maximumDaysRequiredForBooking: number
  /**
   * The maximum range used for searching suppliers, example: 20
   */
  maximumDistance: number
  /**
   * The minimum number of working days that should be between the booking creation and the actual booking, example: 2
   */
  minimumDaysRequiredForBooking: number
  /**
   * Phone max length, example: 14
   */
  phoneMaxLength: number
  /**
   * Phone min length, example: 9
   */
  phoneMinLength: number
}

/**
 * The request body for the "vehicleServices" endpoint
 */
export type VehicleServicesRequest = Pick<
  BookingData,
  'licensePlate' | 'mileage' | 'countryCode'
>

/**
 * The response body for the "vehicleExist" endpoint
 */
export type VehicleExistResponse = {
  isValid: boolean
}

/**
 * The response body for the "vehicleServices" endpoint
 */
export type VehicleService = {
  /**
   * Enum VehicleService
   */
  code: VehicleServiceCode
  isAdditional: boolean
  isAvailable: boolean
}

export enum VehicleServiceCode {
  Maintenance = 'MAINTENANCE',
  SpecialMaintenance = 'SPECIAL_MAINTENANCE',
  PassengerInspection = 'PASSENGER_INSPECTION',
  CommercialInspection = 'COMMERCIAL_INSPECTION',
  CollectReturn = 'COLLECT_RETURN',
  CompCleaning = 'COMP_CLEANING',
  ExpressService = 'EXPRESS_SERVICE',
  GlassCrackRepair = 'GLASS_CRACK_REPAIR',
  TechnicalInspection = 'TECHNICAL_INSPECTION',
  ReplacementComVehicle = 'REPLACEMENT_COM_VEHICLE',
  ReplacementPassengerVehicle = 'REPLACEMENT_PASSENGER_VEHICLE',
  ServiceOnLocation = 'SERVICE_ON_LOCATION',
  OutsideWash = 'OUTSIDE_WASH',
  InsideCleaning = 'INSIDE_CLEANING',
  InsideWash = 'INSIDE_WASH',
  TyreRepair = 'TYRE_REPAIR',
}

export enum BookingTypes {
  Service = 'Service',
  Repair = 'Repair',
  Cvrt = 'CVRT',
}

export type MaybeCountryConfiguration = CountryConfiguration | null | undefined
