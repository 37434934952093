import AyvensTheme from '@velocity/styling/themes/ayvens/theme'
import LeasePlanTheme from '@velocity/styling/themes/leaseplan/theme'
import { VelocityProvider, GoogleMapsContextProvider } from '@velocity/ui'

import { FC } from 'react'

import { supportedDateFnsLocales } from '../../i18n/supportedLocales'
import { AppLocale } from '../../types/i18n'
import { BrandTheme } from '../../types/theme'

export const FtbVelocityProvider: FC<{
  theme?: BrandTheme
  locale: AppLocale
  googleMapsApiKey: string
}> = ({ children, locale, googleMapsApiKey, theme }) => {
  return (
    <VelocityProvider
      theme={theme === BrandTheme.Ayvens ? AyvensTheme : LeasePlanTheme}
      // accepts map of: localeName(any string) => localeObject (DateFns Locale)
      // advised format: LanguageComponent-AnyOtherComponent/s (see explanation below)
      dateFnsLocales={supportedDateFnsLocales}
      // When VEL can't find the passed locale name in its supplied locales, it
      // splits strings that are >2 characters and retries search in the prospect
      // of discovering a supported language-only locale.
      // If it fails again (can't find 'nb-no' or 'nb') it uses 'en-US' as fallback.
      locale={locale}
    >
      <GoogleMapsContextProvider apiKey={googleMapsApiKey}>
        {children}
      </GoogleMapsContextProvider>
    </VelocityProvider>
  )
}
