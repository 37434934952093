import { lazy, Suspense } from 'react'

import { StepComponentContainer } from '../../../types/flow'
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'
import { VehicleStepValues } from './VehicleStep'

const VehicleStepLazy = lazy(() => import('./VehicleStep'))

export const VehicleStepContainer: StepComponentContainer<VehicleStepValues> = (
  props,
) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <VehicleStepLazy {...props} />
    </Suspense>
  )
}

VehicleStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is {} => true
