import { format, isAfter, isEqual } from '../i18n/localizedDateFns'
import { now, SDate } from './date'

type Period = keyof Pick<Duration, 'hours' | 'minutes' | 'seconds'>

export type PeriodicConfig = {
  startTime: string
  interval: number
  period: Period
  intervalCount?: number
}

export const DEFAULT_PERIOD: Period = 'minutes'
export const DEFAULT_PERIODIC_CONFIG: PeriodicConfig = {
  startTime: '09:00',
  interval: 30,
  intervalCount: 16,
  period: DEFAULT_PERIOD,
}

export const TIME_FORMAT = 'HH:mm'
const DAY_MS = 86400000
const periodMs: Record<Period, number> = {
  hours: 3600000,
  minutes: 60000,
  seconds: 1000,
}

const timeStringRegEx = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/

export const setTimeString = (date = now(), time: string) => {
  const dateClone = SDate(date)

  if (!time) return date // with current time

  if (!timeStringRegEx.test(time)) {
    throw new Error(`Invalid time format. Must be "${TIME_FORMAT}".`)
  }

  const [hours, minutes] = time.split(':')

  dateClone.setHours(+hours, +minutes, 0, 0)

  return dateClone
}

export const getTimeline = ({
  startTime,
  interval,
  period = DEFAULT_PERIOD,
  intervalCount,
}: PeriodicConfig) => {
  const dT = setTimeString(now(), startTime)

  // calculate based on given count
  if (intervalCount) {
    const timeLabels: string[] = []

    for (let i = 0; i < intervalCount; i++) {
      const nextTime = now(dT).setTime(
        dT.getTime() + i * interval * periodMs[period],
      )
      timeLabels.push(format(nextTime, TIME_FORMAT))
    }

    return timeLabels
  }

  // calculate all for a day
  const timeLabels: string[] = []
  const start = now(dT)
  const end = now(dT).setTime(dT.getTime() + DAY_MS)
  timeLabels.push(format(start, TIME_FORMAT))

  while (!isEqual(start, end) && !isAfter(start, end)) {
    const nextTime = start.setTime(
      start.getTime() + interval * periodMs[period],
    )
    timeLabels.push(format(nextTime, TIME_FORMAT))
  }

  return timeLabels
}

export const timeStringToMinutes = (time: string): number => {
  const timeArray = time.split(':')
  const hours = Number.parseInt(timeArray[0]) * 60
  const minutes = Number.parseInt(timeArray[1])
  return hours + minutes
}

export const intervalQuantity = (
  startTime: string,
  endTime: string,
): number => {
  const timeInterval =
    timeStringToMinutes(endTime) - timeStringToMinutes(startTime)
  return timeInterval / 30 + 1
}
