import { LocaleSelector as VelocityLocaleSelector } from '@velocity/ui-lab'
import { FC } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { defaultLocale, isSupportedLocale } from '../../i18n/supportedLocales'
import { AppLocale, CountryCode, CountryCodeUppercase } from '../../types/i18n'

import {
  extractLocaleFromUrl,
  extractLanguageFromLocale,
} from '../../utils/i18n'

export const LocaleSelector: FC = () => {
  const translations = useTranslation()
  const sharedTranslations = translations?.steps?.shared

  const f = useMessageFormat()
  const {
    countryConfig: { locale: availableLocales },
    country: { code },
    changeLocale,
  } = useUxConfig()
  const currentLocale = extractLocaleFromUrl() || defaultLocale
  const currentLanguage = extractLanguageFromLocale(currentLocale)

  const tLabelLocation = f(sharedTranslations?.labels['location'], {
    debugId: 'shared.localeSelector.labelLocation',
  })
  const tLabelLanguage = f(sharedTranslations?.labels['language'], {
    debugId: 'shared.localeSelector.labelLanguage',
  })
  const tCountryName = f(sharedTranslations?.labels['countryName'], {
    debugId: 'shared.localeSelector.countryName',
  })
  const tLanguage = (lang: string) =>
    f(sharedTranslations?.labels[`lang-${lang}`], {
      debugId: `shared.localeSelector.lang.${lang}`,
    })
  const languages = availableLocales.map((lang: AppLocale) => ({
    label: tLanguage(extractLanguageFromLocale(lang)),
    value: lang,
  }))
  const handleSelectLocale = (locale: string | null) => {
    if (!isSupportedLocale(locale)) {
      return
    }
    if (languages.find((lang) => lang.value === locale)) {
      changeLocale(locale)
    }
  }

  return tCountryName ? (
    <VelocityLocaleSelector
      label={tCountryName}
      languages={languages}
      onSelectLanguage={handleSelectLocale}
      activeCountry={{
        label: tCountryName,
        code: toUpperCaseCountryCode(code),
      }}
      activeLanguage={{
        label: tLanguage(currentLanguage),
        value: currentLocale,
      }}
      labelLocation={tLabelLocation}
      labelLanguage={tLabelLanguage}
      aria-label="locale-selector"
    />
  ) : null
}

const toUpperCaseCountryCode = (code: CountryCode) =>
  code.toUpperCase() as CountryCodeUppercase
