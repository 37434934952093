import { makeStyles } from '@material-ui/core'
import { FlexColumn } from '@velocity/ui/draft'
import { FC } from 'react'

const useSupplierResultsContainerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    alignContent: 'center',
    padding: theme.spacing(0, 2),
    position: 'relative',
    marginTop: theme.spacing(3),
  },
}))

export const SupplierResultsContainer: FC = ({ children }) => {
  const styles = useSupplierResultsContainerStyles()
  return <FlexColumn className={styles.root}>{children}</FlexColumn>
}
