import { AxiosError } from 'axios'

import { ERROR_CODE_TO_SITECORE_KEY_MAP } from '../types/errors'
import { Translations } from '../types/i18n'
import { ErrorPayload } from '../types/models'
import { FormatMessageApi } from '../types/validation'

const SERVER_ERROR = 500

export const getErrorFromPayload = (error: AxiosError<ErrorPayload>) => {
  if (error.response?.data?.code) {
    return error.response?.data?.code
  }

  return error.response ? error.response.status : SERVER_ERROR
}

export const getTranslatedTitleAndMessageForError = (
  code: string,
  translations: Translations | undefined,
  format: FormatMessageApi<string | number | JSX.Element>,
  messageValues?: Record<string, string | JSX.Element>,
) => {
  const errorMessages = translations?.steps?.shared?.errorMessages
  const debugIdPrefix = 'steps.shared.errorMessages.'
  const sitecoreKey = ERROR_CODE_TO_SITECORE_KEY_MAP[code]
  if (sitecoreKey) {
    return {
      title: format(errorMessages?.[sitecoreKey], {
        debugId: `${debugIdPrefix}${sitecoreKey}`,
      }),
      message: messageValues
        ? format['JSX'](errorMessages?.[`${sitecoreKey}Description`], {
            values: messageValues,
            debugId: `${debugIdPrefix}${sitecoreKey}Description`,
          })
        : format(errorMessages?.[`${sitecoreKey}Description`], {
            debugId: `${debugIdPrefix}${sitecoreKey}Description`,
          }),
    }
  } else {
    return {
      title: format(errorMessages?.['systemError'], {
        debugId: 'steps.shared.errorMessages.systemError',
      }),
    }
  }
}
