import { makeStyles, Theme } from '@material-ui/core'

import { copyFontStyles } from '../../utils/styles'

export const useAvailableCountriesViewStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$root': {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  content: {
    '&$content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: 600,
      [theme.breakpoints.up('md')]: {
        width: 600,
        backgroundColor: '#fff',
        marginTop: theme.spacing(5),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
      },
    },
  },
  countryButton: {
    '&$countryButton': {
      width: '30%',
      marginTop: theme.spacing(1),
      justifyContent: 'flex-start',
      borderRadius: 25,
      minWidth: 150,
      '& svg': {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(2),
      },
    },
  },
  title: {
    '&$title': {
      ...copyFontStyles(theme.typography.h5),
      paddingTop: theme.spacing(2),
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        ...copyFontStyles(theme.typography.h2),
      },
    },
  },
  text: {
    '&$text': {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.subtitle1.fontSize,
      textAlign: 'center',
    },
  },
  button: {
    '&$button': {
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
      width: '50%',
      minWidth: 280,
      maxWidth: 340,
      height: 50,
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        // marginTop: theme.spacing(3),
        fontSize: theme.typography.subtitle2.fontSize,
      },
    },
  },
  footer: {
    '&$footer': {
      marginTop: 'auto',
    },
  },
}))
