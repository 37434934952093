import { CheckboxCard } from '@velocity/ui'
import { FlexBox } from '@velocity/ui/draft'
import clsx from 'clsx'
import { useCallback } from 'react'
import * as React from 'react'

import { VehicleServiceCode } from '../../../../../types/api'
import { Service } from '../../../../../types/models'
import { useStyles } from './ServiceList.styled'

interface ServiceListProps {
  services: Service<VehicleServiceCode>[]
  value?: Service<VehicleServiceCode>['value'][]
  className?: string
  testId?: string
  onChange?: (
    services: Service<VehicleServiceCode>['value'][],
    service: Service<VehicleServiceCode>['value'],
  ) => void
}

export const ServiceList: React.FC<ServiceListProps> = ({
  className,
  services,
  testId,
  value = [],
  onChange,
}) => {
  const classes = useStyles()
  const [filteredServices, setFilteredServices] =
    React.useState<Service<VehicleServiceCode>[]>(services)

  // toggle ReplacementPassengerVehicle and ReplacementComVehicle for user
  React.useEffect(() => {
    let toggledServicesList
    if (value.includes(VehicleServiceCode.ReplacementPassengerVehicle)) {
      toggledServicesList = services.map((s) =>
        s.value === VehicleServiceCode.ReplacementComVehicle
          ? { ...s, enabled: false }
          : s,
      )
      setFilteredServices(toggledServicesList)
    } else if (value.includes(VehicleServiceCode.ReplacementComVehicle)) {
      toggledServicesList = services.map((s) =>
        s.value === VehicleServiceCode.ReplacementPassengerVehicle
          ? { ...s, enabled: false }
          : s,
      )
      setFilteredServices(toggledServicesList)
    } else {
      setFilteredServices(services)
    }
  }, [services, value])

  const handleChange = useCallback(
    (serviceValue) => {
      let serviceValues
      if (value.includes(serviceValue)) {
        serviceValues = value.filter((s) => s !== serviceValue)
      } else {
        serviceValues = [...value, serviceValue]
      }
      onChange?.(serviceValues, serviceValue)
    },
    [onChange, value],
  )

  return (
    <FlexBox
      flexWrap="wrap"
      className={clsx(classes.root, className)}
      data-e2e-component={testId || 'service-list'}
    >
      {filteredServices.map((service) => (
        <div key={service.value} className={classes.checkboxCardWrapper}>
          <CheckboxCard
            value={undefined}
            data-e2e-component={`service-checkbox-${service.value}`}
            title={service.label}
            details={service.description}
            className={classes.checkboxCard}
            checked={value.includes(service.value)}
            onChange={() => handleChange(service.value)}
            disabled={service.enabled === false}
          />
        </div>
      ))}
    </FlexBox>
  )
}
