import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useAvailableCountriesViewStyles } from '../AvailableCountriesView/AvailableCountriesView.styled'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { PageBanner } from '../PageBanner/PageBanner'

export const DisabledAppPage = () => {
  const containerClasses = useAvailableCountriesViewStyles()
  const translations = useTranslation()
  const sharedTranslations = translations?.steps?.shared?.errorMessages

  return (
    <div className={containerClasses.root}>
      <Header hideLocaleSelector={true} />
      <PageBanner />
      <div className={containerClasses.content}>
        <h2>{sharedTranslations?.outOfServiceMessage}</h2>
      </div>
      <Footer className={containerClasses.footer} />
    </div>
  )
}
