import { Container } from '@material-ui/core'
import { Input } from '@velocity/ui'
import { FormApi } from 'final-form'

import { trim as ramdaTrim } from 'ramda'
import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { Form } from 'react-final-form'

import { useTranslation } from '../../../context/TranslationContext/TranslationContext'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useMessageFormat } from '../../../hooks/useMessageFormat'
import { useTracking } from '../../../hooks/useTracking'
import { DriverData } from '../../../types/api'
import { handleInputBlur } from '../../../utils/handleInputBlur'
import { FormField } from '../../FormField/FormField'
import { NO_TRANSLATE_CLASS_NAME } from '../shared/constants'
import { StepFormButtons } from '../shared/StepFormButtons/StepFormButtons'
import { StepFormProps } from '../shared/types'
import { useStyles } from './DriverForm.styled'

import { useDriverFormValidators } from './DriverForm.validators'

export type DriverFormValues = Pick<
  DriverData,
  'firstName' | 'lastName' | 'email' | 'phoneNumber'
>

export type DriverFormProps = StepFormProps<DriverFormValues>

const fieldIds = {
  firstName: 'firstNameId',
  lastName: 'lastNameId',
  email: 'emailId',
  phoneNumber: 'phoneNumberId',
}
const fieldNames = Object.keys(fieldIds)

const trim = (value?: string) => ramdaTrim(value || '')

export const DRIVER_FORM_DEFAULTS: Partial<DriverFormValues> = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

export const DriverForm: FC<DriverFormProps> = ({
  className,
  initialValues = DRIVER_FORM_DEFAULTS,
  desktopView,
  error,
  onFieldError,
  onGoBack,
  onSubmit,
}) => {
  const classes = useStyles()
  const f = useMessageFormat()
  const { trackEvent } = useTracking()
  const [validationMutator, setFormApi, validate] =
    useFormValidation<DriverFormValues>(fieldNames)
  const formApiRef = useRef<FormApi<DriverFormValues>>()
  const translations = useTranslation()
  const formTranslations = translations?.forms?.driver
  const sharedTranslations = translations?.forms?.shared

  const formLabels = useMemo(
    () => ({
      ...formTranslations?.labels,
      ...sharedTranslations?.labels,
    }),
    [formTranslations?.labels, sharedTranslations?.labels],
  )

  const formErrors = useMemo(
    () => ({
      ...formTranslations?.errorMessages,
      ...sharedTranslations?.errorMessages,
    }),
    [formTranslations?.errorMessages, sharedTranslations?.errorMessages],
  )

  const validators = useDriverFormValidators(formErrors, f)

  const handleSubmit = useCallback(
    (values: DriverFormValues) => {
      const formValues: DriverFormValues = {
        firstName: trim(values.firstName),
        lastName: trim(values.lastName),
        email: trim(values.email).toLowerCase(),
        phoneNumber: trim(values.phoneNumber),
      }
      onSubmit?.(formValues)
    },
    [onSubmit],
  )

  useEffect(() => {
    setFormApi(formApiRef.current)
    // only for initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    validate?.()
  }, [validate, validators])

  const handleBlur = handleInputBlur(onFieldError)

  return (
    <Container className={className}>
      <Form<DriverFormValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={validationMutator}
      >
        {(formProps) => {
          formApiRef.current = formProps.form
          return (
            <form
              onSubmit={formProps.handleSubmit}
              data-e2e-component="driver-form"
              noValidate={true}
              autoComplete="on"
            >
              <FormField
                name="firstName"
                validator={validators.firstName}
                label={f(formLabels['firstName'], {
                  debugId: 'forms.driver.firstName',
                })}
                htmlFor={fieldIds.firstName}
                classes={{ formControl: classes.field }}
              >
                {({ input, meta }) => (
                  <Input
                    className={NO_TRANSLATE_CLASS_NAME}
                    {...input}
                    onBlur={(e) =>
                      handleBlur(e, input.onBlur, meta.error?.type)
                    }
                    inputProps={{
                      'data-e2e-component': 'first-name-input',
                      maxLength: 40,
                    }}
                  />
                )}
              </FormField>
              <FormField
                name="lastName"
                validator={validators.lastName}
                label={f(formTranslations?.labels['lastName'], {
                  debugId: 'forms.driver.lastName',
                })}
                htmlFor={fieldIds.lastName}
                classes={{ formControl: classes.field }}
              >
                {({ input, meta }) => (
                  <Input
                    className={NO_TRANSLATE_CLASS_NAME}
                    {...input}
                    onBlur={(e) =>
                      handleBlur(e, input.onBlur, meta.error?.type)
                    }
                    inputProps={{
                      'data-e2e-component': 'last-name-input',
                      maxLength: 50,
                    }}
                  />
                )}
              </FormField>
              <FormField
                name="email"
                validator={validators.email}
                label={f(formLabels['email'], {
                  debugId: 'forms.driver.email',
                })}
                hint={f(formLabels['emailHelp'], {
                  debugId: 'forms.driver.emailHelp',
                })}
                htmlFor={fieldIds.email}
                classes={{ formControl: classes.field }}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    onBlur={(e) =>
                      handleBlur(e, input.onBlur, meta.error?.type)
                    }
                    inputProps={{
                      'data-e2e-component': 'email-input',
                    }}
                    className={NO_TRANSLATE_CLASS_NAME}
                  />
                )}
              </FormField>
              <FormField
                name="phoneNumber"
                validator={validators.phone}
                label={f(formLabels['phone'], {
                  debugId: 'forms.driver.phone',
                })}
                htmlFor={fieldIds.phoneNumber}
                classes={{ formControl: classes.field }}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    onBlur={(e) =>
                      handleBlur(e, input.onBlur, meta.error?.type)
                    }
                    inputProps={{
                      'data-e2e-component': 'phone-input',
                    }}
                    className={NO_TRANSLATE_CLASS_NAME}
                  />
                )}
              </FormField>
              {!desktopView && error}
              <StepFormButtons
                onPrevious={() => onGoBack?.(formProps.values)}
                nextDisabled={formProps.submitting}
                nextButtonProps={{
                  onClick: () => {
                    for (const formFieldName in formProps.errors) {
                      trackEvent({
                        event: 'formEvent',
                        formName: 'garage booking',
                        formStep: 'driver-form',
                        formContext: 'ftb',
                        formErrorField: formFieldName,
                        formErrorType:
                          formProps.errors?.[`${formFieldName}`].type,
                      })
                    }
                  },
                }}
              />
            </form>
          )
        }}
      </Form>
    </Container>
  )
}
