import { VelFocusEvent } from '@velocity/ui'
import { FocusEvent } from 'react'

export type InputFocusEvent = FocusEvent<HTMLInputElement | HTMLTextAreaElement>

export const handleInputBlur = (
  errorHandler?: (fieldName: string, error: string) => void,
) => {
  return (
    e: InputFocusEvent | VelFocusEvent,
    onBlur: (e: InputFocusEvent) => void,
    error?: string,
  ) => {
    const name = e.target.name
    error && errorHandler && errorHandler(name || 'Missing name', error)
    onBlur(e as InputFocusEvent)
  }
}
