import { CheckWithLabelOption } from '@velocity/ui'

import { MalfunctionCode, VehicleServiceCode } from '../types/api'
import {
  GetTranslations_ftbToolV2,
  GetTranslations_ftbToolV2_footer,
  GetTranslations_ftbToolV2_footer_labels,
  GetTranslations_ftbToolV2_forms,
  GetTranslations_ftbToolV2_forms_errorMessages,
  GetTranslations_ftbToolV2_forms_labels,
  GetTranslations_ftbToolV2_results,
  GetTranslations_ftbToolV2_steps,
  GetTranslations_ftbToolV2_steps_errorMessages,
  GetTranslations_ftbToolV2_steps_labels,
  GetTranslations_ftbToolV2_predictiveMaintenance,
  GetTranslations_ftbToolV2_repairs,
  GetTranslations_ftbToolV2_services,
} from '../types/apollo/GetTranslations'
import {
  FlattenedErrorMessages,
  FlattenedLabels,
  FooterDictionary,
  FormNames,
  FormRecords,
  NestedDictionary,
  ResultsViewContentMap,
  SitecoreDictionary,
  StepRecords,
  Translations,
  PMRecords,
  StepNames,
} from '../types/i18n'
import {
  FlowStepName,
  MalfunctionDictionary,
  Service,
  ServiceDictionary,
} from '../types/models'

const SITECORE_STEP_NAMES: Record<string, StepNames> = {
  Vehicle: FlowStepName.Vehicle,
  Activity: FlowStepName.Activity,
  Garage: FlowStepName.Garage,
  Driver: FlowStepName.Driver,
  Summary: FlowStepName.Summary,
  Done: FlowStepName.Done,
  Shared: 'shared',
}

const SITECORE_FORM_NAMES: Record<string, FormNames> = {
  Vehicle: FlowStepName.Vehicle,
  Activity: FlowStepName.Activity,
  Garage: FlowStepName.Garage,
  Driver: FlowStepName.Driver,
  'PM Rejection': 'pMRejection',
  Shared: 'shared',
}

const COMPOSITE_ENTRY_TEMPLATE = {
  labels: {},
  errorMessages: {},
  otherData: {},
}

function createEmptyStepsObject() {
  const stepNames = Object.values(SITECORE_STEP_NAMES)
  return stepNames.reduce<StepRecords>(
    (acc, key) => ({
      ...acc,
      [key]: { ...COMPOSITE_ENTRY_TEMPLATE },
    }),
    {} as StepRecords,
  )
}

function createEmptyFormsObject() {
  const formNames = Object.values(SITECORE_FORM_NAMES)
  return formNames.reduce<FormRecords>(
    (acc, key) => ({
      ...acc,
      [key]: { ...COMPOSITE_ENTRY_TEMPLATE },
    }),
    {} as FormRecords,
  )
}

export const flattenTranslations = (
  translations: Required<GetTranslations_ftbToolV2>,
): Translations => {
  return {
    steps: flattenStepTranslations(translations.steps),
    forms: flattenFormTranslations(translations.forms),
    footer: flattenFooterTranslations(translations.footer),
    results: flattenResults(translations.results),
    pm: flattenPM(translations.predictiveMaintenance),
    malfunctions: flattenMalfunctions(translations.repairs),
    services: flattenServices(translations.services),
  }
}

const flattenStepTranslations = (
  steps: (GetTranslations_ftbToolV2_steps | null)[] | null | undefined,
): StepRecords => {
  const res: StepRecords = createEmptyStepsObject()

  if (!steps?.length) {
    return res
  }

  steps.forEach((step) => {
    const stepName: StepNames | undefined =
      (step?.stepName && SITECORE_STEP_NAMES[step?.stepName]) || undefined
    if (!step || !stepName) {
      return
    }
    res[stepName] = {
      labels: flattenLabels(step.labels),
      errorMessages: flattenErrorMessages(step.errorMessages),
      otherData: flattenDictionary(step.otherData?.items),
    }
  })

  return res
}

const flattenFormTranslations = (
  forms: (GetTranslations_ftbToolV2_forms | null)[] | null | undefined,
): FormRecords => {
  const res: FormRecords = createEmptyFormsObject()

  if (!forms?.length) {
    return res
  }

  forms.forEach((form) => {
    const formName: FormNames | undefined =
      (form?.formName && SITECORE_FORM_NAMES[form?.formName]) || undefined
    if (!form || !formName) {
      return
    }
    res[formName] = {
      labels: flattenLabels(form.labels),
      errorMessages: flattenErrorMessages(form.errorMessages),
      otherData: flattenDictionary(form.otherData?.items),
    }
  })

  return res
}

const flattenFooterTranslations = (
  footer: GetTranslations_ftbToolV2_footer | null | undefined,
): FooterDictionary | null => {
  if (!footer) {
    return null
  }

  const res: FooterDictionary = {
    labels: flattenLabels(footer.labels),
    links: Array.isArray(footer.links)
      ? footer.links.filter(
          (linkObject) => linkObject?.link && linkObject?.linkText,
        )
      : footer.links,
  }

  return res
}

const parseResults = (
  results: (GetTranslations_ftbToolV2_results | null)[] | null | undefined,
): Partial<ResultsViewContentMap> => {
  if (!results) return {}

  return results.reduce((acc, result) => {
    return result
      ? {
          ...acc,
          [result.name]: {
            title: result.header,
            details: result?.body,
            iconName: result.image,
            buttonLabel: result.resetButtonLabel,
          },
        }
      : acc
  }, {})
}

const flattenResults = (
  results: (GetTranslations_ftbToolV2_results | null)[] | null | undefined,
) => {
  const flattenedResults = parseResults(results)

  return {
    appError: flattenedResults?.appError,
    countryNotSupported: flattenedResults?.countryNotSupported,
    notFoundPage: flattenedResults?.notFoundPage,
    proactiveMaintenanceRejectionError:
      flattenedResults?.proactiveMaintenanceRejectionError,
    proactiveMaintenanceRejectionSuccess:
      flattenedResults?.proactiveMaintenanceRejectionSuccess,
    verificationFailure: flattenedResults?.verificationFailure,
    verificationErrorBookingIsProcessed:
      flattenedResults?.verificationErrorBookingIsProcessed,
    verificationErrorVehicleNotFound:
      flattenedResults?.verificationErrorVehicleNotFound,
    verificationErrorContactNotFound:
      flattenedResults?.verificationErrorContactNotFound,
    verificationErrorNoEmptySlots:
      flattenedResults?.verificationErrorNoEmptySlots,
    verificationErrorCreatingContact:
      flattenedResults?.verificationErrorCreatingContact,
    verificationErrorCreatingBooking:
      flattenedResults?.verificationErrorCreatingBooking,
  } as ResultsViewContentMap
}

const flattenDictionary = (
  dictionary: Record<string, SitecoreDictionary>,
): NestedDictionary => {
  return Object.values(dictionary || {}).reduce<NestedDictionary>(
    (res, item) => {
      if (item.type === 'DictionaryEntry') {
        return {
          ...res,
          [item.name]: item.label.value,
        }
      }

      if (item.type === 'DictionaryEntry Richtext') {
        return {
          ...res,
          [item.name]: item.text.value,
        }
      }

      if (item.type === 'DictionaryFolder') {
        return {
          ...res,
          [item.name]: flattenDictionary(item.items),
        }
      }

      return res
    },
    {},
  )
}

const flattenLabels = (
  labels:
    | (
        | GetTranslations_ftbToolV2_steps_labels
        | GetTranslations_ftbToolV2_forms_labels
        | GetTranslations_ftbToolV2_footer_labels
        | null
      )[]
    | null,
): FlattenedLabels => {
  if (!labels) {
    return {}
  }

  const res: FlattenedLabels = {}
  Object.keys(labels).forEach((key) => {
    const label = labels[key]
    if (label) {
      res[label['key']] = label['value']
    }
  })

  return res
}

const flattenErrorMessages = (
  errorMessages:
    | (
        | GetTranslations_ftbToolV2_steps_errorMessages
        | GetTranslations_ftbToolV2_forms_errorMessages
        | null
      )[]
    | null,
): FlattenedErrorMessages => {
  if (!errorMessages) {
    return {}
  }

  const res: FlattenedErrorMessages = {}
  Object.keys(errorMessages).forEach((key) => {
    const errorMessage = errorMessages[key]
    if (errorMessage) {
      res[errorMessage['key']] = errorMessage['value']
    }
  })

  return res
}

const flattenPM = (
  pm: GetTranslations_ftbToolV2_predictiveMaintenance | null | undefined,
): PMRecords => {
  return {
    rejections:
      pm?.rejections?.map<CheckWithLabelOption>((rejection) => ({
        value: rejection?.reasonOfRejection || '',
        label: rejection?.label,
      })) || null,
  }
}

export const flattenMalfunctions = (
  malfunctions:
    | (GetTranslations_ftbToolV2_repairs | Service<MalfunctionCode> | null)[]
    | null
    | undefined,
) =>
  !malfunctions
    ? null
    : // dictionary shape helps with filtering the country-specific malfunctions
      malfunctions.reduce<MalfunctionDictionary>((acc, m) => {
        if (!m?.label || !m.value) return acc

        return {
          ...acc,
          [m.value]: {
            label: m.label,
            // values are fixed on the CMS so casting is not very risky
            value: m.value as MalfunctionCode,
            description: m.description || '',
          },
        }
      }, {})

export const flattenServices = (
  services:
    | (
        | GetTranslations_ftbToolV2_services
        | Service<VehicleServiceCode>
        | null
      )[]
    | null
    | undefined,
) =>
  !services
    ? null
    : // dictionary shape helps with filtering the country-specific malfunctions
      services.reduce<ServiceDictionary>((acc, m) => {
        if (!m?.label || !m.value) return acc

        return {
          ...acc,
          [m.value]: {
            label: m.label,
            // values are fixed on the CMS so casting is not very risky
            value: m.value as VehicleServiceCode,
            description: m.description || '',
          },
        }
      }, {})
