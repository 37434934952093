import { Box } from '@material-ui/core'
import { useBreakpoint } from '@velocity/styling/breakpoint/useBreakpoint/useBreakpoint'
import {
  UnstyledMarker,
  UnstyledMap,
  BoundsConfig,
  useEnsureLatestInitialisedGoogleMapsService,
} from '@velocity/ui'
import { FlexBox } from '@velocity/ui/draft'
import { detect } from 'detect-browser'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { GeolocationCoords, Supplier, Suppliers } from '../../types/models'
import { getCenterCoordinatesFromList } from '../../utils/getCenterCoordinatesFromList'
import { suppliersToCoords } from '../../utils/suppliersToCoords'

import { SupplierCard } from '../SupplierCard/SupplierCard'
import { SupplierCardTooltip } from '../SupplierCardTooltip/SupplierCardTooltip'
import { useStyles } from './MapView.styled'
import supplierMarkerActiveIconPNG from './supplierMarkerActive.png'
import supplierMarkerActiveIcon from './supplierMarkerActive.svg'
import supplierMarkerDefaultIconPNG from './supplierMarkerDefault.png'
import supplierMarkerDefaultIcon from './supplierMarkerDefault.svg'

export interface MapViewProps {
  /**
   * Optional object for the current supplier
   */
  supplier?: Supplier
  /**
   * Optional list of suppliers
   */
  suppliers: Suppliers
  /**
   * Optional function called when a supplier marker is selected
   * @param place
   */
  onSelectPlace?: (place?: Supplier) => void
  /**
   * Optional function called when the selected supplier is clicked
   * @param place
   */
  onSelectedPlaceClick?: (place: Supplier) => void
  /**
   * Whether to show a card on the map with supplier details when its marker is clicked
   * @param place
   */
  showSelectedPlaceCard?: boolean
  /**
   * Whether to show an overlay or not
   */
  overlay?: boolean
  /**
   * Whether to allow map panning or not
   */
  panning?: boolean
  /**
   * The map center to use when no suppliers are passed
   */
  fallbackCenter?: GeolocationCoords
}

const browser = detect()

const markerIcons = {
  default:
    browser?.name === 'ie'
      ? supplierMarkerDefaultIconPNG
      : supplierMarkerDefaultIcon,
  selected:
    browser?.name === 'ie'
      ? supplierMarkerActiveIconPNG
      : supplierMarkerActiveIcon,
}

const MAP_BOX_PADDING = {
  desktop: {
    top: 60,
    right: 60,
    bottom: 60,
    left: 60,
  },
  mobile: {
    top: 75,
    right: 60,
    bottom: 75,
    left: 60,
  },
}

export const MapView: FC<MapViewProps> = (props) => {
  const {
    panning,
    suppliers = [],
    fallbackCenter,
    supplier,
    onSelectPlace,
    onSelectedPlaceClick,
    overlay,
    showSelectedPlaceCard,
  } = props
  const supplierCoords = useMemo(() => {
    return supplier && { lat: supplier.latitude, lng: supplier.longitude }
  }, [supplier])

  const suppliersCoords = useMemo(
    () => suppliersToCoords(suppliers),
    [suppliers],
  )

  const center = useMemo(() => {
    return suppliersCoords.length
      ? getCenterCoordinatesFromList(suppliersCoords)
      : supplierCoords || fallbackCenter || null
  }, [fallbackCenter, supplierCoords, suppliersCoords])

  const classes = useStyles({ overlay: !!overlay })
  const { countryConfig } = useUxConfig()
  const f = useMessageFormat()
  const translations = useTranslation()
  const stepTranslations = translations?.steps?.shared
  const isMobile = useBreakpoint()?.toString() === 'XS'
  const [boundsConfig, setBoundsConfig] = useState<BoundsConfig>()
  const isMapsServiceLoaded = useEnsureLatestInitialisedGoogleMapsService()

  useEffect(() => {
    const maps = window.google?.maps

    if (
      maps &&
      suppliersCoords.length > 1 &&
      !checkAllDuplicatePlaces(suppliersCoords)
    ) {
      const bounds = new maps.LatLngBounds()

      suppliersCoords.forEach(({ lat, lng }) => {
        bounds.extend(new maps.LatLng(lat, lng))
      })

      setBoundsConfig({
        bounds,
        padding: isMobile ? MAP_BOX_PADDING.mobile : MAP_BOX_PADDING.desktop,
      })
    }
  }, [isMobile, suppliersCoords])

  // Check edge case if all bounds are all duplicates
  // If so, don't set the bounds config
  const checkAllDuplicatePlaces = (
    compareCoords: ReadonlyArray<GeolocationCoords>,
  ) => {
    if (compareCoords.length < 2) {
      return false
    }

    const compareCoord = compareCoords[0]
    const res = compareCoords.slice(1).every((coord) => {
      return compareCoord.lat === coord.lat && compareCoord.lng === coord.lng
    })

    return res
  }

  const formattedDistance = supplier
    ? f(stepTranslations?.otherData['distance'], {
        debugId: 'steps.global.otherData.distance',
        values: {
          distance: supplier.distance.toLocaleString(
            countryConfig.formatLocale,
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 2,
            },
          ),
        },
      })
    : ''

  const renderMarker = useCallback(
    (markerSupplier: Supplier, index: number) => {
      const { longitude: lng, latitude: lat } = markerSupplier
      const icon =
        markerSupplier.id === supplier?.id
          ? markerIcons.selected
          : markerIcons.default

      return (
        lat != null &&
        lng != null && (
          <UnstyledMarker
            key={index}
            onClick={() =>
              onSelectPlace &&
              // small delay to ensure click is not capture if card is rendered
              // on top of marker
              setTimeout(() => {
                onSelectPlace(markerSupplier)
              }, 100)
            }
            location={{ lat, lng }}
            icon={{ url: icon }}
          />
        )
      )
    },
    [onSelectPlace, supplier],
  )

  return (
    <FlexBox className={classes.root} data-chromatic="ignore">
      {isMapsServiceLoaded && center && (
        <UnstyledMap
          pannable={panning}
          boundsConfig={boundsConfig}
          {...(boundsConfig ? {} : { center })}
          zoom={14}
          zoomable={true}
          onClick={() => onSelectPlace && onSelectPlace()}
        >
          {suppliers.length && suppliers.map(renderMarker)}
          {showSelectedPlaceCard && supplier && (
            // if the card is wrapped just by a flex container, it cannot take full width
            // if the card is wrapped just by a block container, it cannot be centered
            <FlexBox height="100%" alignItems="center" justifyContent="center">
              <Box my={2} className={classes.centeredCard}>
                <SupplierCard
                  title={supplier.name}
                  location={{
                    city: supplier.city,
                    address: [supplier.streetNumber, supplier.streetName]
                      .filter(Boolean)
                      .join(' '),
                    postalCode: supplier.postalCode,
                  }}
                  tooltip={<SupplierCardTooltip label={formattedDistance} />}
                  onClick={() =>
                    onSelectedPlaceClick && onSelectedPlaceClick(supplier)
                  }
                />
              </Box>
            </FlexBox>
          )}
        </UnstyledMap>
      )}
    </FlexBox>
  )
}
