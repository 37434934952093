import { Typography } from '@material-ui/core'
import { Text } from '@velocity/ui'
import { FlexBox, FlexColumn } from '@velocity/ui/draft'
import { FC, useCallback, useMemo, useRef, useState } from 'react'

import { useTranslation } from '../../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../../hooks/useMessageFormat'
import { Supplier } from '../../../types/models'
import { SDate } from '../../../utils/date'
import { DEFAULT_PERIOD, intervalQuantity } from '../../../utils/time'
import {
  GarageFormValues,
  GarageForm,
  GarageFormProps,
} from '../../forms/GarageForm/GarageForm'
import { MapView } from '../../MapView/MapView'
import { NoSupplierResults } from '../../NoSupplierResults/NoSupplierResults'
import { InstaBookBadge } from '../../SupplierCard/components/InstaBookBadge'
import { SupplierCardList } from '../../SupplierCardList/SupplierCardList'
import { AdjustQueryButton } from './components/AdjustQueryButton'
import { SupplierResultsActions } from './components/SupplierResultsActions'
import { SupplierResultsContainer } from './components/SupplierResultsContainer'
import { SupplierResultsPanel } from './components/SupplierResultsPanel'
import { GarageStepValues } from './GarageStep.types'
import { useStyles } from './GarageStepView.styled'

export interface GarageViewProps
  extends Omit<GarageStepValues, 'supplierQuery'>,
    Omit<GarageFormProps, 'initialValues'> {
  /**
   * Optional boolean value controlling the map's display (used in mobile views only)
   */
  showMap?: boolean
  /**
   * Optional boolean value controlling whether a supplier count should be displayed
   */
  showGarageCount?: boolean
  /**
   * Optional function which passes the new display state of the map
   * (used in mobile views only)
   * @param show
   */
  setMapVisibility?: (show: boolean) => void
  /**
   * Function which passes the form's values when it is submitted
   * @param values
   */
  onSupplierSelect: (supplier: Supplier) => void
  /**
   * The place against which suppliers were requested
   */
  searchedPlace?: GarageFormValues['place']

  initialValues?: GarageStepValues['supplierQuery']
}

export const GarageView: FC<GarageViewProps> = (props) => {
  const {
    initialValues,
    onSubmitClick,
    onFieldError,
    desktopView,
    searchedPlace,
    supplier: passedSupplier,
    suppliers = [],
    showGarageCount,
    onSupplierSelect,
    onSubmit,
    showMap,
    setMapVisibility,
    error,
    className,
    onGoBack,
    withPickupAndReturn,
  } = props
  const [supplier, setSupplier] = useState(passedSupplier || undefined)
  const f = useMessageFormat<string | number>()
  const translations = useTranslation()
  const stepTranslations = translations?.steps?.garage
  const sharedTranslations = translations?.steps?.shared
  const hasSuppliers = !!suppliers?.length
  const classes = useStyles()

  // The form was initially decoupled from what happens in the rest of this view's
  // components. After pick up and return, this view requires some bits of the
  // form's logic exposed to perform its operations.
  const isFormValid = useRef<boolean>()

  const {
    countryConfig: { enableMapPanning, dropOffInterval, countryCode },
  } = useUxConfig()

  const formattedFormInitialValuesRef = useRef<GarageFormValues | undefined>(
    initialValues && {
      place: initialValues.place,
      date: SDate(initialValues.date),
      dropOffTime: initialValues.dropOffTime,
    },
  )

  const [lat, lng] = [searchedPlace?.latitude, searchedPlace?.longitude]
  const fallbackCenter = useMemo(
    () => (showGarageCount && lat && lng ? { lat, lng } : undefined),
    [lat, lng, showGarageCount],
  )

  const renderMobileMapView = useCallback(
    () => (
      <MapView
        fallbackCenter={fallbackCenter}
        supplier={supplier}
        suppliers={suppliers}
        panning={enableMapPanning}
        overlay={true}
        showSelectedPlaceCard={!!supplier}
        onSelectPlace={setSupplier}
        onSelectedPlaceClick={
          hasSuppliers && isFormValid ? onSupplierSelect : undefined
        }
      />
    ),
    [
      enableMapPanning,
      fallbackCenter,
      hasSuppliers,
      isFormValid,
      onSupplierSelect,
      supplier,
      suppliers,
    ],
  )

  const isInstaBookingExist = suppliers?.some(
    (supplier) => supplier?.availabilityStatus === 'available',
  )

  const renderSupplierCardList = useCallback(() => {
    if (hasSuppliers) {
      return (
        <>
          <SupplierCardList
            highlightedCard={supplier?.id || undefined}
            suppliers={suppliers}
            onClick={isFormValid ? onSupplierSelect : undefined}
          />
          {/* Temporary available for LPIE only */}
          {isInstaBookingExist && countryCode === 'LPIE' && (
            <div data-e2e-component="directly-bookable-box">
              <InstaBookBadge className={classes.instaBookTip} />
              <Text component="span">
                means the garage will confirm your appointment immediately while
                others might take a few hours
              </Text>
            </div>
          )}
        </>
      )
    }

    return showGarageCount ? <NoSupplierResults /> : <></>
  }, [
    hasSuppliers,
    isFormValid,
    onSupplierSelect,
    showGarageCount,
    supplier?.id,
    suppliers,
    classes.instaBookTip,
    isInstaBookingExist,
    countryCode,
  ])

  const renderAdjustQueryButton = useCallback(
    () =>
      hasSuppliers || fallbackCenter ? (
        <AdjustQueryButton onClick={() => setMapVisibility?.(!showMap)}>
          {showMap
            ? f(sharedTranslations?.labels['viewList'], {
                debugId: 'shared.viewList',
              })
            : f(sharedTranslations?.labels['viewMap'], {
                debugId: 'shared.viewMap',
              })}
        </AdjustQueryButton>
      ) : null,
    [
      f,
      fallbackCenter,
      hasSuppliers,
      setMapVisibility,
      sharedTranslations?.labels,
      showMap,
    ],
  )

  const renderGarageCount = useCallback(() => {
    return (
      <Typography variant="h5">
        {f(stepTranslations?.otherData['garageCount'], {
          debugId: 'steps.garage.garageCount',
          values: { count: suppliers.length },
        })}
      </Typography>
    )
  }, [f, stepTranslations?.otherData, suppliers.length])

  const intervalCount = intervalQuantity(
    dropOffInterval.startTime,
    dropOffInterval.endTime,
  )

  return (
    <FlexColumn
      height="100%"
      maxHeight={!desktopView && showMap ? '100vh' : '100%'}
      flexGrow={1}
      justifyContent="flex-start"
    >
      <GarageForm
        onFieldError={onFieldError}
        onSubmitClick={onSubmitClick}
        initialValues={formattedFormInitialValuesRef.current}
        onSubmit={onSubmit}
        onGoBack={onGoBack}
        className={className}
        error={error}
        withPickupAndReturn={withPickupAndReturn}
        isFormValid={isFormValid}
        periodicConfig={{
          intervalCount,
          startTime: dropOffInterval.startTime,
          interval: dropOffInterval.minutesOfInterval,
          period: DEFAULT_PERIOD,
        }}
      />
      {desktopView ? (
        <FlexBox mt={7} mb={4} height="100%" minHeight="97vh" maxHeight="97vh">
          <SupplierResultsPanel>
            {showGarageCount && renderGarageCount()}
            {renderSupplierCardList()}
          </SupplierResultsPanel>
          <FlexBox mb={2}>
            <MapView
              fallbackCenter={fallbackCenter}
              panning={enableMapPanning}
              supplier={supplier}
              suppliers={suppliers}
              onSelectPlace={setSupplier}
              showSelectedPlaceCard={!!supplier}
              onSelectedPlaceClick={
                hasSuppliers && isFormValid ? onSupplierSelect : undefined
              }
            />
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexColumn mt={1}>
          {showMap ? (
            <>
              {renderMobileMapView()}
              <SupplierResultsActions>
                {renderAdjustQueryButton()}
              </SupplierResultsActions>
            </>
          ) : (
            <SupplierResultsContainer>
              <>
                {showGarageCount && renderGarageCount()}
                {renderAdjustQueryButton()}
                {renderSupplierCardList()}
              </>
            </SupplierResultsContainer>
          )}
        </FlexColumn>
      )}
    </FlexColumn>
  )
}
