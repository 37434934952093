import { CSSProperties } from 'react'

/**
 * Helps to copy only font styles from a source
 * omits everything else including media queries
 * @param style The source style
 * @returns A new object with copy of font related styles
 */
export const copyFontStyles = (
  style: CSSProperties,
): Pick<
  CSSProperties,
  | 'color'
  | 'fontFamily'
  | 'fontSize'
  | 'fontWeight'
  | 'letterSpacing'
  | 'lineHeight'
> => ({
  color: style.color,
  fontFamily: style.fontFamily,
  fontSize: style.fontSize,
  fontWeight: style.fontWeight,
  letterSpacing: style.letterSpacing,
  lineHeight: style.lineHeight,
})
