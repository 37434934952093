import { Container, Box } from '@material-ui/core'
import { Place } from '@velocity/ui'
import { FlexBox, FlexColumn } from '@velocity/ui/draft'
import clsx from 'clsx'
import { FormApi } from 'final-form'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Form } from 'react-final-form'

import { useTranslation } from '../../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../../context/UxConfigContext/UxConfigContext'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useMessageFormat } from '../../../hooks/useMessageFormat'
import { useReverseGeolocation } from '../../../hooks/useReverseGeolocation'
import { useTracking } from '../../../hooks/useTracking'
import { isSameDay } from '../../../i18n/localizedDateFns'
import {
  addBusinessDays,
  dateToIsoString,
  dateToLocaleString,
  getStartOfDay,
  isBeforeDate,
  now,
  SDate,
} from '../../../utils/date'
import { getGarageValidators as getValidators } from '../../../utils/garageValidation'
import { handleInputBlur } from '../../../utils/handleInputBlur'
import { PeriodicConfig } from '../../../utils/time'
import { DatePicker } from '../../DatePicker/DatePicker'
import { FormField } from '../../FormField/FormField'
import { GeolocationButton } from '../../GeolocationButton/GeolocationButton'
import { GooglePlacesAutocomplete } from '../../GooglePlacesAutocomplete/GooglePlacesAutocomplete'
import { TimeDropdown } from '../../TimeDropdown/TimeDropdown'
import { NO_TRANSLATE_CLASS_NAME } from '../shared/constants'
import { StepFormButtons } from '../shared/StepFormButtons/StepFormButtons'
import { StepFormProps } from '../shared/types'
import { useStyles } from './GarageForm.styled'

export type GarageFormValues = {
  /**
   * Object indicating the selected garage
   */
  place: Place
  /**
   * String value indicating the appointment's date
   */
  date: Date
  /**
   * String value indicating the appointment's time
   */
  dropOffTime: string
}

export interface GarageFormProps extends StepFormProps<GarageFormValues> {
  /**
   * Optional function called when the submit is clicked before submission is triggered
   * @param formValues
   * @param canSubmit
   */
  onSubmitClick?: (
    formValues: Partial<GarageFormValues>,
    canSubmit: boolean,
  ) => void
  /**
   * Optional set of values to configure the "drop off time" field
   */
  periodicConfig?: PeriodicConfig
  /**
   * Extended version of the form with post-service return support and UI changes
   */
  withPickupAndReturn?: boolean
  /**
   * Optional callback used to expose the form validation status
   */
  isFormValid?: React.MutableRefObject<boolean | undefined>
}

const fieldIds = {
  googlePlaces: 'googlePlacesId',
  date: 'dateId',
  googlePlacesReturn: 'googlePlacesReturnId',
  dropOff: 'drop-off-time-input',
}
const fieldNames = Object.keys(fieldIds)

const DEFAULT_PERIODIC_CONFIG: PeriodicConfig = {
  startTime: '09:00',
  interval: 30,
  period: 'minutes',
  intervalCount: 16,
}

export const GarageForm: FC<GarageFormProps> = ({
  initialValues,
  onSubmit,
  error,
  className,
  onSubmitClick,
  onFieldError,
  periodicConfig = DEFAULT_PERIODIC_CONFIG,
  withPickupAndReturn = false,
  onGoBack,
  isFormValid,
}) => {
  const f = useMessageFormat<string>()
  const translations = useTranslation()
  const { trackEvent } = useTracking()
  const formTranslations = translations?.forms?.garage
  const sharedTranslations = translations?.forms?.shared
  const formLabels = useMemo(
    () => ({
      ...sharedTranslations?.labels,
      ...formTranslations?.labels,
    }),
    [formTranslations?.labels, sharedTranslations?.labels],
  )

  const formErrors = useMemo(
    () => ({
      ...sharedTranslations?.errorMessages,
      ...formTranslations?.errorMessages,
    }),
    [formTranslations?.errorMessages, sharedTranslations?.errorMessages],
  )

  const { countryConfig } = useUxConfig()
  // The value to track when tomorrow comes, changes only every day (that's why we use a string value, not an object)
  // (see https://leaseplan-digital.atlassian.net/browse/CJ4VS-867)
  // The actual time does not matter, all the logic is performed with day granularity
  const todayDate = dateToIsoString(undefined, true)
  // The min and max dates watch for todayDate updates.
  const [minDate, maxDate] = useMemo(
    () => [
      getStartOfDay(
        addBusinessDays(countryConfig.minimumDaysRequiredForBooking, todayDate),
      ),
      getStartOfDay(
        // Velocity does not work with times
        addBusinessDays(countryConfig.maximumDaysRequiredForBooking, todayDate),
      ),
    ],
    [
      countryConfig.maximumDaysRequiredForBooking,
      countryConfig.minimumDaysRequiredForBooking,
      todayDate,
    ],
  )

  const [location, setLocation] = useState(initialValues?.place.name || '')
  const [isLoadingGeolocation, setLoadingGeolocation] = useState(false)
  const [geolocationButtonKeys, setGeolocationButtonKeys] = useState({
    geolocation: 0,
    returnGeolocation: 0,
  })
  const autocompleteOnFocus = (keyName: keyof typeof geolocationButtonKeys) =>
    setGeolocationButtonKeys({
      ...geolocationButtonKeys,
      [keyName]: geolocationButtonKeys[keyName] + 1,
    })

  const classes = useStyles()
  const { country } = useUxConfig()
  const [validationMutator, setFormApi, validate] =
    useFormValidation<GarageFormValues>(fieldNames)
  const formApiRef = useRef<FormApi<GarageFormValues>>()

  // The form updates on new day because it watches minDate.
  const formInitialValues = useMemo(
    () => ({ ...makeGarageFormInitialValues(initialValues, minDate) }),
    // NB: the initial values should not be updated every time minDate is changed
    //     initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues],
  )

  // The validators update on new day because they watch minDate & maxDate.
  const validators = useMemo(
    () => getValidators(minDate, maxDate, f, formErrors),
    [f, formErrors, maxDate, minDate],
  )

  const handleSubmit = useCallback(
    (values: GarageFormValues) => {
      const formValues: GarageFormValues = {
        ...values,
        date: new Date(values.date),
        dropOffTime: values.dropOffTime || periodicConfig.startTime,
      }
      onSubmit?.(formValues)
    },
    [onSubmit, periodicConfig.startTime],
  )

  const handleBlur = handleInputBlur(onFieldError)
  useEffect(() => {
    setFormApi(formApiRef.current)
    // only for initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    validate?.()
  }, [validate, validators])

  const inputLabels = useMemo(
    () => ({
      place: withPickupAndReturn
        ? f(formLabels['pickupAndReturnLocation'], {
            debugId: 'forms.shared.labels.pickupAndReturnLocation',
          })
        : f(formLabels['location'], {
            debugId: 'forms.shared.labels.location',
          }),
      placeHint: withPickupAndReturn
        ? f(formLabels['pickupHint'], {
            debugId: 'forms.garage.labels.pickupHint',
          })
        : '',
      date: withPickupAndReturn
        ? f(formLabels['pickupDate'], {
            debugId: 'forms.garage.labels.pickupDate',
          })
        : f(formLabels['date'], {
            debugId: 'forms.shared.labels.date',
          }),
      time: withPickupAndReturn
        ? f(formLabels['pickupTime'], {
            debugId: 'forms.garage.labels.pickupTime',
          })
        : f(formLabels['dropOffTime'], {
            debugId: 'forms.garage.labels.dropOffTime',
          }),
      timePlaceholder: f(formLabels['dropOffTime'], {
        debugId: 'forms.garage.labels.dropOffTime',
      }),
    }),
    [f, formLabels, withPickupAndReturn],
  )

  /**
   * NB: date(time) - Context & approach
   *
   * 1) Backend expects UTC
   * 2) VUI picker (date-only) rejects input values with no default/reset times:
   *    - expects ISO date strings without any time info or
   *    - Date objects/ISO strings with zeroed times but correct local timezones
   * 3) Date-fns cannot parse & format UTC dates to local, accurately
   *
   * - Since time is not processed in FTB, local dates suffice
   * - Pass Date to VUI: better payloads & ability to validate dateTIME
   *
   * TODO: Document
   */
  return (
    <Container className={className}>
      <Form<GarageFormValues>
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        mutators={validationMutator}
        // We use `keepDirtyOnReinitialize` to keep the values entered by the user when the next day comes
        // and we pass new initial values. See the ticket (https://leaseplan-digital.atlassian.net/browse/CJ4VS-867)
        // and the docs (https://final-form.org/docs/react-final-form/types/FormProps#decorators).
        keepDirtyOnReinitialize={true}
        data-e2e-component="garage-form"
      >
        {(formProps) => {
          if (!formApiRef.current) {
            formApiRef.current = formProps.form
          }

          if (isFormValid?.current) {
            isFormValid.current = formProps.valid
          }

          return (
            <form
              onSubmit={formProps.handleSubmit}
              data-e2e-component="garage-form"
              noValidate={true}
              autoComplete="on"
            >
              <FlexColumn>
                <FlexBox className={classes.form}>
                  <FormField
                    name="place"
                    validator={validators.place}
                    label={inputLabels.place}
                    htmlFor={fieldIds.googlePlaces}
                    classes={{
                      formControl: clsx(classes.field, classes.locationField, {
                        [classes.de]: countryConfig.countryCode === 'LPAT',
                      }),
                    }}
                  >
                    {({ input, meta }) => (
                      <>
                        <GooglePlacesAutocomplete
                          name="place"
                          className={clsx(
                            classes.googleAutocomplete,
                            NO_TRANSLATE_CLASS_NAME,
                          )}
                          id={fieldIds.googlePlaces}
                          initialInputValue={location}
                          country={country.code}
                          onBlur={(e) => {
                            // ensure the last known place is used
                            setLocation(input.value?.name || '')
                            // wait for click events of other elements so that
                            // potential blur validation errors do not cause these
                            // click events to be missed (due to UI movement)
                            setTimeout(() => {
                              handleBlur(e, input.onBlur, meta.error?.type)
                            }, 100)
                          }}
                          placeholder={inputLabels.placeHint}
                          onFocus={() => autocompleteOnFocus('geolocation')}
                          readOnly={isLoadingGeolocation}
                          onInputChange={setLocation}
                          value={location}
                          onPlaceSelect={(p) => {
                            input.onChange(p)
                            setLocation(p?.name || '')
                          }}
                          inputProps={
                            {
                              autoComplete: 'off',
                              autoFocus: true,
                            } as React.ComponentPropsWithoutRef<'input'>
                          }
                        />
                        <Box>
                          <GeolocationButton
                            key={geolocationButtonKeys.geolocation}
                            useGeolocation={useReverseGeolocation}
                            onStartFetchingLocation={() =>
                              setLoadingGeolocation(true)
                            }
                            onLocationResult={async ({ place }) => {
                              if (place) {
                                setLocation(place.name)
                                input.onChange(place)
                              }
                              setLoadingGeolocation(false)
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </FormField>
                  <FormField
                    name="date"
                    // On initial render VUI uses the string we pass instead of Date.
                    // TODO: investigate further to find if it is a VUI bug.
                    validator={(date: Date | string, _allValues, _meta) => {
                      return validators.date(now(date))
                    }}
                    label={inputLabels.date}
                    htmlFor={fieldIds.date}
                    classes={{
                      formControl: clsx(classes.field, {
                        [classes.de]: countryConfig.countryCode === 'LPAT',
                      }),
                    }}
                    showErrorFn={(meta) => {
                      return meta.error
                        ? f(meta.error.message, {
                            debugId: 'forms.garage.dateError',
                            values: {
                              minDate: dateToLocaleString(minDate),
                              maxDate: dateToLocaleString(maxDate),
                            },
                          })
                        : ''
                    }}
                  >
                    {({ input, meta }) => {
                      return (
                        <DatePicker
                          inputProps={
                            {
                              'data-e2e-component': 'keyboard-datepicker',
                              className: 'chromatic-ignore',
                              autoComplete: 'off',
                            } as React.ComponentPropsWithoutRef<'input'>
                          }
                          disableWeekend={true}
                          className={classes.datePicker}
                          onBlur={(e) => {
                            handleBlur(e, input.onBlur, meta.error?.type)
                          }}
                          minDate={dateToIsoString(minDate, true)}
                          maxDate={dateToIsoString(maxDate, true)}
                          value={dateToIsoString(new Date(input.value), true)}
                          onChange={(e) => input.onChange(e.target.value)}
                          name="date"
                        />
                      )
                    }}
                  </FormField>
                  <FormField
                    name="dropOffTime"
                    label={inputLabels.time}
                    classes={{
                      formControl: clsx(classes.field, classes.timeField, {
                        [classes.de]: countryConfig.countryCode === 'LPAT',
                      }),
                    }}
                    validator={validators.time}
                    htmlFor={fieldIds.dropOff}
                  >
                    {({ input }) => (
                      <TimeDropdown
                        onChange={input.onChange}
                        periodicConfig={periodicConfig}
                        inputProps={{
                          'aria-label': inputLabels.time,
                        }}
                        placeholder={f(formLabels['timePlaceholder'], {
                          debugId: 'forms.garage.labels.timePlaceholder',
                        })}
                        value={input.value}
                        data-e2e-component={fieldIds.dropOff}
                      />
                    )}
                  </FormField>
                </FlexBox>
                {error && (
                  <Box
                    className={clsx(
                      classes.field,
                      classes.locationField,
                      classes.errorField,
                    )}
                  >
                    {error}
                  </Box>
                )}
                <StepFormButtons
                  // TODO: Fix typing mismatch, onGoBack should just go back
                  // without persistence.
                  onPrevious={() => onGoBack?.(formProps.values)}
                  previousDisabled={formProps.submitting}
                  nextDisabled={formProps.submitting}
                  nextLabel={f(formLabels['searchGarage'], {
                    debugId: 'forms.garage.searchGarage',
                  })}
                  nextButtonProps={{
                    className: classes.searchButton,
                    EndIcon: undefined,
                    onClick: () => {
                      const { dropOffTime } = formProps.values
                      onSubmitClick?.(
                        {
                          ...formProps.values,
                          dropOffTime: dropOffTime || periodicConfig.startTime,
                          date: new Date(formProps.values.date),
                        },
                        !formProps.invalid, // NOSONAR
                      )
                      // GA event, that collect Garage Form errors
                      for (const formFieldName in formProps.errors) {
                        trackEvent({
                          event: 'formEvent',
                          formName: 'garage booking',
                          formStep: 'garage-form',
                          formContext: 'ftb',
                          formErrorField: formFieldName,
                          formErrorType:
                            formProps.errors?.[`${formFieldName}`].type,
                        })
                      }
                      //  GA event, that collect info about submitting
                      trackEvent({
                        event: 'genericEvent',
                        category: 'search',
                        action: 'submit',
                        label: formProps.invalid ? 'failed' : 'success',
                      })
                    },
                  }}
                />
              </FlexColumn>
            </form>
          )
        }}
      </Form>
    </Container>
  )
}

export const makeGarageFormInitialValues = (
  initialValues: GarageFormValues | undefined,
  minDate: Date,
) => {
  const defaultValues = { date: minDate }
  if (!initialValues) {
    return defaultValues
  }

  const initialDate = SDate(initialValues?.['date'] || minDate)
  // Same or before
  if (isBeforeDate(initialDate, minDate) || isSameDay(initialDate, minDate)) {
    return { ...initialValues, ...defaultValues }
  }

  return { ...initialValues, date: initialDate }
}
