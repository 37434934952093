import { Container } from '@material-ui/core'
import { ChevronRightIcon } from '@velocity/icons/system'
import { Button, Input, Text, List } from '@velocity/ui'
import { FlexBox } from '@velocity/ui/draft'
import { FormApi } from 'final-form'
import React, { useMemo, useCallback, useRef, useEffect } from 'react'
import { Form } from 'react-final-form'

import { useTranslation } from '../../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../../context/UxConfigContext/UxConfigContext'
import { useLicensePlateExist } from '../../../hooks/api/useLicensePlateExist'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useMessageFormat } from '../../../hooks/useMessageFormat'
import { useTracking } from '../../../hooks/useTracking'
import { DriverData } from '../../../types/api'
import { handleInputBlur } from '../../../utils/handleInputBlur'
import { getVehicleValidators as getValidators } from '../../../utils/vehicleValidation'
import { FormField } from '../../FormField/FormField'
import { NO_TRANSLATE_CLASS_NAME } from '../shared/constants'
import { StepFormProps } from '../shared/types'
import { useStyles } from './VehicleForm.styled'

export type VehicleFormValues = Pick<DriverData, 'licensePlate' | 'mileage'>

export type VehicleFormProps = StepFormProps<VehicleFormValues>

const fieldIds = {
  licensePlate: 'licensePlateId',
  mileage: 'mileage',
}

export const VEHICLE_FORM_DEFAULTS: Partial<VehicleFormValues> = {
  licensePlate: '',
  mileage: undefined,
}

const fieldNames = Object.keys(fieldIds)

export const VehicleForm: React.FC<VehicleFormProps> = (props) => {
  const {
    onSubmit,
    onFieldError,
    initialValues = VEHICLE_FORM_DEFAULTS,
    error,
    className,
  } = props
  const { countryConfig } = useUxConfig()
  const { trackEvent } = useTracking()
  const initialLicensePlateCheck = React.useRef<boolean>(false)
  const { licensePlateData, checkLicensePlateExists } = useLicensePlateExist(
    countryConfig.licensePlateFormat,
  )
  const [validationMutator, setFormApi, validate] =
    useFormValidation<VehicleFormValues>(fieldNames)
  const formApiRef = useRef<FormApi<VehicleFormValues>>()
  const translations = useTranslation()
  const f = useMessageFormat<string>()
  const classes = useStyles()
  const sharedTranslations = translations?.forms?.shared
  const stepSharedTranslations = translations?.steps?.shared

  const stepOtherData = useMemo(
    () => ({ ...stepSharedTranslations?.otherData }),
    [stepSharedTranslations?.otherData],
  )

  const formLabels = useMemo(
    () => ({ ...sharedTranslations?.labels }),
    [sharedTranslations?.labels],
  )

  const formErrors = useMemo(
    () => ({ ...sharedTranslations?.errorMessages }),
    [sharedTranslations?.errorMessages],
  )

  const handleSubmit = useCallback(
    (values: VehicleFormValues) => {
      const formValues: VehicleFormValues = {
        licensePlate: values.licensePlate.toUpperCase(),
        mileage: Number(values.mileage),
      }

      if (!licensePlateData.exists || licensePlateData.loading) {
        return
      }

      onSubmit?.(formValues)
    },
    [onSubmit, licensePlateData.exists, licensePlateData.loading],
  )

  const handleBlur = handleInputBlur(onFieldError)

  const validators = useMemo(() => {
    return getValidators(countryConfig.licensePlateFormat, f, formErrors)
  }, [countryConfig.licensePlateFormat, f, formErrors])

  const showError =
    licensePlateData.error !== undefined ||
    (licensePlateData.exists !== undefined && !licensePlateData.exists)

  const initializeLicensePlateField =
    initialValues &&
    initialValues.licensePlate &&
    !initialLicensePlateCheck.current

  useEffect(() => {
    setFormApi(formApiRef.current)

    if (initializeLicensePlateField) {
      initialLicensePlateCheck.current = true
      initialValues.licensePlate &&
        checkLicensePlateExists(initialValues.licensePlate)
    }
    // only for initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    validate?.()
  }, [validate, validators])

  return (
    <Container className={className}>
      <Form<VehicleFormValues>
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize={true}
        initialValues={initialValues}
        mutators={{
          ...validationMutator,
          setUpperCase: ([name], state, { changeValue }) =>
            changeValue(state, name, (value) => value?.toUpperCase()),
        }}
        render={({ handleSubmit: submitForm, submitting, form, errors }) => {
          formApiRef.current = form
          const isDisabled =
            submitting || licensePlateData.loading || !licensePlateData.exists
          return (
            <form
              onSubmit={submitForm}
              data-e2e-component="vehicle-form"
              noValidate={true}
              autoComplete="on"
              onChange={(e) => form.mutators.setUpperCase('licensePlate')}
            >
              {/* for LPIE only */}
              {countryConfig.countryCode === 'LPIE' && (
                <div data-e2e-component="user-tips-box">
                  <Text
                    component="h3"
                    bold={true}
                    className={classes.toolTipTitle}
                  >
                    {f(stepOtherData['forWhatIsFtb'])}
                  </Text>
                  <List className={classes.tooltipList}>
                    <List.Item>
                      {f(stepOtherData['forBookingService'])}
                    </List.Item>
                    <List.Item>{f(stepOtherData['forBookingCvrt'])}</List.Item>
                    <List.Item>{f(stepOtherData['forRepairs'])}</List.Item>
                  </List>
                  <Text
                    component="h3"
                    bold={true}
                    className={classes.toolTipTitle}
                  >
                    {f(stepOtherData['whenCallLp'])}
                  </Text>
                  <List className={classes.tooltipList}>
                    <List.Item>{f(stepOtherData['ifUrgent'])}</List.Item>
                  </List>
                </div>
              )}

              <div>
                <div className={classes.plateFieldContainer}>
                  <FormField
                    name="licensePlate"
                    validator={validators.licensePlate}
                    label={f(formLabels['plate'], {
                      debugId: 'forms.shared.labels.plate',
                    })}
                    htmlFor={fieldIds.licensePlate}
                    classes={{ formControl: classes.plateField }}
                  >
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        onBlur={(e) => {
                          handleBlur(e, input.onBlur, meta.error?.type)
                          checkLicensePlateExists(e.target.value)
                        }}
                        inputProps={{
                          'data-e2e-component': 'license-plate-input',
                        }}
                        className={NO_TRANSLATE_CLASS_NAME}
                        placeholder={f(formLabels['plateHint'], {
                          debugId: 'forms.shared.labels.plateHint',
                        })}
                        success={licensePlateData.exists}
                        error={showError}
                        disabled={licensePlateData.loading}
                      />
                    )}
                  </FormField>
                  {licensePlateData.loading && (
                    <span className={classes.existLoading}>
                      {f(formLabels['plateExistLoading'], {
                        debugId: 'forms.shared.labels.plateExistLoading',
                      })}
                    </span>
                  )}
                  {!licensePlateData.loading &&
                    licensePlateData.exists !== undefined &&
                    !licensePlateData.exists && (
                      <span className={classes.existError}>
                        {f(formLabels['plateExistFail'], {
                          debugId: 'forms.shared.labels.plateExistFail',
                        })}
                      </span>
                    )}
                </div>
                <FormField
                  name="mileage"
                  validator={validators.mileage}
                  label={f(formLabels['mileage'], {
                    debugId: 'forms.shared.labels.mileage',
                  })}
                  htmlFor={fieldIds.mileage}
                  classes={{ formControl: classes.mileageField }}
                  hint={f(formLabels['mileageHelp'], {
                    debugId: 'forms.shared.labels.mileageHelp',
                  })}
                >
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      onBlur={(e) =>
                        handleBlur(e, input.onBlur, meta.error?.type)
                      }
                      inputProps={{
                        'data-e2e-component': 'mileage-input',
                      }}
                      endAdornment={f(formLabels['distanceMetric'], {
                        debugId: 'formats.distanceMetric',
                      })}
                      className={NO_TRANSLATE_CLASS_NAME}
                    />
                  )}
                </FormField>
                {error && <FlexBox className={classes.error}>{error}</FlexBox>}
                <Button
                  data-e2e-component="submission-button"
                  type="submit"
                  variant="primary-filled"
                  disabled={isDisabled}
                  className={classes.submit}
                  EndIcon={() => <ChevronRightIcon size="s" />}
                  onClick={() => {
                    // GA event, that collect Vehicle Form errors
                    for (const formFieldName in errors) {
                      trackEvent({
                        event: 'formEvent',
                        formName: 'garage booking',
                        formStep: 'vehicle-form',
                        formContext: 'ftb',
                        formErrorField: formFieldName,
                        formErrorType: errors?.[`${formFieldName}`].type,
                      })
                    }
                  }}
                >
                  {f(formLabels['next'], {
                    debugId: 'forms.shared.next',
                  })}
                </Button>
              </div>
            </form>
          )
        }}
      />
    </Container>
  )
}
