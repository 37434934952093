import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
  },
  title: {
    '&&': {
      fontWeight: 'bold',
    },
  },
  plateFieldContainer: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      '&&': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  plateField: {
    '&$plateField': {
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        '&&': {
          width: '30%',
        },
      },
    },
  },
  mileageField: {
    paddingRight: 0,
    '&&': {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      '&&': {
        width: '30%',
        marginBottom: theme.spacing(3),
      },
    },
  },
  error: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '55%',
    },
  },
  submit: {
    '&$submit': {
      width: '100%',
      justifyContent: 'space-between',
      textTransform: 'uppercase',
      fontSize: theme.typography.subtitle2.fontSize,
      borderRadius: theme.spacing(3),
      margin: theme.spacing(4, 0, 3, 0),

      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(3, 0, 5, 0),
        height: theme.spacing(6),
        width: '30%',
      },
    },
  },
  toolTipTitle: {
    '&$toolTipTitle': {
      fontWeight: 'bold',
      fontSize: '1rem',
      marginBottom: theme.spacing(1),
    },
  },
  tooltipList: {
    '&$tooltipList': {
      marginBottom: theme.spacing(3),
    },
  },
  existError: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.error.dark,
  },
  existLoading: {
    fontSize: theme.typography.caption.fontSize,
  },
}))
