import { FormApi, Mutator } from 'final-form'
import { useCallback, useMemo, useState } from 'react'

// !NB!: The name of this mutator, validationMutator, when passed in the form
// should NOT change as it used by the hook to trigger it. So please AVOID doing
// something like this in the form:
// <Form mutators={{ customValidationMutatorName: validationMutator}} />
export const MUTATOR_NAME = 'validationMutator'

// General type to represent the form's fields { name: type }
type TFormValues = Record<string, unknown>

const validateFormFields = <T extends TFormValues>(
  formApi: FormApi<T>,
  fields: string[],
) => {
  const validationMutator = formApi.mutators[MUTATOR_NAME]

  if (!validationMutator) {
    throw new Error(`Form mutator with name ${MUTATOR_NAME}, not found.`)
  }

  fields.forEach((field) => validationMutator(field))
}

type ValidationMutator<T extends TFormValues> = {
  [MUTATOR_NAME]: Mutator<T>
}

export type HookPayload<T extends TFormValues> = [
  ValidationMutator<T>,
  React.Dispatch<React.SetStateAction<FormApi<T, Partial<T>> | undefined>>,
  (() => void) | undefined,
]

/**
 * This hook helps us to trigger manually validation for React-Final-Form since
 * there is no current api implementation. The motivation was our need of updating
 * our forms' validation messages on locale switch. How to use:
 *
 * - Call hook with the names of the form fields you want to manually trigger validation.
 * - Pass the hook mutator to the form: <Form mutators={validationMutator} />
 * - Persist the Form api: Store formProps.form from its render props (a ref could do)
 * - When the api is fetched, pass it to the hook's setFormApi.
 *
 * After the hook receives the formApi, it exposes the validate function which
 * the consumers can use to retrigger form validation.
 *
 */
export const useFormValidation = <T extends TFormValues>(
  fields: (keyof TFormValues)[],
): HookPayload<T> => {
  const [formApi, setFormApi] = useState<FormApi<T>>()

  const validate = useCallback(() => {
    formApi && validateFormFields(formApi, fields)
  }, [fields, formApi])

  const fieldMutator = useCallback<Mutator<T>>(
    (fieldsToValidate, state, { changeValue }) => {
      fieldsToValidate.forEach((fieldName: string) => {
        changeValue(state, fieldName, (n) => n)
      })
    },
    [],
  )

  // Having the fieldMutator fn defined outside the hook, would produce type
  // errors between the T & TFormValues
  const validationMutator: ValidationMutator<T> = useMemo(
    () => ({ [MUTATOR_NAME]: fieldMutator }),
    [fieldMutator],
  )

  const hookApiMemo = useMemo<HookPayload<T>>(() => {
    return [validationMutator, setFormApi, formApi && validate]
  }, [formApi, validate, validationMutator])

  return hookApiMemo
}
