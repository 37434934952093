import { FC } from 'react'

import { useHistory } from 'react-router-dom'

import { ResultsView } from '../ResultsView/ResultsView'

export type Props = {
  redirectUrl: string
}

export const CountryNotSupported: FC<Props> = ({ redirectUrl }) => {
  const history = useHistory()

  const onClick = () => {
    history.push(redirectUrl)
  }

  return <ResultsView viewType="countryNotSupported" buttonOnClick={onClick} />
}
