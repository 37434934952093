import { lazy, Suspense } from 'react'

import { StepComponentContainer } from '../../../types/flow'
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'
import { hasActivityFormValues } from '../ActivityStep/ActivityStep'
import { hasGarageStepValues } from '../GarageStep/GarageStep'
import { hasVehicleFormValues } from '../VehicleStep/VehicleStep'
import { DriverStepValues } from './DriverStep'
import { DriverStepPrerequisiteData } from './types'

const DriverStepLazy = lazy(() => import('./DriverStep'))

export const DriverStepContainer: StepComponentContainer<
  DriverStepValues,
  DriverStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <DriverStepLazy {...props} />
    </Suspense>
  )
}

DriverStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is DriverStepPrerequisiteData =>
  hasVehicleFormValues(mergedFlowData) &&
  hasActivityFormValues(mergedFlowData) &&
  hasGarageStepValues(mergedFlowData)
