import { CloseIcon } from '@velocity/icons/system'
import { FC, SyntheticEvent } from 'react'

import { useStyles } from './MultiDropDownSelectedLabel.styled'

interface MultiDropDownSelectedLabelProps {
  label: string
  onRemove: () => void
}

export const MultiDropDownSelectedLabel: FC<MultiDropDownSelectedLabelProps> =
  ({ label, onRemove }) => {
    const classes = useStyles()

    const handleRemove = (event: SyntheticEvent) => {
      event.stopPropagation()
      onRemove()
    }

    return (
      <div
        className={classes.label}
        data-e2e-component="multi-drop-down-selected-label"
        onClick={(e) => e.stopPropagation()}
      >
        <span>{label}</span>
        <CloseIcon
          className={classes.removeIcon}
          size="xs"
          onClick={handleRemove}
        />
      </div>
    )
  }
