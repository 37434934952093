import * as R from 'ramda'
import { useEffect, useState, useRef, useCallback } from 'react'

import { useUxConfig } from '../../../context/UxConfigContext/UxConfigContext'
import { useVehicleServices } from '../../../hooks/api/useVehicleServices'
import { VehicleService } from '../../../types/api'
import { ErrorCode } from '../../../types/errors'
import { StepComponent } from '../../../types/flow'
import { ErrorBoxHttpCodes } from '../../ErrorBoxHttpCodes/ErrorBoxHttpCodes'
import { EligibilityError } from '../../forms/shared/EligibilityError/EligibilityError'
import {
  VehicleForm,
  VehicleFormValues,
} from '../../forms/VehicleForm/VehicleForm'
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'

export type VehicleStepValues = VehicleFormValues & {
  services: VehicleService[]
}

const VehicleStep: StepComponent<VehicleStepValues> = (props) => {
  const { countryConfig } = useUxConfig()
  const { onNextStep, onUpdateStep, stepData } = props
  const [formValues, setFormValues] = useState<VehicleFormValues>()
  const [{ loading, data, error }, fetchVehicleServices] = useVehicleServices()

  const initialValuesRef = useRef<VehicleStepValues | undefined>(
    hasVehicleFormValues(stepData)
      ? {
          mileage: stepData.mileage,
          licensePlate: stepData.licensePlate,
          services: stepData.services,
        }
      : undefined,
  )

  const handleSubmit = useCallback(
    async (formData: VehicleFormValues) => {
      const { licensePlate: unsanitizedLicensePlate, mileage } = formData

      const licensePlate = unsanitizedLicensePlate.replace(/[^\w]|_/g, '')

      try {
        setFormValues({ licensePlate, mileage })

        await fetchVehicleServices({
          licensePlate,
          mileage,
          countryCode: countryConfig.countryCode,
        })
      } catch {
        // error handling
      }
    },
    [fetchVehicleServices, countryConfig.countryCode],
  )

  const hasMalfunction = countryConfig.malfunctions.some(
    (malfunction) => malfunction.isAvailable,
  )
  const hasService = Array.isArray(data) && data.length
  /**
   * When fetchVehicleServices resolves move to next step
   */
  useEffect(() => {
    if (
      hasVehicleFormValues(formValues) &&
      data &&
      (hasService || hasMalfunction)
    ) {
      onUpdateStep({
        ...formValues,
        services: data,
      })
      onNextStep()
    }
  }, [data, formValues, hasMalfunction, hasService, onNextStep, onUpdateStep])

  const eligibilityError =
    (error && error.response?.data?.code === ErrorCode.VehicleNotEligible) ||
    (error &&
      error.response?.data?.code === ErrorCode.ContractInThresholdPeriod) ||
    (data && !hasMalfunction && !hasService)

  let errorCode: undefined | ErrorCode = undefined

  if (
    error &&
    error.response &&
    (error.response.data?.code === ErrorCode.VehicleNotEligible ||
      error.response.data?.code === ErrorCode.ContractInThresholdPeriod)
  ) {
    errorCode = error.response.data.code
  }

  const errorComponent =
    (eligibilityError && (
      <EligibilityError errorCode={errorCode || ErrorCode.VehicleNotEligible} />
    )) ||
    (error && <ErrorBoxHttpCodes error={error.response?.status} />)

  return (
    <>
      {loading && <FullScreenLoader variant="fixed" />}
      <VehicleForm
        initialValues={initialValuesRef.current}
        onSubmit={handleSubmit}
        error={errorComponent}
      />
    </>
  )
}

export const hasVehicleFormValues = (x: unknown): x is VehicleFormValues =>
  R.allPass([
    R.pipe(R.prop('licensePlate'), R.is(String)),
    R.pipe(R.prop('mileage'), R.is(Number)),
  ])(x)

// lazy currently supports only default exports (https://reactjs.org/docs/code-splitting.html#named-exports)
export default VehicleStep
