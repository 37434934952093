import { AxiosError } from 'axios'

import React from 'react'

import { useAppConfig } from '../../context/AppConfigContext/AppConfigContext'
import { useUxConfig } from '../../context/UxConfigContext/UxConfigContext'
import { VehicleExistResponse } from '../../types/api'
import { ErrorPayload } from '../../types/models'
import { useAxios } from '../useAxios'

export const useLicensePlateExist = (
  regex: string | RegExp,
): {
  licensePlateData: {
    exists: boolean | undefined
    error?: AxiosError<ErrorPayload>
    loading: boolean
  }
  checkLicensePlateExists: (licensePlate: string) => void
} => {
  const [exists, setExists] = React.useState<boolean | undefined>(undefined)

  const {
    countryConfig: { countryCode },
  } = useUxConfig()

  const {
    endpoints: { apiBase: baseURL, vehicleExist: vehicleExistEndpoint },
  } = useAppConfig()

  const [{ data, loading, error }, deferredCall] =
    useAxios<VehicleExistResponse>({ url: vehicleExistEndpoint }, true)

  const checkLicensePlateExists = React.useCallback(
    (licensePlate: string) => {
      const plate = licensePlate.toUpperCase().replace(/[^\w]|_/g, '')
      const rgx = typeof regex === 'string' ? new RegExp(regex) : regex
      if (rgx.test(plate)) {
        deferredCall({
          baseURL,
          method: 'GET',
          url: vehicleExistEndpoint,
          params: {
            countryCode,
            licensePlate: plate,
          },
        })
      } else {
        setExists(undefined)
      }
    },
    [baseURL, countryCode, deferredCall, vehicleExistEndpoint, regex],
  )

  React.useEffect(() => {
    if (data) {
      setExists(data.isValid)
    }
  }, [setExists, data])

  return {
    licensePlateData: { exists, error, loading },
    checkLicensePlateExists,
  }
}
